import * as R from 'ramda';

import {
  AddIcon,
  AppTriggerCardIconNew,
  ApplicationTrigger,
  CloseIcon,
  DropdownWithCircle,
  MinusIcon,
  NextDetailICon,
  SendExecutionReportIcon,
  SlackIconCC,
  TeamsIcon,
  TestanalyticsIcon,
  TimerNodeIcon,
  TryMeanalyticsIcon,
  TrymeCloseIcon,
  WebhookCardIcon,
  WebhookIcon,
  WebhooksIcon,
  WidgetIcon,
  CloseIconWithCircle,
  SearchIcon,
  TrymeIcon,
  DownarrowWithCircle,
} from '@/common/Icons/Icons';
import {
  All,
  AutomationListT,
  LogsResponseT,
  RequestModes,
  RequestType,
  SampleLogsResponse,
} from './types';
import {
  ApprovalIcon,
  FunctionNodeIcon,
  IfNodeIcon,
  NoTriggerIcon,
  NotificationNodeIcon,
} from '@/common/Icons/Workflow.Icons';
import {
  ApprovalNodeLogT,
  AuthType,
  ConditionNodeLogT,
  EndNodeLogT,
  EventTriggerNodeLogT,
  ForEachNodeLogT,
  NodeTypeT,
  NormalJobDataT,
  NormalViewActionNodeLogT,
  NotificationNodeLogT,
  PollingNodeLogT,
  TimerNodeLogT,
  WebHookNodeLogT,
  WebHookTriggerNodeLogT,
  executionStatus,
} from '../canvas/types';
import { Button, ButtonContainer } from '@/common/styled/Workflow.Dumb';
import {
  AppButton,
  ApprovalBg,
  ApprovalHeader,
  ApprovalRow,
  ApprovalWrapper,
  CancelButton,
  DivisionDiv,
  DropdownLi,
  DropdownUl,
  DropdownUlWrapper,
  DropdownWrapper,
  Input,
  InputContainer,
  InputContent,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  WidthContainer,
} from '@/common/styled/Workflow.Analytics.Dumb';
import {
  EntityWrapper,
  SerachInput,
  SerachInputWrapper,
  TrainHeader,
  UserWrapper,
} from '@/common/styled/DialogLogs.Dumb';
import { Error, ErrorBoundary } from '@/common/components/ErrorBoundary';
import { Header, HeaderChild } from '@/common/components/Header';
import React, { useEffect, useState } from 'react';
import {
  automationAPIURLWithWorkspace,
  automationApprovalAPIURLWithOutWorkspace,
  getJson,
  getProductId,
  logEvent,
  notificaitonAPIWithWorkspace,
  notificationLogsApiURL,
  postJson,
  slackIntegrationUrl,
  stripeGetApi,
  watsonLogsApiURL,
  wrapperGetJson,
} from '@/common/utils/api-utils';
import {
  convertFromToDateForWorkFlow,
  convertObjectToArray,
  convertToMDText,
  convertToSingleStringforTryme,
  formatAMPM,
  formatDateInCard,
  MOCK_USER_API,
} from '@/common/utils/utils';
import { ApprovalPopup } from './commonTables';
import AssistantMd from '@skitter/assistant_md';
import BasicDateRangePicker from '@/Assistant/Dialogue/CustomDatePicker';
import { DateSelectInput } from './dateDropDown';
/*eslint-disable*/
import Downshift from 'downshift';
import { Loader } from '@/common/components/Loader';
import { LogsOutputTree } from '../canvas/TestPanel';
import { TryIt } from '@/DialogEditor/TrainingModule/DemoTryIt';
import { VideoButtonB } from '@/Videos/Videos';
import { getDateNumber } from '@/DialogEditor/Logs';
import moment from 'moment';
import styled from 'styled-components/macro';
import { useRef } from 'react';
import { RefreshIcon } from '../Icons';
import { MOCK_Notification } from '@/Integrations/MockFile';
import ReactPaginate from 'react-paginate';
import { Modal } from '@/common/components/Modal';
import ImgLoader from '@/common/images/loading.svg';
import Tooltip from 'react-tooltip-lite';
import { getAssistants } from '@/Assistant/Mechanisms/action';
type Customdate = {
  from_date: null | string;
  to_date: null | string;
};
const localApi = false;
const Wrapper = styled.section`
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  // left: 80px;
  right: 0;
  bottom: 0;
  background: #00000047 0% 0% no-repeat padding-box;
  z-index: 1111;
  cursor: auto;

  // @media (max-width: 1800px) {
  //     left: 72px;
  // }
  // @media (max-width: 1650px) {
  //     left: 60px;
  // }
`;

const DropdownContainer = styled.div`
  width: 100%;
  float: left;
  display: grid;
  grid-template-columns: 66% 34%;
  height: 80px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 5px #00000029;
  border-radius: 10px;
  padding: 16px 20px;
  margin-bottom: 20px;

  ${DropdownUlWrapper} {
    margin-bottom: 0px;
    ${DropdownLi} {
      justify-content: space-between;
      padding: 3px 10px 0 10px;
      overflow: auto;
      white-space: nowrap;
      
      .option-input.radio {
        top: 2px;
        position: relative;
        margin-right: 0px;
      }
    }
  }
  ${InputContainer} {
    margin-bottom: 0px;
    ${Input} {
      padding-right: 30px;
    }
  }

  .tab_container_left {
    display: grid;
    grid-template-columns: 220px 220px 220px;
    grid-gap: 20px;
  }
  .tab_container_right {
    display: grid;
    grid-template-columns: 180px auto;
    grid-gap: 20px;
    justify-content: flex-end;
    .prev_icon {
      margin-left: 6px;
      width: 16px;
      height: 16px;
      position: relative;
      top: -1px;
      svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
        fill: #555;
        transform: rotate(90deg);
      }
    }
    .next_icon {
      margin-left: 6px;
      width: 16px;
      height: 16px;
      position: relative;
      top: -1px;
      svg {
        width: 16px;
        height: 16px;
        fill: #555;
        cursor: pointer;
        transform: rotate(-90deg);
      }
    }
    p {
      margin-bottom: 0px;
      display: flex;
      align-items: center;
    }
  }
`;
export const TabHeader = styled.h4`
  font-family: 'Gordita-Medium';
  font-size: 16px;
  width: auto;
  float: left;
  color: #555555;
  margin-bottom: 6px;
  line-height: 26px;
  position: relative;
  cursor: pointer;

  :not(:last-child) {
    margin-right: 40px;
  }

  ${({ active }) =>
    active &&
    `
    transition: 0.3255s ease;
    color: #466AF3;
    :before{
        content: "";
        position: absolute;
        border-bottom: 3px solid #466AF3;
        width: 100%;
        height: 3px;
        bottom: -7px;
    }
    
    `}

  span {
    position: relative;
    top: 2px;
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
export const TabRow = styled.div`
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    position:relative;
    // :after{
    //     content: "";
    //     position: absolute;
    //     border-bottom: 1px solid rgb(224, 224, 224);
    //     width: 27%;
    //     height: 1px;
    //     top: 33px;
    //     left: -4px;
    //     z-index: 0;
    // }
   }
`;

export const TabLeft = styled.div`
  width: 90%;
  float: left;
  position: relative;

  ${TabHeader} {
    color: #000000;
  }

  .active {
    color: #466af2 !important;
    font-family: 'Gordita-Medium' !important;
  }
`;

export const TabRight = styled.div`
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    justify-content: flex-end;
}
`;
export const TabContainer = styled.div`
    width: 100%;
    float: left;
    display: flex;
    height: 70px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 10px;
    padding: 8px 20px;
    margin-bottom: 20px;
    align-items: baseline;
    
    .automation_submenu{
        :after{
            content: "";
            position: absolute;
            border-bottom: 1px solid #e0e0e0;
            width: 32.5%;
            height: 1px;
            top: 33px;
            left: -4px;
            z-index: 0;

            @media (max-width: 1440px) {
                width: 38.3%;
            }
        }
        
    }

  .main_menu {
    ${TabLeft} {
      width: fit-content;
      h4 {
        a {
          color: #000;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          :hover {
            text-decoration: none;
          }
        }

        :first-child {
          padding-left: 5px;
        }
      }
      :after {
        content: '';
        position: absolute;
        border-bottom: 1px solid #e0e0e0;
        width: 99%;
        height: 1px;
        top: 33px;
        left: 4px;
        z-index: 0;
      }

      .active {
        a {
          color: #466af2 !important;
          font-family: 'Gordita-Medium' !important;
        }
      }
    }

    ${TabRight} {
      width: 25%;

      @media (max-width: 1440px) {
        width: 29%;
      }
      @media (max-width: 1300px) {
        width: 33%;
      }
    }

    ${TabHeader} {
      font-family: 'Gordita-Regular';
      cursor: pointer;
    }
  }

  .sub_menu {
    ${TabLeft} {
      width: fit-content;
      h4 {
        a {
          color: #000;

          :hover {
            text-decoration: none;
          }
        }
      }
      :after {
        content: '';
        position: absolute;
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        height: 1px;
        top: 33px;
        left: 0;
        z-index: 0;

        // @media (min-width: 1920px) {
        //     width: 71%;
        // }
        // @media (min-width: 2160px) {
        //     width: 62%;
        // }
        // @media (min-width: 2560px) {
        //     width: 52%;
        // }

        // @media (max-width: 1440px) {
        //     width: 95%;
        // }
        // @media (max-width: 1350px) {
        //     width: 99%;
        // }
      }
    }

    ${TabHeader} {
      font-family: 'Gordita-Regular';
    }
  }

  .main_menu.automation {
    ${TabLeft} {
      width: fit-content;
      :after {
        width: 99.5%;
      }
    }
  }

  ${TabRight} {
    width: 25%;
    @media (max-width: 1300px) {
      width: 15%;
    }
  }
`;
export const SmallButton = styled.button`
  background: ${(props) => (props.primary ? '#fff' : '#2468f6')};
  border-radius: 18px;
  border: ${(props) =>
    props.primary ? '1px solid #cecece' : '1px solid #2468f6'};
  outline: 0;
  font-size: 12px;
  color: ${(props) => (props.primary ? '#333' : '#fff')};
  font-family: 'Gordita-Regular';
  display: flex;
  align-items: center;
  min-width: 64px;
  height: 24px;
  transition: 0.5s ease-out;
  padding: 0px 10px;
  cursor: pointer;
  justify-content: center;

  label {
    margin-bottom: 0px;
    position: relative;
    top: 1px;
    cursor: pointer;
  }
  :hover {
    fill: #333;
    outline: 0;
    cursor: pointer;
    background: ${(props) => (props.primary ? '#ddfeef' : '#ddfeef')};
    border: ${(props) =>
      props.primary ? '1px solid #009d5f' : '1px solid #009d5f'};
    color: ${(props) => (props.primary ? '#333' : '#000')};
  }
  :active {
    background: ${(props) => (props.primary ? '#ddfeef' : '#ddfeef')};
    border: ${(props) =>
      props.primary ? '1px solid #009d5f' : '1px solid #009d5f'};
    color: ${(props) => (props.primary ? '#333' : '#000')};
  }
  :focus {
    background: #ddfeef;
    color: #333;
    border: 1px solid #009d5f;
  }
  span {
    width: 10px;
    height: 10px;
    float: left;
    margin-right: 3px;
    display: flex;
    align-items: center;
    position: relative;
    top: 0px;
    svg {
      width: 10px;
      height: 10px;
      fill: #333;
      color: #333;
    }
  }
`;
const TabelWrapper = styled.div`
  width: 100%;
  float: left;
  ${Table} {
    margin-top: 0px;
  }
  ${Tbody} {
    border-radius: 0px 0px 10px 10px;
    max-height: calc(100vh - 400px);
    height: auto;
    @media (max-width: 1500px) {
      max-height: calc(100vh - 400px);
    }
    ${Tr} {
      min-height: 54px;
      ${Td} {
        padding: 0px;
        min-height: 48px;
        font-size: 12px;
        font-family: 'Gordita-Regular';
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        :first-child {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }

        .td_header {
          font-size: 12px;
          color: #000;
          line-height: 16px;
          margin-bottom: 6px;
          display: -webkit-box;
          max-width: 250px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .td_date {
          font-size: 9px;
          color: #355d8b;
          line-height: 7px;
        }

        .td_cc_img {
          width: 24px;
          height: 24px;
          margin-right: 6px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
`;
type PopupStateT = {
  showPopup: boolean;
  logs: never[];
  executionData: {
    serial_no: string;
    execution_id: string;
    status: string;
    automation_id: string;
    platform: string;
    conversation_id: string;
    bot_id: string;
    start_time: string;
    end_time: string;
    automation_name: string;
    automation_type: string;
    executionTime: string;
    executed_by: string | null;
  };
};

type approvalT = {
  id: string;
  approver_email: string;
  approval_status: string;
  title: string;
  details: string;
  details_link: string;
  link_description: string;
  expiry: {
    days: number;
    mins: number;
    hours: number;
  };
  expiry_cron: string;
  comments: string;
  requestId: string;
  createdDate: string;
  updatedDate: string;
};

export type ApprovalHistoryT = {
  id: string;
  requester_email: string;
  requester_name: string;
  request_type: string;
  request_meta: null;
  workspace: string;
  createdDate: string;
  updatedDate: string;
  title: string;
  details: string;
  details_link: string;
  link_description: string;

  approval: approvalT[];
  request_status: 'Approved' | 'Waiting for approval' | 'Rejected';
};

type ApprovalRequestTitleList = {
  id: string;
  title: string;
};

const items: DateSelectInput[] = [
  {
    date: 'yesterday',
    label: 'Yesterday',
  },
  {
    date: 'today',
    label: 'Today',
  },
];

const customDateRange: DateSelectInput = {
  date: 'custom',
  label: 'Custom Range',
};

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function filterData<T>(array: T[], key: string) {
  const filteredArray = [...new Set<T>(array.map((a) => a[key]))];
  return filteredArray;
}

const allPlatforms = [
  { label: 'Test', value: 'test' },
  { label: 'Try Me', value: 'try_it' },
  { label: 'Teams', value: 'teams' },
  { label: 'Slack', value: 'slack' },
  { label: 'Widget', value: 'widget' },
  { label: 'Application', value: 'Application' },
] as const;

const allExecutionStatus = [
  { label: 'Success', value: 'Success' },
  { label: 'Failure', value: 'Failure' },
] as const;

export function DetailedAutomationLogsListView(props: any) {
  // to use while changing execution status or selecting individual automation
  const statuElementRef = useRef<null | true>(null);
  const donotload = useRef<null | true>(null);

  const [baseResponse, setBaseResponse] =
    useState<LogsResponseT>(SampleLogsResponse);
  const [baseHistory, setBaseHistoryResponse] = useState<{
    requests: ApprovalHistoryT[];
    requestTitleList: ApprovalRequestTitleList[];
    total_count: number;
  }>({
    requests: [],
    requestTitleList: [],
    total_count: 0,
  });
  const [approvalData, setApprovalData] = useState<{
    requests: ApprovalHistoryT[];
    requestTitleList: ApprovalRequestTitleList[];
    total_count: number;
  }>({
    requests: [],
    requestTitleList: [],
    total_count: 0,
  });

  const [currentTab, setCurrentTab] = useState<
    'logs' | 'approval' | 'notification'
  >('logs');

  const [notificationData, setNotificationData] = useState<any[]>([]);
  const [notificationLog, setNotificationLog] = useState<any[]>([]);
  const [notificationTotal, setNotificationTotal] = useState<number>(0);
  const [notificationLogTotal, setNotificationLogTotal] = useState<number>(0);
  const [notificationStats, setNotificationStats] = useState<any>();
  const [emailSearch, setEmailSearch] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const [logLoader, setLogLoader] = useState<boolean>(false);
  const [statsLoader, setStatsLoader] = useState<boolean>(false);
  const [notificationTitles, setNotificationTitles] = useState<string[]>([]);
  const [selectedNotifTitle, setselectedNotifTitle] = useState<string>('All');
  const [notifTitleDropdown, setNotifTitleDropdown] = useState<boolean>(false);

  const [pageNum, setPageNum] = useState<number>(0);
  const [forcePage, setForcePage] = useState<number>(0);
  const [notificationPage, setNotificationPage] = useState<{
    page: number;
    apiNum: number;
  }>({
    page: 0,
    apiNum: 0,
  });
  const [notificationLogPage, setNotificationLogPage] = useState<{
    page: number;
    apiNum: number;
  }>({
    page: 0,
    apiNum: 0,
  });
  const [history, setHistory] = useState<{
    open: boolean;
    id: number;
    title: string;
  }>({
    open: false,
    id: 0,
    title: '',
  });
  const [messagePopup, setMessagePopup] = useState<{
    popup: boolean;
    data: any;
  }>({
    popup: false,
    data: {},
  });
  const closePopup = () => {
    setMessagePopup({
      popup: false,
      data: {},
    });
  };
  const createArray = (n: number) => {
    return Array.from({ length: n }, (_, i) => i + 1);
  };
  const setNotificationLogPagenumber = (num: number) => {
    console.log(num, 'handlePageClick');
    setNotificationLogPage({
      ...notificationLogPage,
      page: num,
    });
    setPageNum(0);
  };
  const [statusFilterPopup, setStatusFilterPopup] = useState<boolean>(false);
  const filterList = ['Success', 'Failed', 'Inprogress'];
  const [filterValue, setFilterValue] = useState<string>('All');
  useEffect(() => {
    notificationApi();
  }, [currentTab, notificationPage, selectedNotifTitle]);
  useEffect(() => {
    notificationLogApi();
  }, [emailSearch, filterValue]);

  useEffect(() => {
    if (currentTab === 'notification') {
      notificationTitleApi();
    }
  }, [currentTab]);
  const notificationApi = () => {
    setLoader(true);
    const data =
      selectedNotifTitle === 'All'
        ? {
            num: notificationPage.page * 10,
            limit: 10,
          }
        : {
            num: notificationPage.page * 10,
            limit: 10,
            searchTerm: selectedNotifTitle,
          };

    if (currentTab === 'notification') {
      if (MOCK_USER_API) {
        setNotificationData(MOCK_Notification);
        setNotificationTotal(500);
        setLoader(false);
      } else {
        postJson(
          !localApi
            ? slackIntegrationUrl(`/slack/${workspaceName}/notification`)
            : `http://dev-assistant.workativ.ai/slack-api-v2/slack/${workspaceName}/notification`
        )(data).then((res) => {
          setNotificationData(res.data.data);
          setNotificationTotal(res.data.totalNum);
          console.log(res, 'response');
          setLoader(false);
        });
      }
    }
  };
  const notificationTitleApi = () => {
    postJson(
      !localApi
        ? slackIntegrationUrl(
            `/slack/${workspaceName}/notification/distinct/notification_title`
          )
        : `https://dev-assistant.workativ.ai/slack-api-v2/slack/${workspaceName}/notification/distinct/notification_title`
    )({}).then((res) => {
      setNotificationTitles(res.data);
      console.log(res, 'response');
    });
  };
  useEffect(() => {
    if (history.open) {
      console.log('handlePageClick');
      notificationLogApi();
      notificationStatsApi();
    }
  }, [history, notificationLogPage]);
  const logStatusName = (filter: string) => {
    switch (filter) {
      case 'QUEUED':
        return 'In progress';
      case 'DELIVERED':
        return 'Success';
      case 'FAILED':
        return 'Failed';
      case 'BUST_WITH_AGENT':
        return 'In progress';
      case 'BUSY_WITH_BOT':
        return 'In progress';
      default:
        return filter;
    }
  };
  console.log(
    emailSearch.length > 3,
    emailSearch.length,
    emailSearch,
    'emailSearch'
  );
  const notificationLogApi = () => {
    console.log('handlePageClick');
    setLogLoader(true);
    const statusName = (filter: string) => {
      switch (filter) {
        case 'Success':
          return 'DELIVERED';
        case 'Failed':
          return 'FAILED';
        case 'Inprogress':
          return 'INPROGRESS';
        default:
          return filter;
      }
    };
    const data =
      filterValue === 'All'
        ? {
            num: emailSearch.length === 0 ? notificationLogPage.page * 10 : 0,
            limit: 10,
            searchTerm: emailSearch.length > 0 ? emailSearch : '',
          }
        : {
            num: emailSearch.length === 0 ? notificationLogPage.page * 10 : 0,
            limit: 10,
            status: statusName(filterValue),
            searchTerm: emailSearch.length > 0 ? emailSearch : '',
          };
    if (currentTab === 'notification') {
      if (MOCK_USER_API) {
        setNotificationLog(MOCK_Notification);
        setNotificationLogTotal(500);
        setLogLoader(false);
      } else {
        postJson(
          !localApi
            ? slackIntegrationUrl(
                `/slack/${workspaceName}/notification/${history.id}`
              )
            : `http://dev-assistant.workativ.ai/slack-api-v2/slack/${workspaceName}/notification/${history.id}`
        )(data).then((res) => {
          setNotificationLog(res.data.data);
          setNotificationLogTotal(res.data.totalNum);
          setLogLoader(false);
          console.log(res, 'response');
        });
      }
    }
  };

  const notificationStatsApi = () => {
    setStatsLoader(true);
    if (currentTab === 'notification') {
      postJson(
        !localApi
          ? slackIntegrationUrl(
              `/slack/${workspaceName}/notification/${history.id}/stats`
            )
          : `http://dev-assistant.workativ.ai/slack-api-v2/slack/${workspaceName}/notification/${history.id}/stats`
      )({}).then((res) => {
        setNotificationStats(res.data);
        setStatsLoader(false);
        console.log(res, 'response');
      });
    }
  };
  console.log(notificationData, 'notificationData');

  const [analyticsData, setAnalyticsData] =
    useState<LogsResponseT>(SampleLogsResponse);

  const [request, setRequest] = useState<RequestModes>(All);
  const [dateOptions, setDateOptions] = useState<DateSelectInput[]>(items);
  const [historyPopup, setHistoryPopup] = useState<ApprovalHistoryT | {}>({});

  const [historyRequest, setHistoryRequest] = useState<any>({
    timeInterval: 'today',
    page: 1,
    limit: 50,
  });

  const [state, setState] = useState({
    showAutomationPopup: false,
    selectedAutomation: 'All',
    showStatusPopup: false,
    selectedStatus: 'All',
    showClientPopup: false,
    selectedClient: 'All',
  });

  const [approvalState, setApprovalState] = useState({
    showStatusPopup: false,
    selectedStatus: 'All',
    showTitlePopup: false,
    selectedTitle: 'All',
  });

  const [dateDropdown, setDateDropDownValue] = useState({
    showDatePopup: false,
    selectedDate: { date: 'today', label: 'Today' },
  });

  const [historydateDropdown, setHistorydateDropdown] = useState({
    showDatePopup: false,
    selectedDate: { date: 'today', label: 'Today' },
  });

  const [popupState, setPopupState] = useState({
    loading: false,
    error: false,
    showPopup: false,
    logs: [],
    executionData: {
      serial_no: '',
      execution_id: '',
      status: '',
      automation_id: '',
      platform: '',
      conversation_id: '',
      bot_id: '',
      start_time: '',
      end_time: '',
      automation_name: '',
      automation_type: '',
      executionTime: '',
      executed_by: null,
    },
  });

  const [loading, setLoading] = useState({
    show: true,
    error: { error: false, info: '' },
  });

  const [toggleState, setToggleState] = useState({ toggleOpen0: true });

  const [showConversationPopup, setShowConversationPopup] = useState({
    show: false,
    loading: false,
    error: false,
  });
  const [tryItLog, setTryItLog] = useState([]);
  const [initialApiCall, setInitialApiCall] = useState(true);
  const [stripeLoaded, setStripeLoaded] = useState(false);
  const [showState, setShowState] = useState({ customDatePicker: 'hide' });
  const [logsCustomDates, setLogsCustomDates] = useState<Customdate>({
    from_date: null,
    to_date: null,
  });
  const [historyCustomDates, setHistoryCustomDates] = useState<Customdate>({
    from_date: null,
    to_date: null,
  });

  const workspaceName = props.match.params.workspacename;
  useEffect(() => {
    callApi(request.req);
  }, []);

  function customDateAPIcall(customDates: Customdate) {
    if (currentTab == 'logs') {
      setLogsCustomDates(customDates);
      callApi(request.req, customDates);
    } else {
      setHistoryCustomDates(customDates);
      callHistoryAPICall(customDates);
    }
  }

  function callApi(req: any, custom?: Customdate) {
    setLoading({ ...loading, show: true });
    let apiRequest: any = {
      ...req,
      workspace: workspaceName,
      timeInterval:
        req.timeInterval == 'custom'
          ? {
              start_date: custom ? custom.from_date : logsCustomDates.from_date,
              end_date: custom ? custom.to_date : logsCustomDates.to_date,
            }
          : req.timeInterval,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (initialApiCall) {
      stripeGetApi(`${workspaceName}/info/logs_limit`)
        // Promise.resolve({ output: '30', type: 'success' })
        .then((resp: { output: string; type: 'success' }) => {
          const maxDays = Number(resp.output);
          const daysList = [30, 14, 7]
            .filter((x) => x <= maxDays)
            .map((value) => ({
              date: `last ${value} days`,
              label: `Last ${value} Days`,
            }));

          apiRequest = { ...apiRequest, timeInterval: `last ${maxDays} days` };
          let historyRequests = {
            ...historyRequest,
            timeInterval: `last ${maxDays} days`,
            workspace: workspaceName,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          };

          setHistoryRequest(historyRequests);
          Promise.all([
            postJson(
              automationAPIURLWithWorkspace(
                workspaceName,
                '/automation/analytics/list'
              )
            )(apiRequest),
            postJson(automationApprovalAPIURLWithOutWorkspace('/request/logs'))(
              historyRequests
            ),
          ]).then(([res, history]) => {
            const output = res.data.output as any;
            const historyOutput = history.data.output as any;
            setAnalyticsData(output);
            setBaseResponse(output);
            setBaseHistoryResponse(historyOutput);
            setApprovalData(historyOutput);
            setInitialApiCall(false);
            setDateDropDownValue({
              ...dateDropdown,
              selectedDate: {
                date: `last ${maxDays} days`,
                label: `Last ${maxDays} Days`,
              },
            });
            setHistorydateDropdown({
              ...historydateDropdown,
              selectedDate: {
                date: `last ${maxDays} days`,
                label: `Last ${maxDays} Days`,
              },
            });
            const dateOps = daysList
              .concat(dateOptions)
              .concat(customDateRange);

            setDateOptions(dateOps);
            setRequest({
              ...request,
              req: {
                ...request.req,
                timeInterval: `last ${maxDays} days`,
                workspace: workspaceName,
              },
            });
            setStripeLoaded(true);

            setLoading({ ...loading, show: false });
          });
        })
        .catch((err) => {
          console.log(err);
          setLoading({
            show: false,
            error: {
              error: true,
              info: 'Server error!<br />Please try again after sometime.',
            },
          });
        });
    } else {
      postJson(
        automationAPIURLWithWorkspace(
          workspaceName,
          '/automation/analytics/list'
        )
      )(apiRequest)
        .then((res) => {
          const output = res.data.output as any;
          setAnalyticsData(output);
          setBaseResponse(output);
          setLoading({ ...loading, show: false });
        })
        .catch((err) => {
          console.log(err);
          setLoading({
            show: false,
            error: {
              error: true,
              info: 'Server error!<br />Please try again after sometime.',
            },
          });
        });
    }
  }

  useEffect(() => {
    if (!initialApiCall) {
      // if (
      //     (approvalState.selectedTitle != 'All' && approvalState.selectedTitle.length > 0) ||
      //     (approvalState.selectedStatus != 'All' && approvalState.selectedStatus.length > 0)
      // ) {
      setLoading({ ...loading, show: true });

      let req = {
        ...historyRequest,
        timeInterval:
          historyRequest.timeInterval == 'custom'
            ? {
                start_date: historyCustomDates.from_date,
                end_date: historyCustomDates.to_date,
              }
            : historyRequest.timeInterval,
        workspace: workspaceName,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (
        approvalState.selectedTitle != 'All' &&
        approvalState.selectedTitle.length > 0
      ) {
        req = {
          ...req,
          requestTitle: approvalState.selectedTitle,
        };
      }
      if (
        approvalState.selectedStatus != 'All' &&
        approvalState.selectedStatus.length > 0
      ) {
        req = {
          ...req,
          request_status: approvalState.selectedStatus
            .split(' ')
            .join('_')
            .toLowerCase(),
        };
      }

      approvalState.selectedTitle == 'All' && delete req.requestTitle;
      approvalState.selectedStatus == 'All' && delete req.request_status;

      postJson(automationApprovalAPIURLWithOutWorkspace('/request/logs'))(req)
        .then((historys) => {
          const historyOutput = historys.data.output as any;
          setApprovalData(historyOutput);
          setLoading({ ...loading, show: false });
        })
        .catch((err) => {
          console.log(err);
          setLoading({
            show: false,
            error: {
              error: true,
              info: 'Server error!<br />Please try again after sometime.',
            },
          });
        });
    }
  }, [approvalState.selectedTitle, approvalState.selectedStatus]);

  function sortDataWithApi(state: {
    showAutomationPopup: boolean;
    selectedAutomation: string;
    showStatusPopup: boolean;
    selectedStatus: string;
    showClientPopup: boolean;
    selectedClient: string;
    selectedAutomationId: string;
  }) {
    setLoading({ ...loading, show: true });

    statuElementRef.current = true;

    let req = {
      ...request.req,
      automationId: state.selectedAutomationId,
      timeInterval:
        dateDropdown.selectedDate.date == 'custom'
          ? {
              start_date: logsCustomDates.from_date,
              end_date: logsCustomDates.to_date,
            }
          : dateDropdown.selectedDate.date,
      page: 1,
      workspace: workspaceName,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    } as any;

    postJson(
      automationAPIURLWithWorkspace(workspaceName, '/automation/analytics/list')
    )(req)
      .then((res) => {
        const output = res.data.output as LogsResponseT;
        console.log(output);

        const newValue = output.analytics
          .filter((x) => {
            if (state.selectedAutomation === 'All') {
              return x;
            } else if (x.automation_name === state.selectedAutomation) {
              return x;
            } else {
              return undefined;
            }
          })
          .filter((x) => {
            if (state.selectedClient === 'All') {
              return x;
            } else if (x.platform === state.selectedClient) {
              return x;
            } else {
              return undefined;
            }
          })
          .filter((x) => {
            if (state.selectedStatus === 'All') {
              return x;
            } else if (x.status === state.selectedStatus) {
              return x;
            } else {
              return undefined;
            }
          })
          .filter(Boolean);

        const totalExecutionCount =
          newValue.length === output.analytics.length
            ? output.totalExecutionCount
            : newValue.length;
        setAnalyticsData({
          ...analyticsData,
          analytics: newValue,
          totalExecutionCount,
        });
        setRequest({
          type: RequestType.IndividualFlow,
          req: req,
        });
        setLoading({ ...loading, show: false });
      })
      .catch((err) => {
        console.log(err);
        setLoading({
          show: false,
          error: {
            error: true,
            info: 'Server error!<br />Please try again after sometime.',
          },
        });
      });
  }

  useEffect(() => {
    if (popupState.showPopup) {
      setPopupState({ ...popupState, loading: true, error: false });
      getJson(
        automationAPIURLWithWorkspace(
          workspaceName,
          '/automation/' + popupState.executionData.execution_id + '/logs'
        )
      )
        // getJson("https://dev-assistant.workativ.ai/automation-api/manjulaworkspace/automation/exec_mYIaS6L5E5yxGDrt9Io5C/logs")
        .then((data) => {
          setPopupState({
            ...popupState,
            logs: data.output.normalView,
            loading: false,
            error: false,
            executionData: {
              ...popupState.executionData,
              executed_by: data.output.executed_by,
            },
          });
          const state: any = {};
          data.output.normalView.map((data: any, i: any) => {
            state[`toggleOpen${i}`] = i === 0 ? true : false;
          });
          logEvent(workspaceName, {
            event: 'EVENT::WORKFLOW_LOGS::VIEW',
            event_meta: {},
          });
          setToggleState(state);
        })
        .catch((err) => {
          setPopupState({
            ...popupState,
            loading: false,
            error: err.response
              ? err.response.data.error
              : 'Something went wrong!<br/> Please try again after some time.',
          });
        });
    }
  }, [popupState.showPopup]);

  // for changing page
  useEffect(() => {
    if (request.type == RequestType.AllFlows) {
      setState({
        showAutomationPopup: false,
        selectedAutomation: 'All',
        showStatusPopup: false,
        selectedStatus: 'All',
        showClientPopup: false,
        selectedClient: 'All',
      });
    }
    if (statuElementRef.current == null) {
      stripeLoaded && callApi(request.req);
    } else {
      statuElementRef.current = null;
    }
  }, [request.req.page]);

  useEffect(() => {
    if (statuElementRef.current == null) {
      if (stripeLoaded && dateDropdown.selectedDate.date != 'custom') {
        request.type == RequestType.AllFlows &&
          setState({
            ...state,
            showAutomationPopup: false,
            selectedAutomation: 'All',
            showStatusPopup: false,
            showClientPopup: false,
          });

        callApi(
          request.type == RequestType.IndividualFlow
            ? {
                ...All.req,
                timeInterval: dateDropdown.selectedDate.date,
                workspace: workspaceName,
              }
            : request.req
        );
      }
    } else {
      statuElementRef.current = null;
    }
  }, [request.req.timeInterval]);

  const openConvo = (conversationId: string, botId: string) => {
    setShowConversationPopup({
      ...showConversationPopup,
      show: true,
      loading: true,
    });
    getJson(
      watsonLogsApiURL(
        `/${workspaceName}/logs/${botId}/${getDateNumber(
          dateDropdown.selectedDate.date
        )}?conversation_id=${conversationId}`
      )
    ).then((e: any) => {
      setTryItLog(
        e.map((e: any) => {
          const concatedResponse = e.response.reduce(
            (acc: any, curr: any) => {
              return {
                ...acc,
                intents: curr.intents,
                entities: curr.entities,
                context: curr.context,
                faq: curr.faq,
                output: acc.output.concat(curr.output),
              };
            },
            { intents: [], entities: [], faq: [], context: null, output: [] }
          );
          return {
            text: e.request.input,
            masked: e.masked,
            intents: concatedResponse.intents.map((intent: any) => intent),
            entities: concatedResponse.entities.map((entity: any) => entity),
            context: concatedResponse.context,
            faq: concatedResponse.faq,
            output: concatedResponse.output
              .map((e: any) =>
                e.output == 'text'
                  ? {
                      response_type: 'text',
                      text: e.text,
                    }
                  : e.output == 'option'
                  ? {
                      response_type: 'text',
                      title: e.option.title,
                      options: e.option.options.map((e: any) => ({
                        label: e.label,
                      })),
                    }
                  : e.output == 'adaptive_card'
                  ? {
                      response_type: 'text',
                      title: e.adaptive_card.blocks.filter(
                        (b: any) => b.text && b.text.style == 'bold'
                      )[0].text.text,
                      description: e.adaptive_card.blocks.filter(
                        (b: any) => b.text && b.text.style == 'normal'
                      )[0].text.text,
                      fields: e.adaptive_card.blocks.filter(
                        (b: any) => b.fields
                      )[0].fields,
                      buttons: e.adaptive_card.blocks.filter(
                        (b: any) => b.elements
                      )[0].elements,
                      image: e.adaptive_card.blocks.filter(
                        (b: any) => b.fields
                      )[0].accessory,
                      adaptive_card: 'adaptive_card',
                    }
                  : e.type == 'Carousal'
                  ? {
                      response_type: 'text',
                      values: e.accumulate_carousal.map((carousal: any) => ({
                        title: carousal.blocks.filter(
                          (b: any) => b.text && b.text.style == 'bold'
                        )[0].text.text,
                        description: carousal.blocks.filter(
                          (b: any) => b.text && b.text.style == 'normal'
                        )[0].text.text,
                        fields: carousal.blocks.filter((b: any) => b.fields)[0]
                          .fields,
                        buttons: carousal.blocks.filter(
                          (b: any) => b.elements
                        )[0].elements,
                        image: carousal.blocks.filter((b: any) => b.fields)[0]
                          .accessory,
                      })),
                      adaptive_card: 'carousal',
                    }
                  : null
              )
              .filter(Boolean),
          };
        })
      );
      setShowConversationPopup({
        ...showConversationPopup,
        show: true,
        loading: false,
      });
    });
  };

  const changeSort = (sort: any) => {
    const aParams: RequestModes = {
      ...sort,
      req: {
        ...sort.req,
        sortOrder:
          sort.req.sortColumn === request.req.sortColumn
            ? sort.req.sortOrder === 'ascending'
              ? 'descending'
              : 'ascending'
            : 'ascending',
      },
    };
    setRequest(aParams);
    callApi(aParams.req);
  };

  function sortBasedOnDropDown(state: {
    showAutomationPopup: boolean;
    selectedAutomation: string;
    showStatusPopup: boolean;
    selectedStatus: string;
    showClientPopup: boolean;
    selectedClient: string;
  }) {
    setLoading({ ...loading, show: true });
    statuElementRef.current = true;
    let req = {
      ...request.req,
      page: 1,
      workspace: workspaceName,
      timeInterval:
        dateDropdown.selectedDate.date == 'custom'
          ? {
              start_date: logsCustomDates.from_date,
              end_date: logsCustomDates.to_date,
            }
          : dateDropdown.selectedDate.date,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      filter: [
        {
          field: 'executionStatus',
          value: state.selectedStatus !== 'All' ? state.selectedStatus : '',
        },
        {
          field: 'clientName',
          value: state.selectedClient !== 'All' ? state.selectedClient : '',
        },
      ],
    } as any;

    postJson(
      automationAPIURLWithWorkspace(workspaceName, '/automation/analytics/list')
    )(req)
      .then((res) => {
        const output = res.data.output as LogsResponseT;
        console.log(output);

        const newValue = output.analytics
          .filter((x) => {
            if (
              state.selectedAutomation === 'All' ||
              state.selectedAutomation === 'All'
            ) {
              return x;
            } else if (x.automation_name === state.selectedAutomation) {
              return x;
            } else {
              return undefined;
            }
          })
          .filter((x) => {
            if (state.selectedClient === 'All') {
              return x;
            } else if (x.platform === state.selectedClient) {
              return x;
            } else {
              return undefined;
            }
          })
          .filter((x) => {
            if (state.selectedStatus === 'All') {
              return x;
            } else if (x.status === state.selectedStatus) {
              return x;
            } else {
              return undefined;
            }
          })
          .filter(Boolean);

        const totalExecutionCount =
          newValue.length === output.analytics.length
            ? output.totalExecutionCount
            : newValue.length;

        setAnalyticsData({
          ...analyticsData,
          analytics: newValue,
          totalExecutionCount,
        });

        setRequest(
          state.selectedAutomation === 'All'
            ? { type: RequestType.AllFlows, req: req }
            : {
                type: RequestType.IndividualFlow,
                req: req,
              }
        );

        setLoading({ ...loading, show: false });
        statuElementRef.current = null;
      })
      .catch((err) => {
        console.log(err);
        setLoading({
          show: false,
          error: {
            error: true,
            info: 'Server error!<br />Please try again after sometime.',
          },
        });
      });
  }

  function setSelectedDate(date: { date: { date: string; label: string } }) {
    setDateDropDownValue({
      selectedDate: { label: date.date.label, date: date.date.date },
      showDatePopup: false,
    });

    setRequest({
      ...request,
      req: {
        ...request.req,
        timeInterval: date.date.date,
        page: 1,
      },
    });
  }

  function setSelectedHistoryDate(date: {
    date: { date: string; label: string };
  }) {
    setHistorydateDropdown({
      selectedDate: { label: date.date.label, date: date.date.date },
      showDatePopup: false,
    });
    setHistoryRequest({ ...historyRequest, timeInterval: date.date.date });
  }

  useEffect(() => {
    if (stripeLoaded && donotload.current == null) {
      if (
        historydateDropdown.selectedDate.date != 'custom' ||
        historyCustomDates.from_date != null
      ) {
        callHistoryAPICall();
      }
    } else if (donotload.current == true) {
      donotload.current = null;
    }
  }, [historyRequest]);

  function callHistoryAPICall(custom?: Customdate) {
    let req = {
      ...historyRequest,
      timeInterval:
        historyRequest.timeInterval == 'custom'
          ? {
              start_date: custom
                ? custom.from_date
                : historyCustomDates.from_date,
              end_date: custom ? custom.to_date : historyCustomDates.to_date,
            }
          : historyRequest.timeInterval,
      workspace: workspaceName,
      page: historyRequest.page,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (
      approvalState.selectedTitle != 'All' &&
      approvalState.selectedTitle.length > 0
    ) {
      req = {
        ...req,
        requestTitle: approvalState.selectedTitle,
      };
    }
    if (
      approvalState.selectedStatus != 'All' &&
      approvalState.selectedStatus.length > 0
    ) {
      req = {
        ...req,
        request_status: approvalState.selectedStatus
          .split(' ')
          .join('_')
          .toLowerCase(),
      };
    }

    approvalState.selectedTitle == 'All' && delete req.requestTitle;
    approvalState.selectedStatus == 'All' && delete req.request_status;

    setLoading({ ...loading, show: true });
    postJson(automationApprovalAPIURLWithOutWorkspace('/request/logs'))(req)
      .then((historys) => {
        const historyOutput = historys.data.output as any;
        setApprovalData(historyOutput);
        setBaseHistoryResponse(historyOutput);
        setApprovalState({
          showStatusPopup: false,
          selectedStatus: 'All',
          showTitlePopup: false,
          selectedTitle: 'All',
        });
        setLoading({ ...loading, show: false });
      })
      .catch((err) => {
        console.log(err);
        setLoading({
          show: false,
          error: {
            error: true,
            info: 'Server error!<br />Please try again after sometime.',
          },
        });
      });
  }

  function returnIcon(platform: string) {
    switch (platform) {
      case 'try_it':
        return <TryMeanalyticsIcon />;
      case 'slack':
        return <SlackIconCC />;
      case 'teams':
        return <TeamsIcon />;
      case 'widget':
        return <WidgetIcon />;
      case 'Application':
        return <AppTriggerCardIconNew />;
      case 'Webhook':
        return <WebhookCardIcon />;
      default:
        return <TestanalyticsIcon />;
    }
  }
  const [feedbackDetails, setFeedbackDetails] = useState<null | number>(null);
  const [requestedDetails, setRequestedDetails] = useState<null | number>(null);

  if (loading.show)
    return <Loader.PageLoader show={true} className="analytics_loader" />;

  const IsAssistant = getProductId() === 'ASSISTANT';

  return (
    <React.Fragment>
      {/* <Header>
                <HeaderChild type="first">
                    <div className="indent_left">
                        <div className="indent_title">Workflow Logs</div>
                    </div>
                </HeaderChild>
                <HeaderChild type="second">
                    <VideoButtonB id={'READ_APP_WORKFLOW_APPROVAL_LOGS'} />
                </HeaderChild>
            </Header> */}

      <ErrorBoundary
        error={loading.error}
        render={(err: any, info: any) => {
          return (
            err && (
              <Error.PageLoadingError
                onClick={() => callApi(request.req)}
                info={info}
                className={'analytics_page_error'}
                btnName="Retry"
              />
            )
          );
        }}
      >
        <div
          className="entity_cards"
          style={{ marginTop: '0px', padding: 0, height: 'calc(100vh - 80px)' }}
        >
          <div className="col-md-12">
            <div className="row">
              <TabContainer
                style={{
                  padding: '12px 20px 10px 20px',
                  background: 'transparent',
                  boxShadow: 'none',
                  marginBottom: 0,
                  height: '60px',
                }}
              >
                <TabRow
                  className="automation_submenu"
                  style={{ justifyContent: 'flex-start' }}
                >
                  <TabHeader
                    active={currentTab == 'logs'}
                    onClick={() => setCurrentTab('logs')}
                  >
                    Workflow Logs
                  </TabHeader>
                  <TabHeader
                    active={currentTab == 'approval'}
                    onClick={() => setCurrentTab('approval')}
                  >
                    Approval History
                  </TabHeader>
                  <TabHeader
                    active={currentTab == 'notification'}
                    onClick={() => setCurrentTab('notification')}
                  >
                    Notification History
                  </TabHeader>
                </TabRow>
              </TabContainer>
              {currentTab == 'approval' && (
                <>
                  <DropdownContainer className='approval_std'>
                    <div className="tab_container_left">
                      <Dropdwon
                        header={'Request title'}
                        content="All"
                        showState={{ showPopup: approvalState.showTitlePopup }}
                        onClick={() =>
                          setApprovalState({
                            ...approvalState,
                            showStatusPopup: false,
                            showTitlePopup: true,
                          })
                        }
                        value={capitalizeFirstLetter(
                          approvalState.selectedTitle != 'All' &&
                            approvalState.selectedTitle.length > 0
                            ? approvalState.selectedTitle
                            : 'All'
                        )}
                        selectedvalue={(value: string) => {
                          setApprovalState({
                            ...approvalState,
                            selectedTitle: value,
                            showTitlePopup: false,
                          });
                        }}
                        defaultValue={'All'}
                        closePopup={() => {
                          setApprovalState({
                            ...approvalState,
                            showTitlePopup: false,
                          });
                        }}
                        analyticsData={{
                          data: filterData(
                            baseHistory.requestTitleList,
                            'title'
                          ),
                        }}
                      />
                      <Dropdwon
                        header={'Status'}
                        content="All"
                        showState={{ showPopup: approvalState.showStatusPopup }}
                        onClick={() =>
                          setApprovalState({
                            ...approvalState,
                            showStatusPopup: true,
                            showTitlePopup: false,
                          })
                        }
                        value={approvalState.selectedStatus}
                        selectedvalue={(value: string) => {
                          donotload.current = true;
                          setHistoryRequest({
                            ...historyRequest,
                            page: 1,
                          });

                          setApprovalState({
                            ...approvalState,
                            selectedStatus: value,
                            showStatusPopup: false,
                          });
                        }}
                        defaultValue={'All'}
                        closePopup={() => {
                          setApprovalState({
                            ...approvalState,
                            showStatusPopup: false,
                          });
                        }}
                        analyticsData={{
                          data: filterData(
                            baseHistory.requests,
                            'request_status'
                          ),
                        }}
                      />
                    </div>
                    <div className="tab_container_right">
                      <DateDropDown
                        header={'Show by'}
                        content="All"
                        state={historydateDropdown}
                        displayItems={dateOptions}
                        setDateDropDownValue={setHistorydateDropdown}
                        setSelectedDate={setSelectedHistoryDate}
                        onClick={() =>
                          setHistorydateDropdown({
                            ...historydateDropdown,
                            showDatePopup: true,
                          })
                        }
                        closePopup={() => {
                          setHistorydateDropdown({
                            ...historydateDropdown,
                            showDatePopup: false,
                          });
                        }}
                        showCustomDatePicker={() => {
                          setShowState({ customDatePicker: 'show' });
                        }}
                      />
                      {showState.customDatePicker == 'show' && (
                        <div className="tab_dropdown_date dialog_logs">
                          <div className="tab_calender">
                            <BasicDateRangePicker
                              setDate={(date: Date[]) => {
                                const customDates = {
                                  from_date: convertFromToDateForWorkFlow(
                                    date[0].toString()
                                  ) as any,
                                  to_date: convertFromToDateForWorkFlow(
                                    date[1].toString()
                                  ) as any,
                                };
                                customDateAPIcall(customDates);
                                setShowState({
                                  customDatePicker: 'hide',
                                });
                              }}
                              show={showState.customDatePicker == 'show'}
                              minDate={
                                new Date(
                                  moment()
                                    .add(1, 'day')
                                    .subtract(1, 'year')
                                    .toString()
                                )
                              }
                              customDates={historyCustomDates}
                              closeCalendar={() => {
                                setShowState({
                                  customDatePicker: 'hide',
                                });
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <p>
                        {historyRequest.page === 1
                          ? approvalData.total_count > 0
                            ? 1
                            : 0
                          : (historyRequest.page - 1) * 50 + 1}{' '}
                        -{' '}
                        {approvalData.total_count > historyRequest.page * 50
                          ? historyRequest.page * 50
                          : approvalData.total_count}{' '}
                        of {approvalData.total_count}
                        <span
                          className={
                            historyRequest.page !== 1
                              ? 'prev_icon'
                              : 'prev_icon editor_btn_disabled'
                          }
                          onClick={() => {
                            setHistoryRequest({
                              ...historyRequest,
                              page: historyRequest.page - 1,
                            });
                          }}
                        >
                          <DropdownWithCircle />
                        </span>
                        <span
                          className={
                            approvalData.total_count > historyRequest.page * 50
                              ? 'next_icon'
                              : 'next_icon editor_btn_disabled'
                          }
                          onClick={() =>
                            setHistoryRequest({
                              ...historyRequest,
                              page: historyRequest.page + 1,
                            })
                          }
                        >
                          <DropdownWithCircle />
                        </span>{' '}
                      </p>
                    </div>
                  </DropdownContainer>
                  <div className="automation_logs">
                    <TabelWrapper>
                      <TableContainer>
                        {approvalData.requests.length > 0 ? (
                          <Table>
                            <Thead>
                              <Tr>
                                <Th size={2.6}>Request title</Th>
                                <Th size={2.4}>Status</Th>
                                <Th size={3.2}>Requested by</Th>
                                <Th size={3.2}>Assigned to</Th>
                                <Th
                                  style={{ position: 'relative', left: '30px' }}
                                  size={0.6}
                                >
                                  Logs
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody className="automate_logs_history">
                              {approvalData.requests.map((h, i) => (
                                <Tr
                                  className="automate_logs_history_rows"
                                  key={i}
                                >
                                  <Td size={2.6}>
                                    <div className="td_header">{h.title}</div>
                                    <div className="td_date">
                                      {formatDateInCard(h.createdDate)} |{' '}
                                      {formatAMPM(h.createdDate)}
                                    </div>
                                  </Td>
                                  <Td
                                    size={2.4}
                                    style={{
                                      color:
                                        h.request_status ==
                                        'Waiting for approval'
                                          ? '#E8790A'
                                          : h.request_status == 'Approved'
                                          ? '#0BB251'
                                          : '#DD4330',
                                      paddingLeft:
                                        h.request_status ==
                                        'Waiting for approval'
                                          ? '0'
                                          : h.request_status == 'Approved'
                                          ? '20'
                                          : '0',
                                    }}
                                  >
                                    {h.request_status == 'Waiting for approval'
                                      ? 'Waiting for approval'
                                      : h.request_status}
                                  </Td>
                                  <Td
                                    size={3.2}
                                    onMouseEnter={() => {
                                      setRequestedDetails(i);
                                    }}
                                    onMouseLeave={(e: any) => {
                                      e.preventDefault();
                                      setRequestedDetails(null);
                                    }}
                                  >
                                    {h.requester_email}

                                    {requestedDetails == i && (
                                      <Downshift
                                        isOpen
                                        onOuterClick={() =>
                                          setRequestedDetails(null)
                                        }
                                      >
                                        {() => (
                                          <span>
                                            <EntityWrapper
                                              className="approval_history_requested_box"
                                              style={{
                                                marginLeft: 0,
                                                position: 'absolute',
                                                display: 'none',
                                              }}
                                            >
                                              <TrainHeader>
                                                <label>Requested by</label>
                                              </TrainHeader>
                                              <UserWrapper>
                                                <p className="automate_logs_requested">
                                                  {h.requester_email}
                                                </p>
                                              </UserWrapper>
                                            </EntityWrapper>
                                          </span>
                                        )}
                                      </Downshift>
                                    )}
                                  </Td>
                                  <Td
                                    size={3.2}
                                    onMouseEnter={() => {
                                      setFeedbackDetails(i);
                                    }}
                                    onMouseLeave={(e: any) => {
                                      e.preventDefault();
                                      setFeedbackDetails(null);
                                    }}
                                  >
                                    {h.approval[0].approver_email}
                                    {feedbackDetails == i && (
                                      <Downshift
                                        isOpen
                                        onOuterClick={() =>
                                          setFeedbackDetails(null)
                                        }
                                      >
                                        {() => (
                                          <span>
                                            <EntityWrapper
                                              className="approval_history_assigned_box"
                                              style={{
                                                marginLeft: 0,
                                                position: 'absolute',
                                                display: 'none',
                                              }}
                                            >
                                              <TrainHeader>
                                                <label>Assigned To</label>
                                              </TrainHeader>
                                              <UserWrapper>
                                                <p className="automate_logs_assigned">
                                                  {h.approval[0].approver_email}
                                                </p>
                                              </UserWrapper>
                                            </EntityWrapper>
                                          </span>
                                        )}
                                      </Downshift>
                                    )}
                                  </Td>
                                  <Td
                                    size={0.6}
                                    onClick={() => {
                                      logEvent(workspaceName, {
                                        event: 'EVENT::APPROVAL_LOGS::VIEW',
                                        event_meta: {},
                                      });
                                      setHistoryPopup(h);
                                    }}
                                    color={
                                      h.request_status == 'Waiting for approval'
                                        ? '#E8790A'
                                        : h.request_status == 'Approved'
                                        ? '#0BB251'
                                        : '#DD4330'
                                    }
                                    style={{ justifyContent: 'flex-end' }}
                                  >
                                    <SmallButton primary>
                                      <label>View</label>
                                    </SmallButton>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        ) : (
                          <div>
                            <p className="autoamtion_analytics_nologs">
                              No logs available
                            </p>
                          </div>
                        )}
                      </TableContainer>
                    </TabelWrapper>
                    {Object.keys(historyPopup).length > 0 && (
                      <ApprovalPopup
                        historyPopup={historyPopup as ApprovalHistoryT}
                        closePopup={() => {
                          setHistoryPopup({});
                        }}
                      />
                    )}
                  </div>
                </>
              )}
              {currentTab == 'logs' && (
                <>
                  <DropdownContainer className="workflow_logs">
                    <div className="tab_container_left">
                      <Dropdwon
                        header={'Workflow'}
                        content="All"
                        showState={{ showPopup: state.showAutomationPopup }}
                        onClick={() =>
                          setState({
                            ...state,
                            showAutomationPopup: true,
                            showClientPopup: false,
                            showStatusPopup: false,
                          })
                        }
                        value={state.selectedAutomation}
                        selectedvalue={(value: string) => {
                          setState({
                            ...state,
                            selectedAutomation: value,
                            showAutomationPopup: false,
                          });
                          sortDataWithApi({
                            ...state,
                            selectedAutomation: value,
                            selectedAutomationId:
                              baseResponse.automationList.filter(
                                (automation) => automation.name == value
                              ).length > 0
                                ? baseResponse.automationList.filter(
                                    (automation) => automation.name == value
                                  )[0].id
                                : '',
                          });
                        }}
                        defaultValue={'All'}
                        closePopup={() => {
                          setState({ ...state, showAutomationPopup: false });
                        }}
                        analyticsData={{
                          data: baseResponse.automationList.map((x) => x.name),
                        }}
                      />
                      {IsAssistant && (
                        <Dropdwon
                          header={'Chat channel'}
                          content="All"
                          showState={{ showPopup: state.showClientPopup }}
                          onClick={() =>
                            setState({
                              ...state,
                              showAutomationPopup: false,
                              showClientPopup: true,
                              showStatusPopup: false,
                            })
                          }
                          value={capitalizeFirstLetter(
                            state.selectedClient == 'try_it'
                              ? 'try Me'
                              : state.selectedClient
                          )}
                          selectedvalue={(value: string) => {
                            setState({
                              ...state,
                              selectedClient: value,
                              showClientPopup: false,
                            });
                            sortBasedOnDropDown({
                              ...state,
                              selectedClient: value,
                            });
                          }}
                          defaultValue={'All'}
                          closePopup={() => {
                            setState({ ...state, showClientPopup: false });
                          }}
                          analyticsData={{
                            data: allPlatforms.map((x) => x.value),
                          }}
                        />
                      )}
                      <Dropdwon
                        header={'Status'}
                        content="All"
                        showState={{ showPopup: state.showStatusPopup }}
                        onClick={() =>
                          setState({
                            ...state,
                            showAutomationPopup: false,
                            showClientPopup: false,
                            showStatusPopup: true,
                          })
                        }
                        value={state.selectedStatus}
                        selectedvalue={(value: string) => {
                          setState({
                            ...state,
                            selectedStatus: value,
                            showStatusPopup: false,
                          });
                          sortBasedOnDropDown({
                            ...state,
                            selectedStatus: value,
                          });
                        }}
                        defaultValue={'All'}
                        closePopup={() => {
                          setState({ ...state, showStatusPopup: false });
                        }}
                        analyticsData={{
                          data: allExecutionStatus.map((x) => x.value),
                        }}
                      />
                    </div>
                    <div className="tab_container_right">
                      <DateDropDown
                        header={'Show by'}
                        content="All"
                        state={dateDropdown}
                        displayItems={dateOptions}
                        setDateDropDownValue={setDateDropDownValue}
                        setSelectedDate={setSelectedDate}
                        onClick={() =>
                          setDateDropDownValue({
                            ...dateDropdown,
                            showDatePopup: true,
                          })
                        }
                        closePopup={() => {
                          setDateDropDownValue({
                            ...dateDropdown,
                            showDatePopup: false,
                          });
                        }}
                        showCustomDatePicker={() => {
                          setShowState({ customDatePicker: 'show' });
                        }}
                      />
                      {showState.customDatePicker == 'show' && (
                        <div className="tab_dropdown_date dialog_logs">
                          <div className="tab_calender">
                            <BasicDateRangePicker
                              setDate={(date: Date[]) => {
                                const customDates = {
                                  from_date: convertFromToDateForWorkFlow(
                                    date[0].toString()
                                  ) as any,
                                  to_date: convertFromToDateForWorkFlow(
                                    date[1].toString()
                                  ) as any,
                                };
                                customDateAPIcall(customDates);
                                setShowState({
                                  customDatePicker: 'hide',
                                });
                              }}
                              show={showState.customDatePicker == 'show'}
                              minDate={
                                new Date(
                                  moment()
                                    .add(1, 'day')
                                    .subtract(1, 'year')
                                    .toString()
                                )
                              }
                              customDates={logsCustomDates}
                              closeCalendar={() => {
                                setShowState({
                                  customDatePicker: 'hide',
                                });
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <p>
                        {request.req.page === 1
                          ? analyticsData.totalExecutionCount > 0
                            ? 1
                            : 0
                          : (request.req.page - 1) * 50 + 1}{' '}
                        -{' '}
                        {analyticsData.totalExecutionCount >
                        request.req.page * 50
                          ? request.req.page * 50
                          : analyticsData.totalExecutionCount}{' '}
                        of {analyticsData.totalExecutionCount}
                        <span
                          className={
                            request.req.page !== 1
                              ? 'prev_icon'
                              : 'prev_icon editor_btn_disabled'
                          }
                          onClick={() => {
                            setRequest({
                              ...request,
                              req: {
                                ...request.req,
                                page: request.req.page - 1,
                                timeInterval: dateDropdown.selectedDate.date,
                              },
                            });
                          }}
                        >
                          <DropdownWithCircle />
                        </span>
                        <span
                          className={
                            analyticsData.totalExecutionCount >
                            request.req.page * 50
                              ? 'next_icon'
                              : 'next_icon editor_btn_disabled'
                          }
                          onClick={() =>
                            setRequest({
                              ...request,
                              req: {
                                ...request.req,
                                page: request.req.page + 1,
                                timeInterval: dateDropdown.selectedDate.date,
                              },
                            })
                          }
                        >
                          <DropdownWithCircle />
                        </span>{' '}
                      </p>
                    </div>
                  </DropdownContainer>
                  <div className="automation_logs">
                    <div className="">
                      {analyticsData.analytics.length > 0 ? (
                        <TabelWrapper>
                          <TableContainer>
                            <Table>
                              <Thead>
                                <Tr>
                                  {getProductId() === 'ASSISTANT' ? (
                                    <>
                                      <Th size={3}>Name</Th>
                                      <Th size={2}>Triggered by</Th>
                                      <Th size={2}>Conversations</Th>
                                      <Th size={2.4}>Time taken to execute</Th>
                                      <Th size={1.8}>Status</Th>
                                      <Th
                                        style={{
                                          position: 'relative',
                                          left: '12px',
                                        }}
                                        size={0.8}
                                      >
                                        Logs
                                      </Th>
                                    </>
                                  ) : (
                                    <>
                                      <Th size={3.5}>Name</Th>
                                      {/* <Th size={3}>Name</Th> */}
                                      {/* <Th size={2}>
                                                    <span
                                                        onClick={() =>
                                                            changeSort({
                                                                ...request,
                                                                req: {
                                                                    ...request.req,
                                                                    sortColumn: 'executionStartTime'
                                                                }
                                                            })
                                                        }
                                                    >
                                                        {request.req.sortColumn === 'executionStartTime' &&
                                                        request.req.sortOrder === 'descending' ? (
                                                            <AnalyticsAscendingSortIcon />
                                                        ) : (
                                                            <AnalyticsDescendingSortIcon />
                                                        )}
                                                    </span>
                                                    Date & Time
                                                </Th> */}
                                      <Th size={2}>Triggered by</Th>
                                      <Th size={3.5}>Time taken to execute</Th>
                                      <Th size={IsAssistant ? 1.3 : 2.3}>
                                        Status
                                      </Th>
                                      {/* <Th size={2}>Chat channel</Th>
                                                                <Th size={2}>Conversations</Th>
                                                                <Th size={3}>Time taken to execute</Th>
                                                                <Th size={1.3}>Status</Th> */}
                                      <Th size={0.7}>Logs</Th>
                                    </>
                                  )}
                                </Tr>
                              </Thead>
                              <Tbody>
                                {analyticsData.analytics.map((execution, i) => {
                                  // if (execution.status.toLowerCase() === 'success') {
                                  return (
                                    <Tr key={i}>
                                      {getProductId() === 'ASSISTANT' ? (
                                        <>
                                          <Td size={3}>
                                            <div className="td_header">
                                              {execution.automation_name}
                                            </div>
                                            <div className="td_date">
                                              {formatDateInCard(
                                                execution.start_time
                                              )}{' '}
                                              {formatAMPM(execution.start_time)}
                                            </div>
                                          </Td>
                                          <Td size={2}>
                                            <div className="td_cc_img">
                                              {execution.platform
                                                ? returnIcon(execution.platform)
                                                : null}
                                            </div>
                                            {execution.platform == 'try_it'
                                              ? 'try_me'
                                              : execution.platform}
                                          </Td>
                                          <Td size={2}>
                                            {execution.conversation_id !=
                                              'N/A' &&
                                            execution.bot_id != 'N/A' ? (
                                              <SmallButton
                                                primary
                                                className={
                                                  popupState.showPopup ||
                                                  showConversationPopup.show
                                                    ? 'editor_btn_disabled'
                                                    : ''
                                                }
                                                onClick={() =>
                                                  openConvo(
                                                    execution.conversation_id,
                                                    execution.bot_id
                                                  )
                                                }
                                                disabled={
                                                  popupState.showPopup ||
                                                  showConversationPopup.show
                                                }
                                              >
                                                <label>View</label>
                                              </SmallButton>
                                            ) : (
                                              // '-'
                                              <>-</>
                                            )}
                                          </Td>
                                          <Td size={2.4}>
                                            {execution.executionTime}
                                          </Td>
                                          <Td
                                            size={1.8}
                                            style={{
                                              color:
                                                execution.status ==
                                                  'In progress' ||
                                                execution.status ==
                                                  'Waiting for approval'
                                                  ? '#E8790A'
                                                  : execution.status ==
                                                    'Success'
                                                  ? '#0BB251'
                                                  : '#DD4330',
                                            }}
                                          >
                                            {execution.status ==
                                              'In progress' ||
                                            execution.status ==
                                              'Waiting for approval'
                                              ? 'In progress'
                                              : execution.status == 'Success'
                                              ? 'Success'
                                              : 'Failure'}
                                          </Td>
                                          <Td size={0.8}>
                                            <SmallButton
                                              primary
                                              className={
                                                popupState.showPopup ||
                                                showConversationPopup.show
                                                  ? ' editor_btn_disabled'
                                                  : ''
                                              }
                                              onClick={() =>
                                                setPopupState({
                                                  ...popupState,
                                                  showPopup: true,
                                                  executionData: {
                                                    ...execution,
                                                    executed_by: null,
                                                  },
                                                })
                                              }
                                              disabled={
                                                popupState.showPopup ||
                                                showConversationPopup.show
                                              }
                                              style={{
                                                color:
                                                  execution.status ==
                                                    'In progress' ||
                                                  execution.status ==
                                                    'Waiting for approval'
                                                    ? '#E8790A'
                                                    : execution.status ==
                                                      'Success'
                                                    ? '#0BB251'
                                                    : '#DD4330',
                                                border:
                                                  execution.status ==
                                                    'In progress' ||
                                                  execution.status ==
                                                    'Waiting for approval'
                                                    ? '1px solid #E8790A'
                                                    : execution.status ==
                                                      'Success'
                                                    ? '1px solid #0BB251'
                                                    : '1px solid #DD4330',
                                              }}
                                            >
                                              <label>View</label>
                                            </SmallButton>
                                          </Td>
                                        </>
                                      ) : (
                                        <>
                                          <Td size={3.5}>
                                            <div className="td_header">
                                              {execution.automation_name}
                                            </div>
                                            <div className="td_date">
                                              {formatDateInCard(
                                                execution.start_time
                                              )}{' '}
                                              {formatAMPM(execution.start_time)}
                                            </div>
                                          </Td>
                                          <Td size={2.5}>
                                            <div className="td_cc_img">
                                              {execution.platform
                                                ? returnIcon(execution.platform)
                                                : null}
                                            </div>
                                            {execution.platform == 'try_it'
                                              ? 'try_me'
                                              : execution.platform}
                                          </Td>
                                          <Td size={3}>
                                            {execution.executionTime}
                                          </Td>
                                          <Td
                                            size={2.2}
                                            style={{
                                              color:
                                                execution.status ==
                                                  'In progress' ||
                                                execution.status ==
                                                  'Waiting for approval'
                                                  ? '#E8790A'
                                                  : execution.status ==
                                                    'Success'
                                                  ? '#0BB251'
                                                  : '#DD4330',
                                            }}
                                          >
                                            {execution.status ==
                                              'In progress' ||
                                            execution.status ==
                                              'Waiting for approval'
                                              ? 'In progress'
                                              : execution.status == 'Success'
                                              ? 'Success'
                                              : 'Failure'}
                                          </Td>
                                          <Td size={0.8}>
                                            <SmallButton
                                              primary
                                              className={
                                                popupState.showPopup ||
                                                showConversationPopup.show
                                                  ? ' editor_btn_disabled'
                                                  : ''
                                              }
                                              onClick={() =>
                                                setPopupState({
                                                  ...popupState,
                                                  showPopup: true,
                                                  executionData: {
                                                    ...execution,
                                                    executed_by: null,
                                                  },
                                                })
                                              }
                                              disabled={
                                                popupState.showPopup ||
                                                showConversationPopup.show
                                              }
                                              style={{
                                                color:
                                                  execution.status ==
                                                    'In progress' ||
                                                  execution.status ==
                                                    'Waiting for approval'
                                                    ? '#E8790A'
                                                    : execution.status ==
                                                      'Success'
                                                    ? '#0BB251'
                                                    : '#DD4330',
                                                border:
                                                  execution.status ==
                                                    'In progress' ||
                                                  execution.status ==
                                                    'Waiting for approval'
                                                    ? '1px solid #E8790A'
                                                    : execution.status ==
                                                      'Success'
                                                    ? '1px solid #0BB251'
                                                    : '1px solid #DD4330',
                                              }}
                                            >
                                              <label>View</label>
                                            </SmallButton>
                                          </Td>
                                        </>
                                      )}
                                      {/* <Td size={IsAssistant?3:3.5}>
                                                                        <Td size={3}>
                                                                            <div className="td_header">
                                                                                {execution.automation_name}
                                                                            </div>
                                                                            <div className="td_date">
                                                                                {formatDateInCard(execution.start_time)}{' '}
                                                                                {formatAMPM(execution.start_time)}
                                                                            </div>
                                                                        </Td>
                                                                        <Td size={2.5}>
                                                                            <div className="td_cc_img">
                                                                                {execution.platform
                                                                                    ? returnIcon(execution.platform)
                                                                                    : null}
                                                                            </div>
                                                                            {execution.platform == 'try_it'
                                                                                ? 'try_me'
                                                                                : execution.platform}
                                                                        </Td>
                                                                        { IsAssistant && <Td size={2}>
                                                                            {execution.conversation_id != 'N/A' &&
                                                                            execution.bot_id != 'N/A' ? (
                                                                                <SmallButton
                                                                                    primary
                                                                                    className={
                                                                                        popupState.showPopup ||
                                                                                        showConversationPopup.show
                                                                                            ? 'editor_btn_disabled'
                                                                                            : ''
                                                                                    }
                                                                                    onClick={() =>
                                                                                        openConvo(
                                                                                            execution.conversation_id,
                                                                                            execution.bot_id
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        popupState.showPopup ||
                                                                                        showConversationPopup.show
                                                                                    }
                                                                                >
                                                                                    <label>View</label>
                                                                                </SmallButton>
                                                                            ) : (
                                                                                // '-'
                                                                                <>-</>
                                                                            )}
                                                                        </Td>
                                                            }
                                                                        <Td size={3}>{execution.executionTime}</Td>
                                                                        <Td
                                                                            size={2.2}
                                                                            style={{
                                                                                color:
                                                                                    execution.status == 'In progress' ||
                                                                                    execution.status ==
                                                                                        'Waiting for approval'
                                                                                        ? '#E8790A'
                                                                                        : execution.status == 'Success'
                                                                                        ? '#0BB251'
                                                                                        : '#DD4330'
                                                                            }}
                                                                        >
                                                                            {execution.status == 'In progress' ||
                                                                            execution.status == 'Waiting for approval'
                                                                                ? 'In progress'
                                                                                : execution.status == 'Success'
                                                                                ? 'Success'
                                                                                : 'Failure'}
                                                                        </Td>
                                                                        <Td size={0.8}>
                                                                            <SmallButton
                                                                                primary
                                                                                className={
                                                                                    popupState.showPopup ||
                                                                                    showConversationPopup.show
                                                                                        ? ' editor_btn_disabled'
                                                                                        : ''
                                                                                }
                                                                                onClick={() =>
                                                                                    setPopupState({
                                                                                        ...popupState,
                                                                                        showPopup: true,
                                                                                        executionData: {
                                                                                            ...execution,
                                                                                            executed_by: null
                                                                                        }
                                                                                    })
                                                                                }
                                                                                disabled={
                                                                                    popupState.showPopup ||
                                                                                    showConversationPopup.show
                                                                                }

                                                                                style={{
                                                                                    color:
                                                                                        execution.status == 'In progress' ||
                                                                                        execution.status ==
                                                                                            'Waiting for approval'
                                                                                            ? '#E8790A'
                                                                                            : execution.status == 'Success'
                                                                                            ? '#0BB251'
                                                                                            : '#DD4330',
                                                                                    border:
                                                                                        execution.status == 'In progress' ||
                                                                                        execution.status ==
                                                                                            'Waiting for approval'
                                                                                            ? '1px solid #E8790A'
                                                                                            : execution.status == 'Success'
                                                                                            ? '1px solid #0BB251'
                                                                                            : '1px solid #DD4330'
                                                                                }}
                                                                            >
                                                                                <label>View</label>
                                                                            </SmallButton>
                                                                        </Td> */}
                                    </Tr>
                                  );
                                  // }
                                  // return (
                                  //     <Tr key={i}>
                                  //         <Td size={3}>
                                  //             <div className="td_header">
                                  //                 {execution.automation_name}
                                  //             </div>
                                  //             <div className="td_date">
                                  //                 {execution.start_time}
                                  //             </div>
                                  //         </Td>
                                  //         <Td size={2}>
                                  //             <div className="td_cc_img">
                                  //                 {execution.platform
                                  //                     ? returnIcon(execution.platform)
                                  //                     : null}
                                  //             </div>
                                  //             {execution.platform == 'try_it'
                                  //                 ? 'try_me'
                                  //                 : execution.platform}
                                  //         </Td>
                                  //         <Td size={2}>
                                  //             {execution.conversation_id != 'N/A' &&
                                  //             execution.bot_id != 'N/A' ? (
                                  //                 <button
                                  //                     className={
                                  //                         popupState.showPopup ||
                                  //                         showConversationPopup.show
                                  //                             ? 'btn_account_failed editor_btn_disabled'
                                  //                             : 'btn_account_failed'
                                  //                     }
                                  //                     onClick={() =>
                                  //                         openConvo(
                                  //                             execution.conversation_id,
                                  //                             execution.bot_id
                                  //                         )
                                  //                     }
                                  //                     disabled={
                                  //                         popupState.showPopup ||
                                  //                         showConversationPopup.show
                                  //                     }
                                  //                 >
                                  //                     View
                                  //                 </button>
                                  //             ) : (
                                  //                 '-'
                                  //             )}
                                  //         </Td>
                                  //         <Td size={3}>{execution.executionTime}</Td>
                                  //         <Td size={1.5} style={{ color: '#EB6453' }}>
                                  //             Failure
                                  //         </Td>
                                  //         <Td size={0.5}>
                                  //             <SmallButton
                                  //                 primary
                                  //                 className={
                                  //                     popupState.showPopup ||
                                  //                     showConversationPopup.show
                                  //                         ? 'editor_btn_disabled'
                                  //                         : ''
                                  //                 }
                                  //                 onClick={() =>
                                  //                     setPopupState({
                                  //                         ...popupState,
                                  //                         showPopup: true,
                                  //                         executionData: {
                                  //                             ...execution,
                                  //                             executed_by: null
                                  //                         }
                                  //                     })
                                  //                 }
                                  //                 disabled={
                                  //                     popupState.showPopup ||
                                  //                     showConversationPopup.show
                                  //                 }
                                  //             >
                                  //                 <label>View</label>
                                  //             </SmallButton>
                                  //         </Td>
                                  //     </Tr>
                                  // )
                                })}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </TabelWrapper>
                      ) : (
                        <div>
                          <p className="autoamtion_analytics_nologs">
                            No logs available
                          </p>
                        </div>
                      )}
                      {renderAutomationLogsPopup()}
                      {renderConversationLogsPopup()}
                    </div>
                  </div>
                </>
              )}
              {currentTab == 'notification' && (
                <>
                  <DropdownContainer>
                    <div className="tab_container_left">
                      <Dropdwon
                        header={'Notification title'}
                        content="All"
                        showState={{ showPopup: notifTitleDropdown }}
                        onClick={() =>
                          setNotifTitleDropdown(!notifTitleDropdown)
                        }
                        value={selectedNotifTitle}
                        // {capitalizeFirstLetter(
                        //   approvalState.selectedTitle != 'All' &&
                        //     approvalState.selectedTitle.length > 0
                        //     ? approvalState.selectedTitle
                        //     : 'All'
                        // )}
                        selectedvalue={(value: string) => {
                          setselectedNotifTitle(value);
                          setNotifTitleDropdown(false);
                        }}
                        defaultValue={'All'}
                        closePopup={() => {
                          setNotifTitleDropdown(false);
                        }}
                        analyticsData={{
                          data: notificationTitles,
                        }}
                      />
                      {/* <Dropdwon
                        header={'Send to'}
                        content="All"
                        showState={{ showPopup: approvalState.showStatusPopup }}
                        onClick={() =>
                          setApprovalState({
                            ...approvalState,
                            showStatusPopup: true,
                            showTitlePopup: false,
                          })
                        }
                        value={approvalState.selectedStatus}
                        selectedvalue={(value: string) => {
                          donotload.current = true;
                          setHistoryRequest({
                            ...historyRequest,
                            page: 1,
                          });

                          setApprovalState({
                            ...approvalState,
                            selectedStatus: value,
                            showStatusPopup: false,
                          });
                        }}
                        defaultValue={'All'}
                        closePopup={() => {
                          setApprovalState({
                            ...approvalState,
                            showStatusPopup: false,
                          });
                        }}
                        analyticsData={{
                          data: filterData(
                            baseHistory.requests,
                            'request_status'
                          ),
                        }}
                      /> */}
                    </div>
                    <div className="tab_container_right notification_std">
                      <p>
                        {notificationPage.page === 0
                          ? notificationTotal > 0
                            ? 1
                            : 0
                          : notificationPage.page * 10 + 1}{' '}
                        -{' '}
                        {notificationTotal > (notificationPage.page + 1) * 10
                          ? (notificationPage.page + 1) * 10
                          : notificationTotal}{' '}
                        of {notificationTotal}
                        <span
                          className={
                            notificationPage.page !== 0
                              ? 'prev_icon'
                              : 'prev_icon editor_btn_disabled'
                          }
                          onClick={() => {
                            setNotificationPage({
                              ...notificationPage,
                              page: notificationPage.page - 1,
                            });
                          }}
                        >
                          <DropdownWithCircle />
                        </span>
                        <span
                          className={
                            notificationTotal > notificationPage.page * 10
                              ? 'next_icon'
                              : 'next_icon editor_btn_disabled'
                          }
                          onClick={() =>
                            setNotificationPage({
                              ...notificationPage,
                              page: notificationPage.page + 1,
                            })
                          }
                        >
                          <DropdownWithCircle />
                        </span>{' '}
                      </p>
                    </div>
                  </DropdownContainer>
                  <div className={loader ? "automation_logs notification_loader" : "automation_logs" }>
                    {loader ? (
                      <Loader.PopupLoader show={true}></Loader.PopupLoader>
                    ) : (
                      <TabelWrapper>
                        <TableContainer>
                          {notificationData.length > 0 ? (
                            <Table className="notification_std_table">
                              <Thead>
                                <Tr>
                                  <Th size={3}>Notification title</Th>
                                  <Th size={1.5}>Sent to users</Th>
                                  <Th size={1.5}>Channel</Th>
                                  <Th size={2}>View Sent to</Th>
                                  <Th size={2}>Status</Th>
                                  <Th
                                    style={{ justifyContent: 'flex-end' }}
                                    size={2}
                                  >
                                    View Message
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody className="automate_logs_history">
                                {notificationData.map((h, i) => (
                                  <Tr
                                    className="automate_logs_history_rows"
                                    key={i}
                                  >
                                    {console.log(h.created_at, '')}
                                    <Td size={3}>
                                      <div className="td_header">
                                        {capitalizeFirstLetter(h.notification_title)}
                                      </div>
                                      <div className="td_date">
                                        {formatDateInCard(h.created_at)} |{' '}
                                        {formatAMPM(h.created_at)}
                                      </div>
                                    </Td>
                                    <Td size={1.5}>{h.totaluser}</Td>
                                    <Td size={1.5}>
                                      <div className="td_cc_img">
                                        <SlackIconCC />
                                      </div>
                                    </Td>
                                    <Td
                                      size={2}
                                      onMouseEnter={() => {
                                        setRequestedDetails(i);
                                      }}
                                      onMouseLeave={(e: any) => {
                                        e.preventDefault();
                                        setRequestedDetails(null);
                                      }}
                                    >
                                      <SmallButton
                                        primary
                                        className={
                                          popupState.showPopup ||
                                          showConversationPopup.show
                                            ? 'editor_btn_disabled'
                                            : ''
                                        }
                                        onClick={() =>
                                          setHistory({
                                            open: true,
                                            id: h.id,
                                            title: h.notification_title,
                                          })
                                        }
                                        disabled={
                                          popupState.showPopup ||
                                          showConversationPopup.show
                                        }
                                      >
                                        <label>View</label>
                                      </SmallButton>
                                    </Td>
                                    <Td
                                      size={2}
                                      onMouseEnter={() => {
                                        setFeedbackDetails(i);
                                      }}
                                      onMouseLeave={(e: any) => {
                                        e.preventDefault();
                                        setFeedbackDetails(null);
                                      }}
                                      style={{ gap: 6 }}
                                    >
                                      {h.failed === 0 && h.inprogress === 0 ? (
                                        <SmallButton
                                          primary
                                          className={
                                            popupState.showPopup ||
                                            showConversationPopup.show
                                              ? 'editor_btn_disabled'
                                              : ''
                                          }
                                          style={{
                                            color: '#0BB251',
                                            border: '1px solid #0EB25136',
                                          }}
                                        >
                                          <label style={{top: 1}}>Success</label>
                                        </SmallButton>
                                      ) : h.success === 0 &&
                                        h.inprogress === 0 ? (
                                        <SmallButton
                                          primary
                                          className={
                                            popupState.showPopup ||
                                            showConversationPopup.show
                                              ? 'editor_btn_disabled'
                                              : ''
                                          }
                                          style={{
                                            color: '#FF0D0D',
                                            border: '1px solid #FF0C0E33',
                                          }}
                                          //   disabled={
                                          //     popupState.showPopup ||
                                          //     showConversationPopup.show
                                          //   }
                                        >
                                          <label>Failed</label>
                                        </SmallButton>
                                      ) : (
                                        <>
                                          <Tooltip
                                            className="target customTip "
                                            zIndex={10000}
                                            arrowSize={8}
                                            tagName="span"
                                            content={'Success'}
                                            distance={5}
                                          >
                                            <SmallButton
                                              primary
                                              className={
                                                popupState.showPopup ||
                                                showConversationPopup.show
                                                  ? 'editor_btn_disabled'
                                                  : ''
                                              }
                                              style={{
                                                border: '1px solid #0EB25136',
                                              }}
                                              //   disabled={
                                              //     popupState.showPopup ||
                                              //     showConversationPopup.show
                                              //   }
                                            >
                                              <label>
                                                {' '}
                                                <span
                                                  style={{ color: '#0BB251' }}
                                                >
                                                  {'S'}
                                                </span>
                                                {h.success}{' '}
                                              </label>
                                            </SmallButton>
                                          </Tooltip>
                                          <Tooltip
                                            className="target customTip "
                                            zIndex={10000}
                                            arrowSize={8}
                                            tagName="span"
                                            content={'In progress'}
                                            distance={5}
                                          >
                                            <SmallButton
                                              primary
                                              className={
                                                popupState.showPopup ||
                                                showConversationPopup.show
                                                  ? 'editor_btn_disabled'
                                                  : ''
                                              }
                                              style={{
                                                border: '1px solid #E9790B33',
                                              }}
                                              //   disabled={
                                              //     popupState.showPopup ||
                                              //     showConversationPopup.show
                                              //   }
                                            >
                                              <label>
                                                {' '}
                                                <span
                                                  style={{ color: '#E9790B' }}
                                                >
                                                  {'IP'}
                                                </span>
                                                {h.inprogress}{' '}
                                              </label>
                                            </SmallButton>
                                          </Tooltip>
                                          <Tooltip
                                            className="target customTip "
                                            zIndex={10000}
                                            arrowSize={8}
                                            tagName="span"
                                            content={'Failed'}
                                            distance={5}
                                          >
                                            <SmallButton
                                              primary
                                              className={
                                                popupState.showPopup ||
                                                showConversationPopup.show
                                                  ? 'editor_btn_disabled'
                                                  : ''
                                              }
                                              style={{
                                                border: '1px solid #FF0C0E33',
                                              }}
                                              disabled={
                                                popupState.showPopup ||
                                                showConversationPopup.show
                                              }
                                            >
                                              <label>
                                                {' '}
                                                <span
                                                  style={{ color: '#FF0D0D' }}
                                                >
                                                  {'F'}
                                                </span>
                                                {h.failed}{' '}
                                              </label>
                                            </SmallButton>
                                          </Tooltip>
                                        </>
                                      )}
                                    </Td>
                                    <Td
                                      size={2}
                                      onClick={() => {
                                        setMessagePopup({
                                          popup: true,
                                          data: h.message,
                                        });
                                      }}
                                      color={
                                        h.request_status ==
                                        'Waiting for approval'
                                          ? '#E8790A'
                                          : h.request_status == 'Approved'
                                          ? '#0BB251'
                                          : '#DD4330'
                                      }
                                      style={{
                                        justifyContent: 'flex-end',
                                        paddingRight: 20,
                                      }}
                                    >
                                      <SmallButton primary>
                                        <label>View</label>
                                      </SmallButton>
                                    </Td>
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          ) : (
                            <div>
                              <p className="autoamtion_analytics_nologs">
                                No logs available
                              </p>
                            </div>
                          )}
                        </TableContainer>
                      </TabelWrapper>
                    )}
                    {messagePopup.popup && (
                      <MessageMDPopup
                        data={messagePopup.data}
                        closePopup={closePopup}
                      />
                    )}
                  </div>
                </>
              )}
              {history.open && (
                <div className="log_notification_section">
                  <div className="log_notification">
                    <div className="automation_logs">
                      <div className="notification_log_header flex">
                        <div className="notification_log_header_left">
                          <h5>Notification</h5>
                          <h6>{capitalizeFirstLetter(history.title)}</h6>
                        </div>
                        <div className="notification_log_header_right_side flex">
                          <div className="notification_log_header_center loader">
                            {statsLoader ? (
                              <div className="publish_button_dialog btn_loader">
                                <img src={ImgLoader} />
                              </div>
                            ) : (
                              notificationStats && (
                                <div className="flex">
                                  <span
                                    onClick={() => {
                                      notificationStatsApi();
                                      notificationLogApi();
                                    }}
                                    className="notification_refresh_btn"
                                  >
                                    <Tooltip
                                      className="target customTip "
                                      zIndex={10000}
                                      arrowSize={8}
                                      tagName="span"
                                      content={'Refresh'}
                                      distance={14}
                                    >
                                      <RefreshIcon />
                                    </Tooltip>
                                  </span>
                                  <div>
                                    <span>Total</span>
                                    <span>{notificationStats.totaluser}</span>
                                  </div>
                                  <div>
                                    <span style={{color: '#0BB251'}}>Success</span>
                                    <span>{notificationStats.success}</span>
                                  </div>
                                  <div>
                                    <span style={{color: '#E8790A'}}>In progress</span>
                                    <span>{notificationStats.inprogress}</span>
                                  </div>
                                  <div>
                                    <span style={{color: '#FF0D0D'}}>Failed</span>
                                    <span>{notificationStats.failed}</span>
                                  </div>
                                </div>
                                //
                              )
                            )}
                          </div>
                          <div className="notification_log_header_right flex">
                            <SerachInputWrapper>
                              <SerachInput
                                type="search"
                                value={emailSearch}
                                placeholder={`Search user`}
                                onChange={(e: any) =>
                                  setEmailSearch(e.target.value)
                                }
                                className="notifictaion_search_box"
                              />
                              <span className="search_icon">
                                <SearchIcon />
                              </span>
                            </SerachInputWrapper>
                            <Dropdwon
                              header={'Filter by'}
                              content="All"
                              showState={{ showPopup: statusFilterPopup }}
                              onClick={() =>
                                setStatusFilterPopup(!statusFilterPopup)
                              }
                              value={filterValue}
                              selectedvalue={(value: string) => {
                                setFilterValue(value);
                                setStatusFilterPopup(false);
                              }}
                              defaultValue={'All'}
                              closePopup={() => {
                                setStatusFilterPopup(false);
                              }}
                              analyticsData={{
                                data: filterList,
                              }}
                            />
                          </div>
                        </div>
                        <span
                          className="notification_log_close"
                          onClick={() => {
                            setHistory({
                              open: false,
                              id: 0,
                              title: '',
                            });
                            setEmailSearch('');
                            setFilterValue('All');
                          }}
                        >
                          <CloseIconWithCircle />
                        </span>
                      </div>
                      <TabelWrapper>
                        <TableContainer>
                          {logLoader ? (
                            <div className="notification_inner_table loader">
                              {/* <div className="log_notification"> */}
                              <Loader.PopupLoader show={true} />
                              {/* </div> */}
                            </div>
                          ) : notificationLog.length > 0 ? (
                            <>
                              <Table className="notification_inner_table">
                                <Thead>
                                  <Tr>
                                    <Th size={3}>User Email</Th>
                                    <Th size={3}>Delivered Time</Th>
                                    <Th size={1.5}>Status</Th>
                                    <Th size={3.5} className="last_heading">
                                      Reason
                                    </Th>
                                  </Tr>
                                </Thead>
                                <Tbody className="automate_logs_history">
                                  {notificationLog.map((h, i) => (
                                    <Tr
                                      className="automate_logs_history_rows"
                                      key={i}
                                    >
                                      {console.log(h.created_at, '')}
                                      <Td size={3}>
                                        <div className="">{h.email_id}</div>
                                      </Td>
                                      <Td size={3}>
                                        <div className="td_date">
                                          {formatDateInCard(h.updated_at)} |{' '}
                                          {formatAMPM(h.updated_at)}
                                        </div>
                                      </Td>
                                      <Td
                                        size={1.5}
                                        onMouseEnter={() => {
                                          setRequestedDetails(i);
                                        }}
                                        onMouseLeave={(e: any) => {
                                          e.preventDefault();
                                          setRequestedDetails(null);
                                        }}
                                      >
                                        <SmallButton
                                          primary
                                          className={
                                            popupState.showPopup ||
                                            showConversationPopup.show
                                              ? 'editor_btn_disabled'
                                              : ''
                                          }
                                          style={{
                                            border:
                                              h.status === 'DELIVERED'
                                                ? '1px solid #0EB25136'
                                                : h.status === 'QUEUED' ||
                                                  h.status ===
                                                    'BUST_WITH_AGENT' ||
                                                  h.status === 'BUSY_WITH_BOT'
                                                ? '1px solid #E8790A36'
                                                : '1px solid #FF0C0E33',
                                          }}
                                        >
                                          <label
                                            style={{
                                              color:
                                                h.status === 'DELIVERED'
                                                  ? '#0BB251'
                                                  : h.status === 'QUEUED' ||
                                                    h.status ===
                                                      'BUST_WITH_AGENT' ||
                                                    h.status === 'BUSY_WITH_BOT'
                                                  ? '#E8790A'
                                                  : '#FF0D0D',
                                                  top: 1, 
                                            }}
                                          >
                                            {logStatusName(h.status)}
                                          </label>
                                        </SmallButton>
                                      </Td>
                                      <Td
                                        size={3.5}
                                        style={{ justifyContent: 'flex-end' }}
                                      >
                                        <div className="td_header" style={{marginBottom: 0}}>
                                          {h.status === 'DELIVERED'
                                            ? 'Delivered'
                                            : h.status === 'QUEUED'
                                            ? 'Waiting to send'
                                            : h.status === 'BUST_WITH_AGENT'
                                            ? 'User is chatting with the agent'
                                            : h.status === 'BUSY_WITH_BOT'
                                            ? 'User is chatting with the bot'
                                            : h.status_message}
                                        </div>
                                      </Td>
                                    </Tr>
                                  ))}
                                </Tbody>
                              </Table>
                            </>
                          ) : (
                            <div>
                              <p className="autoamtion_analytics_nologs">
                                No logs available
                              </p>
                            </div>
                          )}
                        </TableContainer>
                      </TabelWrapper>
                    </div>
                    { notificationLogTotal  > 10 &&
                    <div className="notification_pagination_box flex">
                      <PaginatedItems
                        itemsPerPage={10}
                        items={notificationLogTotal}
                        pageChange={setNotificationLogPagenumber}
                        forcePage={forcePage}
                        setForcepage={setForcePage}
                      />
                      <div className='notification_pagination_search flex'>
                        <InputContainer style={{ marginBottom: 0, width: '30%' }}>
                          <Input
                            value={pageNum === 0 ? '' : pageNum}
                            onChange={(e: {
                              target: { value: React.SetStateAction<number> };
                            }) => setPageNum(e.target.value)}
                            style={{height: '21px', padding: "12px"}}
                          />
                        </InputContainer>
                        <button
                          onClick={() => {
                            setNotificationLogPagenumber(pageNum);
                            setForcePage(pageNum);
                          }}
                          disabled={pageNum === 0}
                        >
                          Go
                        </button>
                      </div>
                    </div>
}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </React.Fragment>
  );

  function renderConversationLogsPopup() {
    return (
      showConversationPopup.show && (
        <TryIt
          message={tryItLog}
          close={() =>
            setShowConversationPopup({ ...showConversationPopup, show: false })
          }
          loading={showConversationPopup.loading}
        />
      )
    );
  }

  function renderAutomationLogsPopup() {
    const logs = popupState.logs as (
      | NormalViewActionNodeLogT
      | EndNodeLogT
      | WebHookNodeLogT
      | WebHookTriggerNodeLogT
      | PollingNodeLogT
      | ConditionNodeLogT
      | ForEachNodeLogT
      | EventTriggerNodeLogT
      | ApprovalNodeLogT
      | TimerNodeLogT
      | NotificationNodeLogT
    )[];

    const setOpen = (i: number) => {
      const state: any = {};
      logs.map((data, j) => {
        state[`toggleOpen${j}`] = j === i ? true : false;
      });

      setToggleState(state);
    };

    const setClose = (i: number) => {
      const state: any = {};
      logs.map((data, j) => {
        state[`toggleOpen${j}`] = false;
      });
      setToggleState(state);
    };

    const executionFailCount = logs
      ? R.flatten([
          ...logs
            .map((log) => {
              if (log.node_type == NodeTypeT.loop) {
                return log.jobs.map((job: any) =>
                  job.actions
                    .map((act: any) => act.node_execution_status == 'failed')
                    .filter((b: boolean) => b)
                );
              } else if (log.node_execution_status == 'failed') {
                return log;
              }
              return null;
            })
            .filter(Boolean),
        ])
      : [];

    if (popupState.showPopup) {
      if (popupState.loading) {
        return (
          <div className="right-panel analytics_popup">
            <div className="popuploader_logs">
              <Loader.PopupLoader show={true} />
            </div>
          </div>
        );
      } else if (popupState.error) {
        return (
          <div className="right-panel analytics_popup automation_logs_output_popup">
            <div className="flow_canvas_popup_error">
              <Error.AddorEdit
                info={popupState.error}
                onClick={() => {
                  setPopupState({ ...popupState, showPopup: false });
                }}
              />
            </div>
          </div>
        );
      }
      return (
        <>
          <Wrapper>
            {getProductId() === 'ASSISTANT' ? (
              <div className="right-panel-logs">
                <div className="">
                  <div className="analytics_popup_header">
                    <h4>
                      Log name:{' '}
                      <span>{popupState.executionData.automation_name}</span>
                    </h4>
                    <span
                      onClick={() =>
                        setPopupState({
                          ...popupState,
                          showPopup: false,
                        })
                      }
                    >
                      <TrymeCloseIcon />
                    </span>
                  </div>
                  <div className="analytics_popup_count">
                    <div className="analytics_popup_count_width">
                      <span>Duration</span>
                      <h6>{popupState.executionData.executionTime}</h6>
                    </div>
                    <div className="analytics_popup_count_width">
                      <span>Status</span>
                      <h6
                        style={{
                          color:
                            popupState.executionData.status.toLowerCase() ===
                            'success'
                              ? '#2DB677'
                              : '#DD4330',
                        }}
                      >
                        {popupState.executionData.status
                          .charAt(0)
                          .toUpperCase() +
                          popupState.executionData.status.slice(1)}
                      </h6>
                    </div>
                    <div className="analytics_popup_count_width">
                      <span>Error</span>
                      <h6
                        style={{
                          color:
                            executionFailCount.length == 0
                              ? '#494949'
                              : '#DD4330',
                        }}
                      >
                        {executionFailCount.length == 0
                          ? 'No Error'
                          : executionFailCount.length +
                            (executionFailCount.length > 1
                              ? ' Errors'
                              : ' Error')}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="analytics_popup automation_logs_output_popup">
                  {logs &&
                    logs.length > 0 &&
                    logs.map((log, i) => {
                      const name =
                        log.node_type === NodeTypeT.web_hook
                          ? { name: 'Workflow inputs', value: ' No Trigger' }
                          : log.node_type === NodeTypeT.polling ||
                            log.node_type === NodeTypeT.event_trigger
                          ? {
                              name:
                                'Start workflow if - Trigger - ' +
                                log.node_meta.action.name,
                              value: log.node_meta.action.name,
                            }
                          : log.node_type === NodeTypeT.action
                          ? {
                              name: log.node_meta.action.name,
                              icon:
                                log.node_meta.action.auth_type == 'none'
                                  ? FunctionNodeIcon
                                  : log.node_meta.action.icon,
                              value: log.node_meta.action.name,
                            }
                          : log.node_type === NodeTypeT.condition
                          ? {
                              name: log.node_meta.name,
                              value: log.node_meta.name,
                              icon: IfNodeIcon,
                            }
                          : log.node_type === NodeTypeT.loop
                          ? {
                              name: 'For Each - ' + log.node_meta.loop.name,
                              value: 'ForEach',
                            }
                          : log.node_type === NodeTypeT.approval
                          ? {
                              name: 'Approval',
                              value: 'Approval',
                              icon: ApprovalIcon,
                            }
                          : log.node_type === NodeTypeT.timer
                          ? {
                              name: 'Timer',
                              value: 'Timer',
                              icon: TimerNodeIcon,
                            }
                          : log.node_type === NodeTypeT.notification
                          ? {
                              name: 'Notification',
                              value: 'Notification',
                              icon: NotificationNodeIcon,
                            }
                          : {
                              name: 'Send Execution Report',
                              value: 'End',
                              icon: SendExecutionReportIcon,
                            };

                      if (
                        !toggleState['toggleOpen' + i] &&
                        log.node_type != NodeTypeT.loop
                      ) {
                        return (
                          <div className="analytics_popup_body" key={i}>
                            <div
                              className={
                                log.node_execution_status === 'skipped' ||
                                (log.node_type !== NodeTypeT.condition &&
                                  log.node_execution_status === 'success') ||
                                (log.node_execution_status === 'success' &&
                                  log.output_schema[log.node_id])
                                  ? 'analytics_popup_body_plus notifi_title'
                                  : 'analytics_popup_body_plus automation_failed_node'
                              }
                              onClick={() => setOpen(i)}
                            >
                              <h4>
                                {name.icon && (
                                  <img
                                    style={{
                                      width: 32,
                                      height: 32,
                                      marginRight: 8,
                                    }}
                                    src={name.icon}
                                  />
                                )}
                                <div>{name.name}</div>
                              </h4>
                              {log.node_execution_status == 'skipped' && (
                                <p className="skipped_text">(Skipped)</p>
                              )}
                              <span>
                                <AddIcon />
                              </span>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <OutputUI
                            popupState={popupState}
                            log={log}
                            key={i}
                            setClose={() => setClose(i)}
                            name={name}
                            workspaceName={workspaceName}
                          />
                        );
                      }
                    })}
                </div>
              </div>
            ) : (
              <div className="right-panel-logs">
                <div className="">
                  <div className="analytics_popup_header">
                    <h4>
                      Log name:{' '}
                      <span>{popupState.executionData.automation_name}</span>
                    </h4>
                    {/* <span
                                    onClick={() =>
                                        setPopupState({
                                            ...popupState,
                                            showPopup: false
                                        })
                                    }
                                >
                                    <CloseIcon />
                                </span> */}
                  </div>
                  <div className="analytics_popup_count">
                    <div className="analytics_popup_count_width">
                      <span>Duration</span>
                      <h6>{popupState.executionData.executionTime}</h6>
                    </div>
                    <div className="analytics_popup_count_width">
                      <span>Status</span>
                      <h6
                        style={{
                          color:
                            popupState.executionData.status.toLowerCase() ===
                            'success'
                              ? '#2DB677'
                              : popupState.executionData.status.toLowerCase() ===
                                'in progress'
                              ? '#355d8b'
                              : '#DD4330',
                        }}
                      >
                        {popupState.executionData.status
                          .charAt(0)
                          .toUpperCase() +
                          popupState.executionData.status.slice(1)}
                      </h6>
                    </div>
                    <div className="analytics_popup_count_width">
                      <span>Error</span>
                      <h6
                        style={{
                          color:
                            executionFailCount.length == 0
                              ? '#494949'
                              : '#DD4330',
                        }}
                      >
                        {executionFailCount.length == 0
                          ? 'No Error'
                          : executionFailCount.length +
                            (executionFailCount.length > 1
                              ? ' Errors'
                              : ' Error')}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="analytics_popup automation_logs_output_popup">
                  {logs &&
                    logs.length > 0 &&
                    logs.map((log, i) => {
                      const name =
                        log.node_type === NodeTypeT.web_hook ||
                        log.node_type === NodeTypeT.webhook_trigger
                          ? {
                              name: 'Workflow inputs',
                              value:
                                log.node_type === NodeTypeT.web_hook
                                  ? ' No Trigger'
                                  : ' Webhook',
                              icon:
                                log.node_type === NodeTypeT.web_hook
                                  ? NoTriggerIcon
                                  : WebhookIcon,
                            }
                          : log.node_type === NodeTypeT.polling ||
                            log.node_type === NodeTypeT.event_trigger
                          ? {
                              name:
                                'Start workflow if - Trigger - ' +
                                log.node_meta.action.name,
                              value: log.node_meta.action.name,
                              icon: log.node_meta.action.icon,
                            }
                          : log.node_type === NodeTypeT.action
                          ? {
                              name: log.node_meta.action.name,
                              icon:
                                log.node_meta.action.auth_type == 'none'
                                  ? FunctionNodeIcon
                                  : log.node_meta.action.icon,
                              value: log.node_meta.action.name,
                            }
                          : log.node_type === NodeTypeT.condition
                          ? {
                              name: log.node_meta.name,
                              value: log.node_meta.name,
                              icon: IfNodeIcon,
                            }
                          : log.node_type === NodeTypeT.loop
                          ? {
                              name: 'For Each - ' + log.node_meta.loop.name,
                              value: 'ForEach',
                            }
                          : log.node_type === NodeTypeT.approval
                          ? {
                              name: 'Approval',
                              value: 'Approval',
                              icon: ApprovalIcon,
                            }
                          : log.node_type === NodeTypeT.timer
                          ? {
                              name: 'Timer',
                              value: 'Timer',
                              icon: TimerNodeIcon,
                            }
                          : log.node_type === NodeTypeT.notification
                          ? {
                              name: 'Notification',
                              value: 'Notification',
                              icon: NotificationNodeIcon,
                            }
                          : {
                              name: 'Send Execution Report',
                              value: 'End',
                              icon: SendExecutionReportIcon,
                            };

                      if (
                        !toggleState['toggleOpen' + i] &&
                        log.node_type != NodeTypeT.loop
                      ) {
                        return (
                          <div className="analytics_popup_body" key={i}>
                            <div
                              className={
                                log.node_execution_status === 'skipped' ||
                                log.node_execution_status === 'in_progress' ||
                                (log.node_type !== NodeTypeT.condition &&
                                  log.node_execution_status === 'success') ||
                                (log.node_execution_status === 'success' &&
                                  log.output_schema[log.node_id])
                                  ? 'analytics_popup_body_plus notifi_title'
                                  : 'analytics_popup_body_plus automation_failed_node'
                              }
                              onClick={() => setOpen(i)}
                            >
                              <div className="analytics_popup_body_minus_header_container">
                                <div className="analytics_popup_body_minus_header_img">
                                  {name.icon && (
                                    <img
                                      style={{
                                        width: 32,
                                        height: 32,
                                        marginRight: 5,
                                      }}
                                      src={name.icon}
                                    />
                                  )}
                                </div>
                                <div className="analytics_popup_body_minus_header_content">
                                  <h4>{name.name}</h4>
                                </div>
                              </div>

                              {log.node_execution_status == 'skipped' && (
                                <p className="skipped_text">(Skipped)</p>
                              )}
                              <span>
                                <AddIcon />
                              </span>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <OutputUI
                            popupState={popupState}
                            log={log}
                            key={i}
                            setClose={() => setClose(i)}
                            name={name}
                            workspaceName={workspaceName}
                          />
                        );
                      }
                    })}
                </div>
                <ButtonContainer className="footer_button_fixed">
                  <div className="popup_footer_button_container">
                    <Button
                      onClick={() =>
                        setPopupState({
                          ...popupState,
                          showPopup: false,
                        })
                      }
                      className="automate_logs_poupup_btn"
                    >
                      <label>Close</label>
                    </Button>
                  </div>
                </ButtonContainer>
              </div>
            )}
          </Wrapper>
        </>
      );
    }
    return null;
  }
}

function OutputUI(props: {
  log:
    | NormalViewActionNodeLogT
    | EndNodeLogT
    | WebHookNodeLogT
    | WebHookTriggerNodeLogT
    | ConditionNodeLogT
    | PollingNodeLogT
    | ForEachNodeLogT
    | EventTriggerNodeLogT
    | ApprovalNodeLogT
    | TimerNodeLogT
    | NotificationNodeLogT;
  name: { name: string; value: string; icon?: string };
  setClose: () => void;
  popupState: PopupStateT;
  workspaceName: string;
}) {
  const { log, name, setClose, popupState, workspaceName } = props;
  const [statsLoader, setStatsLoader] = useState<boolean>(false);
  const [notificationStats, setNotificationStats] = useState<any>();
  const [inputOpen, setInputOpen] = useState<boolean>(true);
  const [statusOpen, setStatusOpen] = useState<boolean>(false);
  const [bots, setBots] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);
  useEffect(() => {
    if (log.node_type === 'notification') {
      getAssistants(workspaceName).then((res) => {
        const botsList = res.map((x) => ({ id: x.id, name: x.name }));
        setBots(botsList);
      });
      notificationStatsApi();
    }
  }, []);
  console.log(statusOpen, 'logdfgsfgdf');
  const getMessageType = (text: string) => {
    switch (text) {
      case 'adaptive_card':
        return 'Adaptive card';
      case 'option':
        return 'Options';
      case 'message':
        return 'Message';
      default:
        break;
    }
  };
  const getBotName = (id: string) => {
    return bots.filter((bot) => bot.id === id)[0]?.name;
  };
  const notificationStatsApi = () => {
    setStatsLoader(true);
    postJson(
      !localApi
        ? slackIntegrationUrl(
            `/slack/${workspaceName}/notification/${log.output_schema.id}/stats`
          )
        : `http://dev-assistant.workativ.ai/slack-api-v2/slack/${workspaceName}/notification/${log.output_schema.id}/stats`
    )({}).then((res) => {
      setNotificationStats(res.data);
      setStatsLoader(false);
      console.log(res, 'response');
    });
  };
  const [toggleState, setToggleState] = useState({ toggleOpen0: false });

  if (log.node_type == NodeTypeT.loop) {
    const jobs = log.jobs as NormalJobDataT[];

    const setForEachChildOpen = (i: number, job: number) => {
      const state: any = {};
      const filteredJob = jobs.filter((j) => j.node_meta.job == job)[0];
      filteredJob &&
        filteredJob.actions.forEach((data: any, j: number) => {
          state[`toggleOpen${j}`] = j === i ? true : false;
        });

      setToggleState(state);
    };

    const setForEachChildClose = (i: number, job: number) => {
      const state: any = {};
      const filteredJob = jobs.filter((j) => j.node_meta.job == job)[0];
      filteredJob &&
        filteredJob.actions.forEach((data: any, j: number) => {
          state[`toggleOpen${j}`] = false;
        });

      setToggleState(state);
    };

    return (
      <>
        <div className="analytics_popup_foreach">
          <div className="analytics_popup_foreach_wrapper">
            <div
              className={'analytics_popup_body_minus_header for_each_header'}
            >
              <h4>{name.name}</h4>
            </div>

            {jobs.map((job) => {
              return job.actions.map((action, j) => {
                const name =
                  action.node_type === NodeTypeT.web_hook ||
                  action.node_type === NodeTypeT.webhook_trigger
                    ? {
                        name: 'Workflow inputs',
                        value:
                          action.node_type === NodeTypeT.web_hook
                            ? ' No Trigger'
                            : ' Webhook',
                        icon:
                          action.node_type === NodeTypeT.web_hook
                            ? NoTriggerIcon
                            : WebhookIcon,
                      }
                    : action.node_type === NodeTypeT.polling
                    ? {
                        name:
                          'Workflow inputs - ' + action.node_meta.action.name,
                        value: action.node_meta.action.name,
                      }
                    : action.node_type === NodeTypeT.action
                    ? {
                        name: 'Then - ' + action.node_meta.action.name,
                        icon:
                          action.node_meta.action.auth_type == 'none'
                            ? FunctionNodeIcon
                            : action.node_meta.action.icon,
                        value: action.node_meta.action.name,
                      }
                    : action.node_type === NodeTypeT.condition
                    ? {
                        name: 'Then - ' + action.node_meta.name,
                        value: action.node_meta.name,
                        icon: IfNodeIcon,
                      }
                    : action.node_type === NodeTypeT.approval
                    ? {
                        name: 'Then - Approval',
                        value: 'Approval',
                        icon: ApprovalIcon,
                      }
                    : action.node_type === NodeTypeT.timer
                    ? {
                        name: 'Then - Timer',
                        value: 'Timer',
                        icon: TimerNodeIcon,
                      }
                    : action.node_type === NodeTypeT.notification
                    ? {
                        name: 'Then - Notification',
                        value: 'Notification',
                        icon: NotificationNodeIcon,
                      }
                    : {
                        name: 'Send Execution Report',
                        value: 'End',
                        icon: SendExecutionReportIcon,
                      };

                if (!toggleState['toggleOpen' + j]) {
                  return (
                    <div
                      className={
                        action.node_execution_status === 'skipped' ||
                        action.node_execution_status === 'in_progress' ||
                        (action.node_type !== NodeTypeT.condition &&
                          action.node_execution_status === 'success') ||
                        (action.node_execution_status === 'success' &&
                          action.output_schema[action.node_id])
                          ? 'analytics_popup_body_plus notifi_title'
                          : 'analytics_popup_body_plus automation_failed_node'
                      }
                      onClick={() => setForEachChildOpen(j, job.node_meta.job)}
                      key={j}
                    >
                      <h4>
                        {name.icon && (
                          <img
                            style={{ width: 32, height: 32, marginRight: 8 }}
                            src={name.icon}
                          />
                        )}
                        {name.name}
                      </h4>

                      {action.node_execution_status == 'skipped' && (
                        <p className="skipped_text">(Skipped)</p>
                      )}
                      <span>
                        <AddIcon />
                      </span>
                    </div>
                  );
                } else {
                  return (
                    <OutputUI
                      popupState={popupState}
                      log={action}
                      key={j}
                      setClose={() =>
                        setForEachChildClose(j, job.node_meta.job)
                      }
                      name={name}
                      workspaceName={workspaceName}
                    />
                  );
                }
              });
            })}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="analytics_popup_body">
        <div className="analytics_popup_body_plus">
          <h4>{name.name}</h4>
          <AddIcon />
        </div>
        <div
          className={
            log.node_execution_status === 'skipped' ||
            log.node_execution_status === 'in_progress' ||
            (log.node_type !== NodeTypeT.condition &&
              log.node_execution_status === 'success') ||
            (log.node_execution_status === 'success' &&
              log.output_schema[log.node_id])
              ? 'analytics_popup_body_minus'
              : 'analytics_popup_body_minus automation_failed_node'
          }
        >
          <div className="analytics_popup_body_minus_header" onClick={setClose}>
            <div className="analytics_popup_body_minus_header_container">
              <div className="analytics_popup_body_minus_header_img">
                <img src="" alt="" />
              </div>
              <div className="analytics_popup_body_minus_header_content">
                <h4>
                  {name.icon && (
                    <img
                      src={name.icon}
                      style={{
                        width: '32px',
                        height: '32px',
                        marginRight: '8px',
                      }}
                    />
                  )}
                  <div>{name.name}</div>
                </h4>
              </div>
            </div>

            {log.node_execution_status == 'skipped' && (
              <p className="skipped_text">(Skipped)</p>
            )}
            <span>
              <MinusIcon />
            </span>
          </div>
          <div className="analytics_popup_body_minus_body">
            {log.node_type !== NodeTypeT.end &&
            log.node_type !== NodeTypeT.notification ? (
              log.node_type === NodeTypeT.action ||
              log.node_type === NodeTypeT.polling ||
              log.node_type === NodeTypeT.event_trigger ||
              log.node_type === NodeTypeT.web_hook ||
              log.node_type === NodeTypeT.webhook_trigger ||
              log.node_type === NodeTypeT.approval ||
              log.node_type === NodeTypeT.timer ? (
                log.node_execution_status === 'skipped' ? (
                  <div className="automation_logs_collapse_open_content">
                    <div
                      className={'automation_logs_collapse_open_content_scroll'}
                    >
                      <h5 style={{ paddingTop: 0 }}>Action not executed!</h5>
                      <p>
                        Previous action/condition is failed. So, the action node
                        is not executed.
                      </p>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    {log.node_type === NodeTypeT.action &&
                      log.node_meta.action.auth_type != AuthType.NoAuth && (
                        <p>
                          Authenticated with{' '}
                          <span>{log.node_meta.authentication.name}</span>
                        </p>
                      )}
                    {(log.node_type == NodeTypeT.approval ||
                      log.node_type == NodeTypeT.timer ||
                      !log.node_meta ||
                      (log.node_meta &&
                        log.node_meta.action &&
                        log.node_meta.action.auth_type != AuthType.NoAuth &&
                        log.node_type !== NodeTypeT.event_trigger)) && (
                      <div className="analytics_popup_body_minus_footer analytics_popup_body_minus_body">
                        <h4>
                          Input Provided:{' '}
                          <span className="next_icon_svg">
                            {/* <NextDetailICon /> */}
                          </span>
                        </h4>
                        {log.input_schema &&
                        (log.node_type === NodeTypeT.action ||
                          log.node_type == NodeTypeT.approval ||
                          log.node_type === NodeTypeT.polling) &&
                        Array.isArray(log.input_schema) &&
                        log.input_schema.length > 0 ? (
                          log.input_schema.map((schema, i) => {
                            if (schema.config.kind === 'dictionary') {
                              return (
                                <React.Fragment>
                                  <h6 key={i}>
                                    {/* {i + 1}. {schema.displayTitle}:{' '} */}
                                    {schema.displayTitle}:{' '}
                                    {Object.entries(
                                      schema.value as any as Object
                                    ).map(([key, value], index) => {
                                      return (
                                        <h6
                                          key={i}
                                          style={{ paddingTop: '5px' }}
                                        >
                                          {/* {index + 1}. {key}:{' '} */}
                                          {key}: <span>{value}</span>
                                        </h6>
                                      );
                                    })}
                                  </h6>
                                </React.Fragment>
                              );
                            }
                            return (
                              <h6 key={i}>
                                {/* {i + 1}. {schema.displayTitle}:{' '} */}
                                {schema.displayTitle}:{' '}
                                <span>{schema.value}</span>
                              </h6>
                            );
                          })
                        ) : (Array.isArray(log.input_schema) &&
                            log.input_schema.length == 0) ||
                          Object.entries(log.input_schema).length === 0 ? (
                          <h6>No input found</h6>
                        ) : log.node_type == NodeTypeT.timer ? (
                          log.input_schema['by'] ? (
                            <h6>
                              <span>Wait for:</span> {log.input_schema['by']}{' '}
                              {log.input_schema['period']}
                            </h6>
                          ) : log.input_schema['date'] ? (
                            <>
                              <h6>
                                <span>Condition:</span> Date & Time
                              </h6>
                              <h6>
                                <span>Current day of month is:</span>{' '}
                                {log.input_schema['date']}
                              </h6>
                              <h6>
                                <span>Time is:</span>{' '}
                                {`${log.input_schema['time']}h (${
                                  log.input_schema['time'] === 0
                                    ? 12
                                    : log.input_schema['time'] < 13
                                    ? log.input_schema['time']
                                    : (log.input_schema['time'] as number) % 12
                                }${
                                  log.input_schema['time'] < 12 ? 'AM' : 'PM'
                                })`}
                              </h6>
                              <h6>
                                <span>Timezone:</span>{' '}
                                {log.input_schema['timezone']}
                              </h6>
                            </>
                          ) : (
                            <>
                              <h6>
                                <span>Condition:</span> Current time
                              </h6>
                              <h6>
                                <span>Time is:</span>{' '}
                                {`${log.input_schema['time']}h (${
                                  log.input_schema['time'] === 0
                                    ? 12
                                    : log.input_schema['time'] < 13
                                    ? log.input_schema['time']
                                    : (log.input_schema['time'] as number) % 12
                                }${
                                  log.input_schema['time'] < 12 ? 'AM' : 'PM'
                                })`}
                              </h6>
                              <h6>
                                <span>Timezone:</span>{' '}
                                {log.input_schema['timezone']}
                              </h6>
                            </>
                          )
                        ) : (
                          Object.entries(log.input_schema).map(
                            (schema: any, i) =>
                              typeof schema[1] == 'object' &&
                              (schema[1] != null || schema[1] != undefined) ? (
                                <>
                                  <h4>
                                    {i + 1}. {schema[0]}:
                                  </h4>
                                  {Object.entries(schema[1]).map(
                                    (sch: any, j) => (
                                      <h6 key={j}>
                                        {j + 1}. {sch[0]}:{' '}
                                        <span>
                                          {R.isNil(sch[1]) ? '' : sch[i]}
                                        </span>
                                      </h6>
                                    )
                                  )}
                                </>
                              ) : (
                                <h6 key={i}>
                                  {i + 1}. {schema[0]}: <span>{schema[1]}</span>
                                </h6>
                              )
                          )
                        )}
                      </div>
                    )}
                    <div className="analytics_popup_body_minus_body">
                      {renderNodes(name.value, log.node_execution_status, log)}
                    </div>
                  </React.Fragment>
                )
              ) : log.node_execution_status === 'skipped' ? (
                <div className="automation_logs_collapse_open_content">
                  <div
                    className={'automation_logs_collapse_open_content_scroll'}
                  >
                    <h5 style={{ paddingTop: 0 }}>Condition not executed!</h5>
                    <p>
                      Previous action/condition is failed. So, the condition
                      node is not executed.
                    </p>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <div className="automation_logs_collapse_open_content">
                    <div
                      className={'automation_logs_collapse_open_content_scroll'}
                    >
                      <h5 style={{ paddingTop: 0 }}>
                        Condition:
                        <p style={{ marginBottom: 0, paddingLeft: 5 }}>
                          {log.input_schema.description}
                        </p>
                      </h5>
                      <p>
                        Condition excution status:
                        {log.node_execution_status === 'success' &&
                        log.output_schema[log.node_id] ? (
                          <span
                            style={{ color: '#2DB677', padding: '0px 3px' }}
                          >
                            {' '}
                            true{' '}
                          </span>
                        ) : (
                          <span
                            style={{ color: '#DD4330', padding: '0px 3px' }}
                          >
                            {' '}
                            false{' '}
                          </span>
                        )}
                        {log.node_execution_status === 'success' &&
                        log.output_schema[log.node_id]
                          ? '(condition satisfied)'
                          : '(condition not satisfied)'}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              )
            ) : log.node_type == NodeTypeT.notification ? (
              log.node_execution_status === 'skipped' ||
              log.output_schema.error ? (
                <div className="automation_logs_collapse_open_content">
                  <div
                    className={'automation_logs_collapse_open_content_scroll'}
                  >
                    <h5 style={{ paddingTop: 0 }}>
                      Notification not executed!
                    </h5>
                    <p>
                      {log.output_schema.error
                        ? log.output_schema.error
                        : 'Previous action/condition is failed. So, the notification node is not executed.'}
                    </p>
                  </div>
                </div>
              ) : Object.keys(log.output_schema).includes('channel_id') ? (
                <>
                  <div className="analytics_popup_notifiaction_box">
                    <div className="analytics_popup_notifiaction_up">
                      <div className="flex">
                        <h6>Input Provided</h6>
                        <span
                          className={inputOpen ? 'open' : 'close'}
                          onClick={() => {
                            setInputOpen(!inputOpen);
                            setStatusOpen(!statusOpen);
                          }}
                        >
                          {' '}
                          <DownarrowWithCircle />{' '}
                        </span>
                      </div>
                      {inputOpen && (
                        <>
                          <div>
                            <h6>
                              Notification Type:{' '}
                              <span>
                                {capitalizeFirstLetter(
                                  log.output_schema.notification_type
                                )}
                              </span>
                            </h6>
                            <h6>
                              Selected Bot:{' '}
                              <span>
                                {getBotName(log.output_schema.bot_id)}
                              </span>
                            </h6>
                            <h6>
                              Chat Channel:{' '}
                              <span>
                                {capitalizeFirstLetter(
                                  log.output_schema.chat_channel_type
                                )}
                              </span>
                            </h6>
                            <h6>
                              Channel Name: <span>{log.output_schema.slack_workspace ? capitalizeFirstLetter(log.output_schema.slack_workspace) :"-"}</span>
                            </h6>
                            <h6>
                              Send Notification to:{' '}
                              <span>
                                {log.output_schema.notification_recipient.type === "broadcast" ? "All user" :log.output_schema.notification_recipient.type } 
                              </span>
                            </h6>
                            <h6>
                              User Email:{' '}
                              <span>
                                {
                                  log.output_schema.notification_recipient
                                    .user_email ? log.output_schema.notification_recipient
                                    .user_email : "-"
                                }
                              </span>
                            </h6>
                            <h6>
                              Notification Message Type:{' '}
                              <span>
                                {getMessageType(
                                  log.output_schema.notification_input
                                    .notification_input_type
                                )}
                              </span>
                            </h6>
                            <h6>
                              Notification title:{' '}
                              <span>
                                {
                                  log.output_schema.notification_input
                                    .notification_title
                                }
                              </span>
                            </h6>
                            <h6>Message to User: </h6>
                          </div>
                          {log.output_schema.notification_input
                            .notification_input_type === 'option' ? (
                            <>
                              <div className="tryit_understand_parent test_run">
                                {/* <h6>{log.output_schema.notification_input.notification_title}</h6> */}
                                <p className="tryit_understand tryme_option_title">
                                  <AssistantMd
                                    mdText={convertToMDText(
                                      log.output_schema.notification_input
                                        .message
                                    )}
                                  />
                                </p>
                                <div className="tryit_option test_run">
                                  {log.output_schema.notification_input.option.map(
                                    (option: any, index: number) => (
                                      <button
                                        key={index}
                                        className={
                                          option.label.length < 57
                                            ? 'btn btn-primary tryme_option_button'
                                            : 'btn btn-primary tryme_option_button'
                                        }
                                      >
                                        <span>
                                          <AssistantMd
                                            mdText={convertToMDText(
                                              option.label
                                            )}
                                          />
                                        </span>
                                      </button>
                                    )
                                  )}
                                </div>
                              </div>
                            </>
                          ) : log.output_schema.notification_input
                              .notification_input_type === 'adaptive_card' ? (
                            <div className="position-realtive tryit_wrapper_card adaptive_card ">
                              <div className="tryit_cards">
                                <div className="tryit_cards_top">
                                  <div className="tryit_cards_top_right">
                                    {log.output_schema.notification_input
                                      .adaptive_card.title && (
                                      <h4 className="word_break">
                                        <AssistantMd
                                          mdText={convertToMDText(
                                            log.output_schema.notification_input
                                              .adaptive_card.title
                                          )}
                                        />
                                      </h4>
                                    )}
                                    {log.output_schema.notification_input
                                      .adaptive_card.description && (
                                      <p>
                                        <AssistantMd
                                          mdText={convertToMDText(
                                            log.output_schema.notification_input
                                              .adaptive_card.description
                                          )}
                                        />
                                      </p>
                                    )}
                                  </div>
                                  <div className="tryit_cards_left">
                                    <div className="tryit_ticket_wrapper tryit_content_wrapper">
                                      {log.output_schema.notification_input
                                        .adaptive_card.fields.length > 0 && (
                                        <div className="tryit_ticket_details_wrapper">
                                          {log.output_schema.notification_input.adaptive_card.fields.map(
                                            (field: any, key: number) => (
                                              <div
                                                className="tryit_ticket_details"
                                                key={key}
                                              >
                                                <h6>
                                                  {Object.keys(field.title)
                                                    .length > 0 && (
                                                    <AssistantMd
                                                      mdText={convertToMDText(
                                                        field.title
                                                      )}
                                                    />
                                                  )}
                                                </h6>
                                                <span className="logs_colen">
                                                  :
                                                </span>
                                                <p>
                                                  <AssistantMd
                                                    mdText={convertToMDText(
                                                      field.value
                                                    )}
                                                  />
                                                </p>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                      {log.output_schema.notification_input
                                        .adaptive_card.buttons.length > 0 && (
                                        <div className="tryit_ticket_details_wrapper">
                                          {log.output_schema.notification_input.adaptive_card.buttons.map(
                                            (field: any, key: number) => {
                                              return (
                                                <div className="tryit_ticket_details">
                                                  <SmallButton
                                                    style={{
                                                      marginTop: 6,
                                                      minWidth: 'max-content',
                                                    }}
                                                    primary
                                                    key={key}
                                                    onClick={() =>
                                                      window.open(
                                                        convertToSingleStringforTryme(
                                                          convertToMDText(
                                                            field.url
                                                          ),
                                                          ''
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <label
                                                      style={{ width: 60 }}
                                                    >
                                                      {/* {field.structValue.fields.text.stringValue} */}
                                                      <AssistantMd
                                                        mdText={convertToMDText(
                                                          field.text
                                                        )}
                                                      />
                                                    </label>
                                                  </SmallButton>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="tryit_cards_wrapper">
                                  <div className="tryit_cards_top_left">
                                    {log.output_schema.notification_input
                                      .adaptive_card.logo && (
                                      <img
                                        src={
                                          log.output_schema.notification_input
                                            .adaptive_card.logo
                                        }
                                        alt={'image'}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="tryit_understand_parent test_run">
                              {/* <h1>{log.output_schema.notification_input.notification_title}</h1> */}
                              <p className="tryit_understand tryme_option_title">
                                <AssistantMd
                                  mdText={convertToMDText(
                                    log.output_schema.notification_input.message
                                  )}
                                />
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="analytics_popup_notifiaction_section">
                    <div className="analytics_popup_notifiaction_up">
                      <div className="flex">
                        <h6>Notification Status</h6>
                        <span
                          className={statusOpen ? 'open' : 'close'}
                          onClick={() => {
                            setInputOpen(!inputOpen);
                            setStatusOpen(!statusOpen);
                          }}
                        >
                          {' '}
                          <DownarrowWithCircle />{' '}
                        </span>
                        <div
                          className="refresh_icon flex"
                          onClick={() => notificationStatsApi()}
                        >
                          <RefreshIcon /> Refresh
                        </div>
                      </div>
                      {statusOpen && (
                        <div>
                          {notificationStats && (
                            <>
                              <h6>
                                Number of Users:{' '}
                                <span>{notificationStats.totaluser}</span>
                              </h6>
                              <h6>
                                Notifications Delivered to Users:{' '}
                                <span style={{color:"#0EB251"}}>{notificationStats.success}</span>
                              </h6>
                              <h6>
                                Notifications In Progress to Users:{' '}
                                <span style={{color:"#E8790A"}}>{notificationStats.inprogress}</span>
                              </h6>
                              <h6>
                                Notifications Failed to Users:{' '}
                                <span style={{color:"#FF0D0D"}}>{notificationStats.failed}</span>
                              </h6>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : log.output_schema.notification_input_type === 'option' ? (
                <>
                  <div className="tryit_understand_parent test_run">
                    <h6>{log.output_schema.notification_title}</h6>
                    <p className="tryit_understand tryme_option_title">
                      <AssistantMd
                        mdText={convertToMDText(log.output_schema.message)}
                      />
                    </p>
                    <div className="tryit_option test_run">
                      {log.output_schema.option.map(
                        (option: any, index: number) => (
                          <button
                            key={index}
                            className={
                              option.label.length < 57
                                ? 'btn btn-primary tryme_option_button'
                                : 'btn btn-primary tryme_option_button'
                            }
                          >
                            <span>
                              <AssistantMd
                                mdText={convertToMDText(option.label)}
                              />
                            </span>
                          </button>
                        )
                      )}
                    </div>
                  </div>
                </>
              ) : log.output_schema.notification_input_type ===
                'adaptive_card' ? (
                <div className="position-realtive tryit_wrapper_card adaptive_card ">
                  <div className="tryit_cards">
                    <div className="tryit_cards_top">
                      <div className="tryit_cards_top_right">
                        {log.output_schema.adaptive_card.title && (
                          <h4 className="word_break">
                            <AssistantMd
                              mdText={convertToMDText(
                                log.output_schema.adaptive_card.title
                              )}
                            />
                          </h4>
                        )}
                        {log.output_schema.adaptive_card.description && (
                          <p>
                            <AssistantMd
                              mdText={convertToMDText(
                                log.output_schema.adaptive_card.description
                              )}
                            />
                          </p>
                        )}
                      </div>
                      <div className="tryit_cards_left">
                        <div className="tryit_ticket_wrapper tryit_content_wrapper">
                          {log.output_schema.adaptive_card.fields.length >
                            0 && (
                            <div className="tryit_ticket_details_wrapper">
                              {log.output_schema.adaptive_card.fields.map(
                                (field: any, key: number) => (
                                  <div
                                    className="tryit_ticket_details"
                                    key={key}
                                  >
                                    <h6>
                                      {Object.keys(field.title).length > 0 && (
                                        <AssistantMd
                                          mdText={convertToMDText(field.title)}
                                        />
                                      )}
                                    </h6>
                                    <span className="logs_colen">:</span>
                                    <p>
                                      <AssistantMd
                                        mdText={convertToMDText(field.value)}
                                      />
                                    </p>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                          {log.output_schema.adaptive_card.buttons.length >
                            0 && (
                            <div className="tryit_ticket_details_wrapper">
                              {log.output_schema.adaptive_card.buttons.map(
                                (field: any, key: number) => {
                                  return (
                                    <div className="tryit_ticket_details">
                                      <SmallButton
                                        style={{
                                          marginTop: 6,
                                          minWidth: 'max-content',
                                        }}
                                        primary
                                        key={key}
                                        onClick={() =>
                                          window.open(
                                            convertToSingleStringforTryme(
                                              convertToMDText(field.url),
                                              ''
                                            )
                                          )
                                        }
                                      >
                                        <label style={{ width: 60 }}>
                                          {/* {field.structValue.fields.text.stringValue} */}
                                          <AssistantMd
                                            mdText={convertToMDText(field.text)}
                                          />
                                        </label>
                                      </SmallButton>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="tryit_cards_wrapper">
                      <div className="tryit_cards_top_left">
                        {log.output_schema.adaptive_card.logo && (
                          <img
                            src={log.output_schema.adaptive_card.logo}
                            alt={'image'}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="tryit_understand_parent test_run">
                  <h1>{log.output_schema.notification_title}</h1>
                  <p className="tryit_understand tryme_option_title">
                    <AssistantMd
                      mdText={convertToMDText(log.output_schema.message)}
                    />
                  </p>
                </div>
              )
            ) : (
              <div className="analytics_popup_body_minus_footer analytics_popup_body_minus_body">
                <h4>Automation execution status:</h4>
                <React.Fragment>
                  <h6>
                    1. Automation name:{' '}
                    <span>{popupState.executionData.automation_name}</span>
                  </h6>
                  <h6>
                    2. Date & Time:{' '}
                    <span>{popupState.executionData.start_time}</span>
                  </h6>
                  <h6>
                    3. Executed by:{' '}
                    {popupState.executionData.executed_by ? (
                      <span>{popupState.executionData.executed_by}</span>
                    ) : (
                      <span>Not available</span>
                    )}
                  </h6>
                  <h6>
                    4. Time taken:{' '}
                    <span>{popupState.executionData.executionTime}</span>
                  </h6>
                  <h6>
                    5. Status:{' '}
                    <span>
                      {popupState.executionData.status.toLowerCase() ===
                      'success' ? (
                        <span style={{ color: '#2DB677' }}>Success</span>
                      ) : (
                        <span style={{ color: '#DD4330' }}>'Failed'</span>
                      )}
                    </span>
                  </h6>
                  <h6>
                    6. Error:{' '}
                    <span>
                      {popupState.executionData.status.toLowerCase() ===
                      'success'
                        ? 'No Error'
                        : 'Error'}
                    </span>
                  </h6>
                  <span>
                    <h4 style={{ paddingLeft: 0 }}>
                      Automation execution report sent to:{' '}
                    </h4>
                  </span>
                  {log.node_execution_status === 'success' ? (
                    <h6>
                      Automation success:{' '}
                      {
                        <span>
                          {!log.node_meta ||
                          log.node_meta.successEmail.length === 0
                            ? 'Not configured'
                            : log.node_meta.successEmail}
                        </span>
                      }
                    </h6>
                  ) : (
                    <h6>
                      Automation Failed:{' '}
                      {
                        <span>
                          {!log.node_meta ||
                          log.node_meta.failureEmail.length === 0
                            ? 'Not configured'
                            : log.node_meta.failureEmail}
                        </span>
                      }
                    </h6>
                  )}
                </React.Fragment>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export function renderNodes(
  name: string,
  status: 'success' | 'failed' | 'in_progress',
  log:
    | NormalViewActionNodeLogT
    | EndNodeLogT
    | WebHookNodeLogT
    | WebHookTriggerNodeLogT
    | ConditionNodeLogT
    | PollingNodeLogT
    | EventTriggerNodeLogT
    | ApprovalNodeLogT
    | TimerNodeLogT
) {
  if (status === 'success') {
    return log.node_type === NodeTypeT.web_hook ||
      log.node_type === NodeTypeT.webhook_trigger ? (
      <div className="analytics_popup_body_minus_footer">
        <h4>Output:</h4>

        {Object.entries(log.output_schema).length > 0 ? (
          Object.entries(log.output_schema).map((schema: any, i) =>
            typeof schema[1] == 'object' &&
            (schema[1] != null || schema[1] != undefined) ? (
              <>
                <h4>
                  {i + 1}. {schema[0]}:
                </h4>
                {Object.entries(schema[1]).map((sch: any, j) => (
                  <h6 key={j}>
                    {j + 1}. {sch[0]}:{' '}
                    <span>{R.isNil(sch[1]) ? '' : sch[1]}</span>
                  </h6>
                ))}
              </>
            ) : (
              <h6 key={i}>
                {i + 1}. {schema[0]}: <span>{schema[1]}</span>
              </h6>
            )
          )
        ) : (
          <h6>No output found</h6>
        )}
      </div>
    ) : (
      <React.Fragment>
        <p>{name} executed successfully.</p>

        <div className="analytics_popup_body_minus_footer">
          <h4>Output:</h4>{' '}
          <div className="automation_logs_ul_tree">
            {convertObjectToArray(log.output_schema).length > 0 ? (
              log.node_type === NodeTypeT.timer ? (
                <h6>
                  Timer excution status:{' '}
                  {log.output_schema.success ? (
                    <span style={{ color: '#2DB677', padding: '0px 3px' }}>
                      {' '}
                      Success{' '}
                    </span>
                  ) : (
                    <span style={{ color: 'red', padding: '0px 3px' }}>
                      {' '}
                      Failed{' '}
                    </span>
                  )}
                </h6>
              ) : (
                <LogsOutputTree
                  output={log.output_schema}
                  executionSuccess={true}
                  developerView={false}
                />
              )
            ) : (
              <h6>No output found</h6>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  } else if (status === 'in_progress') {
    return (
      <React.Fragment>
        <p>{name} execution in progress.</p>
      </React.Fragment>
    );
  } else
    return (
      <React.Fragment>
        <p>{name} execution failed.</p>

        <div className="analytics_popup_body_minus_footer error_output">
          <h4>Output:</h4>
          <LogsOutputTree
            output={log.output_schema}
            executionSuccess={false}
            developerView={false}
          />
        </div>
      </React.Fragment>
    );
}

export function OverviewPopup(props: any) {
  const [search, setSearch] = useState('');

  function sortDataWithApi(id: string) {
    let req = props.request;
    req = {
      type: RequestType.IndividualFlow,
      req: { ...req.req, flow: id, page: 1 },
    };
    props.setRequest(req);
    props.callApi(req.req);
    props.closePopup();
    setSearch('');
  }

  function sortAllDataWithApi() {
    let req = props.request;
    req = { type: RequestType.AllFlows, req: { ...req.req, page: 1 } };
    req.req.flow && delete req.req.flow;
    props.setRequest(req);
    props.callApi(req.req);
    props.closePopup();
    setSearch('');
  }

  function filterBySearch(array: AutomationListT[], search: string) {
    return array.filter(
      (x: AutomationListT) =>
        x.name.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
  }

  return props.showState.showFlowPopup ? (
    <Downshift isOpen={true} onOuterClick={() => props.closePopup()}>
      {() => (
        <div
          className="dropdown_right_entity"
          role="combobox"
          aria-expanded="true"
          aria-haspopup="listbox"
          aria-owns="downshift-1-menu"
          aria-labelledby="downshift-1-label"
        >
          <label className="form-input">
            <input
              type="text"
              className="form-control ana_chart_drpdwn_left_fourty_form_control"
              placeholder="Automation name"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </label>
          <div className="dialog_scroll_list">
            <ul>
              <li onClick={() => sortAllDataWithApi()}>All</li>
              {filterBySearch(props.data.flows, search).map(
                (flow: AutomationListT, i: number) => (
                  <li key={i} onClick={() => sortDataWithApi(flow.id)}>
                    {flow.name}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      )}
    </Downshift>
  ) : null;
}

export function Dropdwon(props: any) {
  function selectedvalue(date: any) {
    props.selectedvalue(date);
  }
  const { showState, hasId } = props;

  return (
    <DropdownWrapper>
      <InputContainer style={{ marginBottom: 0 }}>
        <Input type="text" value={props.value} onClick={props.onClick} />
        <InputContent>{props.header}</InputContent>
        <CancelButton style={{ cursor: 'pointer' }} onClick={props.onClick}>
          <DropdownWithCircle />
        </CancelButton>

        {showState && showState.showPopup ? (
          <Downshift isOpen={true} onOuterClick={() => props.closePopup()}>
            {() => (
              <span>
                <DropdownUlWrapper>
                  <DropdownUl>
                    <DropdownLi
                      onClick={(e: any) => {
                        e.stopPropagation();
                        selectedvalue(props.defaultValue);
                      }}
                    >
                      {props.defaultValue}{' '}
                    </DropdownLi>

                    {props.analyticsData &&
                      props.analyticsData.data &&
                      props.analyticsData.data.map((value: any, i: number) => (
                        <DropdownLi
                          onClick={(e: any) => {
                            e.stopPropagation();
                            selectedvalue(hasId ? value.id : value);
                          }}
                          key={i}
                        >
                          {/* <p> */}
                          {typeof value == 'string'
                            ? capitalizeFirstLetter(
                                value == 'try_it' ? 'try Me' : value
                              )
                            : hasId
                            ? capitalizeFirstLetter(value.title)
                            : value}
                          {/* </p> */}
                        </DropdownLi>
                      ))}
                  </DropdownUl>
                </DropdownUlWrapper>
              </span>
            )}
          </Downshift>
        ) : null}
      </InputContainer>
    </DropdownWrapper>
  );
}

export function DateDropDown(props: any) {
  function selectedDate(date: { date: string; label: string }) {
    props.setSelectedDate({ date });
  }

  const items: DateSelectInput[] = [];

  const allItems = props.displayItems
    ? items.concat(props.displayItems)
    : items;

  const { state } = props;

  return (
    <DropdownWrapper>
      <InputContainer>
        <Input
          type="text"
          value={state.selectedDate.label}
          onClick={props.onClick}
        />
        <InputContent>{'Show by: '}</InputContent>
        <CancelButton style={{ cursor: 'pointer' }} onClick={props.onClick}>
          <DropdownWithCircle />
        </CancelButton>

        {state.showDatePopup ? (
          <Downshift isOpen={true} onOuterClick={() => props.closePopup()}>
            {() => (
              <span>
                <DropdownUlWrapper>
                  <DropdownUl>
                    {allItems.map((item, index) => (
                      <DropdownLi
                        key={index}
                        onClick={(e: any) => {
                          if (item.date == 'custom') {
                            props.showCustomDatePicker();
                          }
                          e.stopPropagation();
                          selectedDate(item);
                        }}
                      >
                        <p>{item.label}</p>
                        <span>
                          <input
                            type="radio"
                            className="option-input radio"
                            name="example"
                            onChange={() => {}}
                            checked={state.selectedDate.date === item.date}
                          />
                        </span>
                      </DropdownLi>
                    ))}
                  </DropdownUl>
                </DropdownUlWrapper>
              </span>
            )}
          </Downshift>
        ) : null}
      </InputContainer>
    </DropdownWrapper>
  );
}

function MessageMDPopup({ data, closePopup }: any) {
  return (
    <Modal
      className={
        data.type === 'adaptive_card'
          ? 'view_message_modal apdaptive' 
          : data.type === 'option' ? 'view_message_modal option'
          : 'view_message_modal'
      }
    >
      <div
        className="notification_modal_close"
        onClick={() => {
          closePopup();
        }}
      >
        <CloseIconWithCircle />
      </div>
      {/* <div className="knowledge_container_popup externalKB delete_popup"> */}
      {data.type === 'adaptive_card' && <AdaptiveCard output={data as any} />}
      {data.type === 'option' && (
        <OptionRes message={data} sendMessage={false} />
      )}
      {data.type === 'text' && (
        <>
          <div className="tryit_understand_parent">
            {data.notification_title}
          </div>
          <p className="tryit_understand tryme_option_title">
            <AssistantMd mdText={convertToMDText(data.text)} />
          </p>
        </>
      )}
    </Modal>
  );
}
function AdaptiveCard({ output }: any) {
  console.log(output, 'outputoutput');
  return (
    <>
      <div className="tryit_understand_parent">{output.notification_title}</div>
      <div className="liveAt_adaptive_single tryit_wrapper_card">
        <div className="tryit_cards">
          <div className="tryit_cards_top">
            <div className="tryit_cards_top_left">
              {output.logo && (
                <img src={output.logo} alt={'image'} />
              )}
            </div>
            <div className="tryit_cards_top_right">
              <h4 className="word_break">
                <AssistantMd mdText={convertToMDText(output.title)} />
              </h4>
              {output.description && (
                <p>
                  <AssistantMd mdText={convertToMDText(output.description)} />
                </p>
              )}
            </div>
          </div>
          <div className="tryit_cards_wrapper">
            <div className="tryit_cards_left">
              <div className="tryit_ticket_wrapper tryit_content_wrapper">
                {output.fields && (
                  <div className="tryit_ticket_details_wrapper">
                    {output.fields.map((field: any, key: number) => (
                      <div className="tryit_ticket_details" key={key}>
                        <h6>
                          <AssistantMd mdText={convertToMDText(field.title)} />
                        </h6>
                        <p className="tryit_ticket_colun">:</p>
                        <p>
                          <AssistantMd mdText={convertToMDText(field.value)} />
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                {output.buttons && (
                  <div className="tryit_ticket_details_wrapper">
                    {output.buttons.map((field: any, key: number) => {
                      return (
                        <div className="tryit_ticket_details">
                          <SmallButton
                            // style={{ marginTop: 6 }}
                            primary
                            key={key}
                            onClick={() => window.open(field.url)}
                          >
                            <label>
                              <AssistantMd
                                mdText={convertToMDText(field.text)}
                              />
                            </label>
                          </SmallButton>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function OptionRes({ message, sendMessage }: any) {
  console.log(message, 'outputoutput');
  return (
    <React.Fragment>
      {
        <div className="tryit_understand_parent">
          {message.notification_title}
        </div>
      }
      {message.title !== '' ? (
        <div className="tryit_understand_parent">
          {/* <div className='try_me_icon'>
                        <TrymeIcon />
                    </div> */}
          <p className="tryit_understand tryme_option_title">
            <AssistantMd mdText={convertToMDText(message.title)} />
          </p>
        </div>
      ) : null}
      <div className="tryit_option">
        {message.options.map((option: any, index: number) => (
          <button
            key={index}
            className={
              option.label.length < 57
                ? 'btn btn-primary tryme_option_button'
                : 'btn btn-primary tryme_option_button'
            }
            onClick={() => {
              sendMessage(convertToSingleStringforTryme(option.value, ''));
            }}
          >
            <span>
              <AssistantMd mdText={convertToMDText(option.label)} />
            </span>
          </button>
        ))}
      </div>
    </React.Fragment>
  );
}

export function PaginatedItems({
  itemsPerPage,
  items,
  pageChange,
  forcePage,
  setForcepage,
}: {
  itemsPerPage: number;
  items: number;
  pageChange: any;
  forcePage: number;
  setForcepage: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const pageCount = Math.ceil(items / itemsPerPage);
  console.log(pageCount, items, itemsPerPage, 'itemsPerPage');
  useEffect(() => {
    if (forcePage !== 0) {
      const value = Number(forcePage) - 1;
      console.log('Current Page:', value);
      setCurrentPage(value);
    }
  }, [forcePage]);

  const handlePageClick = (event: { selected: number }) => {
    console.log(event.selected, 'handlePageClick');
    pageChange(event.selected + 1);
    setCurrentPage(event.selected);
    setForcepage(0);
  };

  return (
    <>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<DropdownWithCircle />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount - 1}
        previousLabel={<DropdownWithCircle />}
        renderOnZeroPageCount={null}
        forcePage={currentPage}
      />
    </>
  );
}
