import React from 'react';
import styled from 'styled-components/macro';
import {
  CheckColorIcon,
  ConfluenceIcon,
  FreshServiceIcon,
  S3ConnectionIcon,
  SharePointIcon,
  SlackIconCC,
  VivantioIcon,
} from '@/common/Icons/Icons';
import { Button } from '@/common/styled/Dialog.BotDetails.Dumb';
import { TabelWrapper } from '@/common/styled/Dialogue.Dumb';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@/common/styled/Workflow.Analytics.Dumb';
import { ButtonContainer } from '@/common/styled/Workflow.Dumb';
import { Modal } from '@/common/components/Modal';
import { useExternalCategory, useKBCategory } from './KnowledgeApis';
import { Header as PageHeader, HeaderChild } from '@/common/components/Header';
import { formatAMPM, formatDateInCard } from '@/common/utils/utils';
import { KnowledgeBase } from './MockFiles';
import { KnowledgeAIUpgrade } from './KnowledgeUpgrade';
import { useQuery } from 'react-query';
import { stripeGetApi } from '@/common/utils/api-utils';
import { Loader } from '@/common/components/Loader';

const BgWrapper = styled.div`
  width: 100%;
  float: left;
  padding: 20px 20px 0px;
  background: #f4f4f4;
  margin-bottom: 10px;
`;

const OptionalInput = styled.div`
  width: 100%;
  float: left;

  .target.customTip {
    width: 9px;
    height: 9px;
    pointer-events: all;
    cursor: pointer;
    left: 3px;
    svg {
      width: 9px;
      height: 9px;
      pointer-events: all;
      cursor: pointer;
    }
  }
`;

export function KnowledgeExternalKB(props: any) {
  const workspace = props.match.params.workspacename;
  const { kbCategorydata } = useKBCategory(workspace);

  const { external, externalCategory, exCatResult } =
    useExternalCategory(workspace);
  
  // const exCatArray: KnowledgeBase[] = exCatResult.SHAREPOINT_ONLINE;
  
  const pageLink = (type: string) => {
    console.log(type,"pageLink")
    switch (type){
      case 'SHAREPOINT_ONLINE' :
        return props.history.push(
          `/workspace/${props.match.params.workspacename}/assistant/knowledge/external-kb/sharepoint`
        );
      case 'SHAREPOINT_CUSTOM' :
        return props.history.push(
          `/workspace/${props.match.params.workspacename}/assistant/knowledge/external-kb/sharepointcustom`
        );
      case "CONFLUENCE_ONLINE" :
           return props.history.push(
                  `/workspace/${props.match.params.workspacename}/assistant/knowledge/external-kb/confluence`
                );
      case "VIVANTIO" :
           return props.history.push(
                  `/workspace/${props.match.params.workspacename}/assistant/knowledge/external-kb/vivantio`
                );
      case "FRESHSERVICE" :
        return props.history.push(
                `/workspace/${props.match.params.workspacename}/assistant/knowledge/external-kb/freshservice`
              );
      case 'S3' :
        return props.history.push(
          `/workspace/${props.match.params.workspacename}/assistant/knowledge/external-kb/amazons3`
        );
    }
  };
  // const trainedCon = exCatArray && exCatArray.length >0 ? exCatArray.filter((e)=>e.syncHistory !== null).length : 0
  const checkingPlanAccess = useQuery(
    'users::knowledge-access',
    () => stripeGetApi(`${workspace}/info/KB_ACCESS`),
    {
      onSuccess: (response) => {
        //console.log(response,'response')
      },
      staleTime: 0,
      refetchOnMount: true,
    }
  );
  const planAllowed = 
  checkingPlanAccess.isSuccess && checkingPlanAccess.data.output == 'yes';

  const kbIcon =(conn:string)=>{
    switch (conn) {
      case 'SHAREPOINT_ONLINE':
        return <SharePointIcon />;
      case 'SHAREPOINT_CUSTOM':
        return <SharePointIcon />;
      case 'CONFLUENCE_ONLINE':
        return <ConfluenceIcon />;
      case 'VIVANTIO':
        return <VivantioIcon />;
      case 'FRESHSERVICE':
        return <FreshServiceIcon />;
      case 'S3':
          return <S3ConnectionIcon />
      default:
        return <S3ConnectionIcon />;
    }
  }

  // console.log(external,externalCategory,exCatResult,'externalvalue')
  return (
    <>
      {/* <PageHeader>
        {[
          <HeaderChild type="first">
            <div className="indent_left">
              <div className="indent_title">External KB</div>
            </div>
          </HeaderChild>,
        ]}
      </PageHeader> */}
      {checkingPlanAccess.status == 'loading'?(
          <Loader.PageLoader show/>
      ):!planAllowed ? (
          <KnowledgeAIUpgrade {...props}/>
      ) : (
      <section className="entity_cards externalKB externalKB_home">
        <div className="col-md-12">
          <div className="row card_grid_view">
            {external.length > 0 ? (
              external.map((data,index) => (
                <div
                  key={index}
                  className="col-lg-3 col-md-3 col-sm-3 dialog_cards_layer"
                  onClick={() => pageLink(data.type)}
                >
                  <div className="externalKB_title_box external_kb_home">
                    <div className="externalKB_title">
                      <div className="externalKB_title_logo">
                      {data.type === 'SHAREPOINT_ONLINE' || data.type === 'VIVANTIO' || data.type === 'S3' ? kbIcon(data.type) : <span>{kbIcon(data.type)}</span>}
                        {/* {data.type === 'SHAREPOINT_ONLINE' ?  <SharePointIcon /> : <span> <ConfluenceIcon /></span>} */}
                      </div>
                      <div className="externalKB_title_head">
                        <h4>{data.name === "Microsoft Sharepoint Custom" ? "Microsoft Sharepoint Online" : data.name}</h4>
                        <p>
                          <span>Language:</span> English (United States)
                        </p>
                      </div>
                    </div>
                    <div className="externalKB_title_count_box">
                      <div className="externalKB_title_count">
                        <h4>{data.knowledgeBase.length}</h4>
                        <p>Total Connections</p>
                      </div>
                      <div className="externalKB_title_count">
                        <h4>{data && data.knowledgeBase.length >0 ? data.knowledgeBase.filter((e)=>e.syncHistory.length >0).length : 0}</h4>
                        <p>Trained Connections</p>
                      </div>
                    </div>  
                    <div className="externalKB_status_box flex">
                      <div className="externalKB_status_title flex">
                        <h5>Last Updated:</h5>
                        <div className="error_icon_wrapper_left">
                          <span
                            className="entity_span"
                            style={{ color: '#355D8B' }}
                          >
                            {data.knowledgeBase[0]?.updatedAt
                              ? formatDateInCard(
                                  data.knowledgeBase[0].updatedAt
                                )
                              : '--'}
                          </span>
                          <span className="entity_span">|</span>
                          <span
                            className="entity_span"
                            style={{ color: '#355D8B' }}
                          >
                            {data.knowledgeBase[0]?.updatedAt
                              ? formatAMPM(data.knowledgeBase[0].updatedAt)
                              : '--'}
                          </span>
                        </div>
                      </div>
                      <div className="externalKB_status_title flex">
                        <h5>Last Trained:</h5>
                        <p><span
                                className="entity_span"
                                style={{ color: '#355D8B' }}
                              >
                                {data.knowledgeBase.length>0
                                  ? (data.knowledgeBase.filter((e)=>e.syncHistory.length >0).length>0 ? (formatDateInCard
                                  (data.knowledgeBase.filter((e)=>e.syncHistory.length >0)[0].syncHistory[0].updatedAt)): '--')
                                  : '--'}
                              </span>
                              <span className="entity_span">|</span>
                              <span
                                className="entity_span"
                                style={{ color: '#355D8B' }}
                              >
                                {data.knowledgeBase.length>0
                                  ? (data.knowledgeBase.filter((e)=>e.syncHistory.length >0).length>0 ? 
                                  formatAMPM(data.knowledgeBase.filter((e)=>e.syncHistory.length >0)[0].syncHistory[0].updatedAt) :'--')
                                  : '--'}
                              </span></p>
                      </div>
                      {/* <div className="externalKB_status_title flex">
                        <h5>Trained History:</h5>
                        <p>
                          <u>View History</u>
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                className="col-lg-3 col-md-3 col-sm-3 dialog_cards_layer"
                onClick={() => pageLink('SHAREPOINT_ONLINE')}
              >
                <div className="externalKB_title_box">
                  <div className="externalKB_title">
                    <div className="externalKB_title_logo">
                    <SharePointIcon />
                    </div>
                    <div className="externalKB_title_head">
                      <h4>Microsoft SharePoint</h4>
                      <p>
                        <span>Language:</span> English (United States)
                      </p>
                    </div>
                  </div>
                  <div className="externalKB_title_count_box">
                    <div className="externalKB_title_count">
                      <h4>0</h4>
                      <p>Total Connections</p>
                    </div>
                    <div className="externalKB_title_count">
                      <h4>0</h4>
                      <p>Trained Connections</p>
                    </div>
                  </div>
                  <div className="externalKB_status_box flex">
                    <div className="externalKB_status_title flex">
                      <h5>Last Updated:</h5>
                      <p> --|--</p>
                    </div>
                    <div className="externalKB_status_title flex">
                      <h5>Last Trained:</h5>
                      <p>--|--</p>
                    </div>
                    {/* <div className="externalKB_status_title flex">
                      <h5>Trained History:</h5>
                      <p>
                        <u>View History</u>
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
        )}
      <section className="entity_cards knowledgeAi_table knowledgeExternal hide">
        <div className="col-md-12">
          <div className="row">
            <TabelWrapper>
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th size="4.5">
                        <div className="knowledgeExternal_header">
                          <div>
                            {' '}
                            <SlackIconCC />
                          </div>
                          <div>ServiceNow</div>
                        </div>
                      </Th>
                      <Th size="3.5">Authentication type</Th>
                      <Th size="2.5">Date</Th>
                      <Th size="1.5">Status </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td size="4.5" className={'list_flow_hr'}>
                        IT Hub
                      </Td>
                      <Td size="3.5" className="list_flow_p">
                        <p> Basic Auth </p>
                      </Td>
                      <Td size="2.5">28 Aug 2020</Td>
                      <Td size="1.5">
                        <CheckColorIcon />
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </TabelWrapper>
          </div>
        </div>
      </section>
    </>
  );
}
