import {
  DownArrowCircle,
  SearchIcon,
  SlackIconCC,
  UserIconAnalyticsMenu,
} from '@/common/Icons/Icons';
import { Input } from '@/common/styled/Workflow.Dumb';
import {
  convertToMDText,
  formatAMPM,
  formatDateInCard,
} from '@/common/utils/utils';
import { UserApi } from '@/DialogEditor/Canvas/Components/GetStarted';
import AssistantMd from '@skitter/assistant_md';
import Downshift from 'downshift';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { ChatUserInfo } from './ChatUser';
import { EmptyContainer, platformIcon, SessionChatP } from './inbox';
import {
  conversation,
  useClosedSessions,
  useMentionStatus,
  useOpenStatus,
} from './LivaAgentApi';
import { statusList } from './LiveAgentMenu';
import {
  ActionT,
  initialSessionData,
  Sessions,
  VIEW,
} from './LiveagentMockFile';
var InfiniteScroll = require('react-infinite-scroll-component');
export function OpenSession(props: SessionChatP) {
  const { pageState, setPageState, bots } = props;
  const sorter = ['Newest', 'Oldest'];
  const [sortValue, setSortValue] = useState<string>('Newest');
  const [sorterDropdown, setSorterDropdown] = useState<boolean>(false);
  const tabId = props.tabId;
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { openSessionData } = useOpenStatus(
    props.workspacename,
    'InProgress',
    props.selectedBotID,
    sortValue,
    pageNumber,
    () => setLoading(false),
    props.user?.id
  );
  const [loading, setLoading] = useState(false);
  console.log(pageNumber, loading, 'column');
  const [openData, setOpenData] = useState<Sessions[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetchData = () => {
    setPageNumber((prevPage) => prevPage + 1);
    setLoading(true);
  };
  useEffect(() => {
    if (pageNumber === 0 && openSessionData) {
      setOpenData(openSessionData.sessions.result);
      setTotalCount(openSessionData.sessions.total_count);
    } else if (openSessionData) {
      setOpenData([...openData, ...openSessionData.sessions.result]);
      setTotalCount(openSessionData.sessions.total_count);
    }
  }, [openSessionData]);
  const queryClient = useQueryClient();
  const [searchWord, setSearchWord] = useState<string>('');
  const sessionData =
    searchWord !== ''
      ? openData &&
        openData.filter((ses: Sessions) =>
          ses.user.first_name.toLowerCase().includes(searchWord.toLowerCase())
        )
      : openData && openData;

  const idAvailable = sessionData
    ? props.checkChatID(parseInt(tabId), sessionData)
    : false;
  const convDatas = conversation(tabId, sessionData, idAvailable);

  useEffect(() => {
    setPageState({
      action: ActionT.SELECT,
      data: [convDatas.id],
    });
  }, [convDatas]);
  console.log(pageState, 'pagestateopen');
  useEffect(() => {
    if (pageState.action === ActionT.VIEW) {
      console.log(pageState, 'pagestateopen');
      setMultiRadioSelect(false);
      setPageState({
        action: ActionT.SELECT,
        data: [convDatas.id],
      });
    } else if (pageState.action === ActionT.MOVETRASH) {
      setMultiRadioSelect(true);
    } else if (pageState.action === ActionT.SELECT) {
      setMultiRadioSelect(false);
    }
  }, [pageState]);
  const botName = (id: string) => {
    return bots.filter((b) => b.id === id)[0]?.name;
  };
  const [multiRadioSelect, setMultiRadioSelect] = useState<boolean>(false);

  const multiSelect = (sessionData: Sessions[]) => {
    if (multiRadioSelect) {
      setMultiRadioSelect(false);
      setPageState(VIEW);
    } else {
      setMultiRadioSelect(true);
      const allIDs = sessionData.map((ses) => ses.id);
      setPageState({
        action: ActionT.MOVETRASH,
        data: allIDs,
      });
    }
    console.log('multiselst');
  };
  return (
    <div>
      {sessionData && sessionData.length > 0 ? (
        <section className="liveAgent_inbox_section">
          <div className="liveAgent_inbox_list">
            <div className="liveAt_inbox_title_container">
              <div
                className="liveAt_inbox_title flex"
                style={{ padding: '11px 12px 0 16px' }}
              >
                <input
                  type="radio"
                  onClick={() => multiSelect(sessionData)}
                  checked={multiRadioSelect}
                />
                <div className="liveAt_newest_dropdown">
                  <Input
                    className="liveAt_mail_filter"
                    type="text"
                    value={sortValue}
                    onClick={() => setSorterDropdown(true)}
                    style={{ position: 'relative', top: '3.8px' }}
                  />
                  <div
                    className="arrow_up_form"
                    // style={{marginTop:'-2px'}}
                    onClick={() => setSorterDropdown(true)}
                  >
                    <DownArrowCircle />
                  </div>
                </div>
                {sorterDropdown && (
                  <Downshift
                    isOpen={true}
                    onOuterClick={() => setSorterDropdown(false)}
                  >
                    {() => (
                      <div className="slack_buton_4 integration_dropdown">
                        <ul>
                          {sorter &&
                            sorter.map((data, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  setSortValue(data);
                                  setSorterDropdown(false);
                                  queryClient.invalidateQueries('trashStatus');
                                }}
                              >
                                {/* <div>
                                  <UserIconAnalyticsMenu />
                                </div> */}
                                <div className="liveAt_user_name">{data}</div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </Downshift>
                )}
              </div>
            </div>
            <div className="liveAt_inbox_search">
              <span className="search_click">
                <input
                  value={searchWord}
                  className="search_click"
                  type="search"
                  placeholder="Search user"
                  onChange={(e) => setSearchWord(e.target.value)}
                />
                {/* <span
                onClick={() => {
                    setSearchWord('')
                    setInputFocus(false)
                }}
            >
                <CloseIcon />
            </span> */}
                <SearchIcon />
              </span>
            </div>
            <div
              className="liveAt_inbox_mail_list_container"
              id="scrollableChatList"
              style={{ overflowY: 'scroll' }}
            >
              <InfiniteScroll
                dataLength={sessionData.length} //This is important field to render the next data
                next={fetchData}
                hasMore={totalCount > sessionData.length}
                loader={<></>}
                // endMessage={<p>No more data to load.</p>}
                scrollableTarget="scrollableChatList"
                // scrollThreshold={1}
                style={{ overflow: 'hidden' }}
                initialScrollY={0}
              >
                {sessionData.length > 0 &&
                  sessionData.map((data: Sessions, index: number) => (
                    <div
                      className={
                        convDatas.chat_id == data.chat_id
                          ? 'liveAt_inbox_mail_list flex active'
                          : 'liveAt_inbox_mail_list flex'
                      }
                    >
                      <div className="liveAt_inbox_mail_user">
                        <span
                          className={
                            pageState.action === ActionT.MOVETRASH &&
                            pageState.data.includes(data.id)
                              ? 'hide'
                              : 'liveAt_user_icon'
                          }
                        >
                          <UserIconAnalyticsMenu />
                        </span>
                        <span
                          className={
                            pageState.action === ActionT.MOVETRASH &&
                            pageState.data.includes(data.id)
                              ? 'radioShow liveAt_user_radio_btn'
                              : 'liveAt_user_radio_btn'
                          }
                        >
                          <label>
                            <input
                              type="radio"
                              className="option-input radio"
                              onClick={() => {
                                props.selectConvo(data.id);
                              }}
                              checked={
                                pageState.action === ActionT.MOVETRASH &&
                                pageState.data.includes(data.id)
                              }
                              // onChange={() => {}}
                              // onChange={() => setPageState(VIEW)}
                            />
                          </label>
                        </span>
                      </div>
                      <div
                        className="liveAt_inbox_mail_box flex"
                        onClick={() => {
                          props.linkSelect(
                            props.selectedBotID,
                            data.chat_id,
                            'open-sessions'
                          );
                          setPageState({
                            action: ActionT.SELECT,
                            data: [data.id],
                          });
                        }}
                      >
                        <h5>{data.user.email}</h5>
                        <div className="flex">
                          <p>Connected with:</p>
                          <span>
                            <div>
                              <UserIconAnalyticsMenu />
                            </div>
                            <h6>
                              {data.assigned_agent
                                ? data.assigned_agent.first_name +
                                  data.assigned_agent.last_name
                                : 'Unassigned'}
                            </h6>
                          </span>
                        </div>
                        <p>
                          <AssistantMd
                            mdText={convertToMDText(data.custom_message)}
                          />
                        </p>
                        <div className="liveAt_inbox_mail_status flex">
                          <p>{botName(data.chat_source.id)}</p>
                          {/* <p>{data.session_status}</p> */}
                        </div>
                      </div>
                      <div className="liveAt_inbox_mail_icon flex">
                        <span>
                          {' '}
                          #
                          {data.chat_id < 10
                            ? '00' + data.chat_id
                            : data.chat_id < 1000
                            ? '0' + data.chat_id
                            : data.chat_id}
                        </span>
                        {platformIcon(data.platform)}
                        <p>{`${formatAMPM(data.start_time + 'Z')}`} </p>
                        <p> {`${formatDateInCard(data.start_time + 'Z')}`}</p>
                      </div>
                    </div>
                  ))}
                {/* {loading && <div>Loading...</div>} */}
              </InfiniteScroll>
            </div>
          </div>
          <ChatUserInfo
            data={convDatas.id}
            workspace={props.workspacename}
            botName={botName}
            closeConvo={props.closeConvo}
          />
        </section>
      ) : (
        <EmptyContainer searchWord={searchWord} setSearchWord={setSearchWord} />
      )}
    </div>
  );
}

export function ClosedSession(props: SessionChatP) {
  const tabId = props.tabId;
  const sorter = ['Newest', 'Oldest'];
  const [sortValue, setSortValue] = useState<string>('Newest');
  const [sorterDropdown, setSorterDropdown] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { closedSessionData } = useClosedSessions(
    props.workspacename,
    'closed',
    props.selectedBotID,
    sortValue,
    pageNumber,
    () => setLoading(false),
    props.user?.id
  );
  const [loading, setLoading] = useState(false);
  console.log(pageNumber, loading, 'column');
  const [closedData, setClosedData] = useState<Sessions[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetchData = () => {
    setPageNumber((prevPage) => prevPage + 1);
    setLoading(true);
  };
  useEffect(() => {
    if (pageNumber === 0 && closedSessionData) {
      setClosedData(closedSessionData.sessions.result);
      setTotalCount(closedSessionData.sessions.total_count);
    } else if (closedSessionData) {
      setClosedData([...closedData, ...closedSessionData.sessions.result]);
      setTotalCount(closedSessionData.sessions.total_count);
    }
  }, [closedSessionData]);
  const queryClient = useQueryClient();
  const [searchWord, setSearchWord] = useState<string>('');
  const { pageState, setPageState, bots } = props;
  const sessionData =
    searchWord !== ''
      ? closedData &&
        closedData.filter((ses: Sessions) =>
          ses.user.first_name.toLowerCase().includes(searchWord.toLowerCase())
        )
      : closedData && closedData;

  const idAvailable = sessionData
    ? props.checkChatID(parseInt(tabId), sessionData)
    : false;
  const convDatas = conversation(tabId, sessionData, idAvailable);
  useEffect(() => {
    setPageState({
      action: ActionT.CLOSEDTRASH,
      data: [convDatas.id],
    });
  }, [convDatas]);
  const botName = (id: string) => {
    return bots.filter((b) => b.id === id)[0]?.name;
  };
  const [multiRadioSelect, setMultiRadioSelect] = useState<boolean>(false);
  useEffect(() => {
    if (pageState.action === ActionT.VIEW) {
      setMultiRadioSelect(false);
      setPageState({
        action: ActionT.CLOSEDTRASH,
        data: [convDatas.id],
      });
    } else if (pageState.action === ActionT.MOVETRASH) {
      setMultiRadioSelect(true);
    }
  }, [pageState]);
  const multiSelect = (sessionData: Sessions[]) => {
    if (multiRadioSelect) {
      setMultiRadioSelect(false);
      setPageState(VIEW);
    } else {
      setMultiRadioSelect(true);
      const allIDs = sessionData.map((ses) => ses.id);
      setPageState({
        action: ActionT.MOVETRASH,
        data: allIDs,
      });
    }
    console.log('multiselst');
  };
  return (
    <div>
      {sessionData && sessionData.length > 0 ? (
        <section className="liveAgent_inbox_section">
          <div className="liveAgent_inbox_list">
            <div className="liveAt_inbox_title_container">
              <div
                className="liveAt_inbox_title flex"
                style={{ padding: '14px 12px 0 16px' }}
              >
                <input
                  type="radio"
                  onClick={() => multiSelect(sessionData)}
                  checked={multiRadioSelect}
                />
                <div style={{ width: '23%' }}>
                  <Input
                    className="liveAt_mail_filter"
                    type="text"
                    value={sortValue}
                    onClick={() => setSorterDropdown(true)}
                  />
                  <div
                    className="arrow_up_form"
                    onClick={() => setSorterDropdown(true)}
                  >
                    <DownArrowCircle />
                  </div>
                </div>
                {sorterDropdown && (
                  <Downshift
                    isOpen={true}
                    onOuterClick={() => setSorterDropdown(false)}
                  >
                    {() => (
                      <div className="slack_buton_4 integration_dropdown">
                        <ul>
                          {sorter &&
                            sorter.map((data, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  setSortValue(data);
                                  setSorterDropdown(false);
                                  queryClient.invalidateQueries('trashStatus');
                                }}
                              >
                                {/* <div>
                                  <UserIconAnalyticsMenu />
                                </div> */}
                                <div className="liveAt_user_name">{data}</div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </Downshift>
                )}
              </div>
            </div>
            <div className="liveAt_inbox_search">
              <span className="search_click">
                <input
                  value={searchWord}
                  className="search_click"
                  type="search"
                  placeholder="Search user"
                  onChange={(e) => setSearchWord(e.target.value)}
                />
                {/* <span
                onClick={() => {
                    setSearchWord('')
                    setInputFocus(false)
                }}
            >
                <CloseIcon />
            </span> */}
                <SearchIcon />
              </span>
            </div>
            <div
              className="liveAt_inbox_mail_list_container"
              id="scrollableChatList"
              style={{ overflowY: 'scroll' }}
            >
              <InfiniteScroll
                dataLength={sessionData.length} //This is important field to render the next data
                next={fetchData}
                hasMore={totalCount > sessionData.length}
                loader={<></>}
                // endMessage={<p>No more data to load.</p>}
                scrollableTarget="scrollableChatList"
                // scrollThreshold={1}
                style={{ overflow: 'hidden' }}
                initialScrollY={0}
              >
                {sessionData &&
                  sessionData.map((data: Sessions, index: number) => (
                    <div
                      key={index}
                      className={
                        convDatas.chat_id == data.chat_id
                          ? 'liveAt_inbox_mail_list flex active'
                          : 'liveAt_inbox_mail_list flex'
                      }
                    >
                      <div className="liveAt_inbox_mail_user">
                        <span
                          className={
                            pageState.action === ActionT.MOVETRASH &&
                            pageState.data.includes(data.id)
                              ? 'hide'
                              : 'liveAt_user_icon'
                          }
                        >
                          <UserIconAnalyticsMenu />
                        </span>
                        <span
                          className={
                            pageState.action === ActionT.MOVETRASH &&
                            pageState.data.includes(data.id)
                              ? 'radioShow liveAt_user_radio_btn'
                              : 'liveAt_user_radio_btn'
                          }
                        >
                          <label>
                            <input
                              type="radio"
                              className="option-input radio"
                              onClick={() => {
                                props.selectConvo(data.id);
                              }}
                              checked={
                                pageState.action === ActionT.MOVETRASH &&
                                pageState.data.includes(data.id)
                              }
                              // onChange={() => {}}
                              // onChange={() => setPageState(VIEW)}
                            />
                          </label>
                        </span>
                      </div>
                      <div
                        className="liveAt_inbox_mail_box flex"
                        onClick={() => {
                          props.linkSelect(
                            props.selectedBotID,
                            data.chat_id,
                            'closed-sessions'
                          );
                          // setPageState({
                          //   action: ActionT.MOVETRASH,
                          //   data: [data.id],
                          // });
                        }}
                      >
                        <h5>{data.user.email}</h5>
                        <div className="flex">
                          <p>Connected with:</p>
                          <span>
                            <div>
                              <UserIconAnalyticsMenu />
                            </div>
                            <h6>
                              {data.assigned_agent
                                ? data.assigned_agent.first_name +
                                  data.assigned_agent.last_name
                                : 'Unassigned'}
                            </h6>
                          </span>
                        </div>
                        <p>
                          <AssistantMd
                            mdText={convertToMDText(data.custom_message)}
                          />
                        </p>
                        <div className="liveAt_inbox_mail_status flex">
                          <p>{botName(data.chat_source.id)}</p>
                        </div>
                      </div>
                      <div className="liveAt_inbox_mail_icon flex">
                        <span>
                          {' '}
                          #
                          {data.chat_id < 10
                            ? '00' + data.chat_id
                            : data.chat_id < 1000
                            ? '0' + data.chat_id
                            : data.chat_id}
                        </span>
                        {platformIcon(data.platform)}
                        <p>{`${formatAMPM(data.start_time + 'Z')}`} </p>
                        <p> {`${formatDateInCard(data.start_time + 'Z')}`}</p>
                      </div>
                    </div>
                  ))}
                {/* {loading && <div>Loading...</div>} */}
              </InfiniteScroll>
            </div>
          </div>
          <ChatUserInfo
            data={convDatas.id}
            workspace={props.workspacename}
            botName={botName}
            closeConvo={props.closeConvo}
          />
        </section>
      ) : (
        <EmptyContainer searchWord={searchWord} setSearchWord={setSearchWord} />
      )}
    </div>
  );
}

export function MentionSession(props: { props: SessionChatP; user: UserApi }) {
  console.log(props.user?.id, 'mentionSessionData');
  const { pageState, setPageState, bots } = props.props;
  const sorter = ['Newest', 'Oldest'];
  const [sortValue, setSortValue] = useState<string>('Newest');
  const [sorterDropdown, setSorterDropdown] = useState<boolean>(false);
  const tabId = props.props.tabId;
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { mentionSessionData } = useMentionStatus(
    props.props.workspacename,
    'InProgress',
    props.props.selectedBotID,
    sortValue,
    props.user?.id,
    pageNumber,
    () => setLoading(false)
  );
  const [loading, setLoading] = useState(false);
  console.log(pageNumber, loading, 'column');
  const [mentionData, setMentionData] = useState<Sessions[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const fetchData = () => {
    setPageNumber((prevPage) => prevPage + 1);
    setLoading(true);
  };
  useEffect(() => {
    if (pageNumber === 0 && mentionSessionData) {
      setMentionData(mentionSessionData.sessions.result);
      setTotalCount(mentionSessionData.sessions.total_count);
    } else if (mentionSessionData) {
      setMentionData([...mentionData, ...mentionSessionData.sessions.result]);
      setTotalCount(mentionSessionData.sessions.total_count);
    }
  }, [mentionSessionData]);
  console.log(mentionSessionData, props.user?.id, 'mentionSessionData');
  const queryClient = useQueryClient();
  const [searchWord, setSearchWord] = useState<string>('');
  const sessionData =
    searchWord !== ''
      ? mentionData &&
        mentionData.filter((ses: Sessions) =>
          ses.user.first_name.toLowerCase().includes(searchWord.toLowerCase())
        )
      : mentionData && mentionData;

  const idAvailable = sessionData
    ? props.props.checkChatID(parseInt(tabId), sessionData)
    : false;
  const convDatas = conversation(tabId, sessionData, idAvailable);

  useEffect(() => {
    setPageState({
      action: ActionT.SELECT,
      data: [convDatas.id],
    });
  }, [convDatas]);
  console.log(pageState, 'pagestateopen');
  useEffect(() => {
    if (pageState.action === ActionT.VIEW) {
      console.log(pageState, 'pagestateopen');
      setMultiRadioSelect(false);
      setPageState({
        action: ActionT.SELECT,
        data: [convDatas.id],
      });
    } else if (pageState.action === ActionT.MOVETRASH) {
      setMultiRadioSelect(true);
    } else if (pageState.action === ActionT.SELECT) {
      setMultiRadioSelect(false);
    }
  }, [pageState]);
  const botName = (id: string) => {
    return bots.filter((b) => b.id === id)[0]?.name;
  };
  const [multiRadioSelect, setMultiRadioSelect] = useState<boolean>(false);

  const multiSelect = (sessionData: Sessions[]) => {
    if (multiRadioSelect) {
      setMultiRadioSelect(false);
      setPageState(VIEW);
    } else {
      setMultiRadioSelect(true);
      const allIDs = sessionData.map((ses) => ses.id);
      setPageState({
        action: ActionT.MOVETRASH,
        data: allIDs,
      });
    }
    console.log('multiselst');
  };
  return (
    <div>
      {sessionData && sessionData.length > 0 ? (
        <section className="liveAgent_inbox_section">
          <div className="liveAgent_inbox_list">
            <div className="liveAt_inbox_title_container">
              <div
                className="liveAt_inbox_title flex"
                style={{ padding: '14px 12px 0 16px' }}
              >
                <input
                  type="radio"
                  onClick={() => multiSelect(sessionData)}
                  checked={multiRadioSelect}
                />
                <div className="liveAt_newest_dropdown">
                  <Input
                    className="liveAt_mail_filter"
                    type="text"
                    value={sortValue}
                    onClick={() => setSorterDropdown(true)}
                    style={{ position: 'relative', top: '3.8px' }}
                  />
                  <div
                    className="arrow_up_form"
                    // style={{marginTop:'-2px'}}
                    onClick={() => setSorterDropdown(true)}
                  >
                    <DownArrowCircle />
                  </div>
                </div>
                {sorterDropdown && (
                  <Downshift
                    isOpen={true}
                    onOuterClick={() => setSorterDropdown(false)}
                  >
                    {() => (
                      <div className="slack_buton_4 integration_dropdown">
                        <ul>
                          {sorter &&
                            sorter.map((data, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  setSortValue(data);
                                  setSorterDropdown(false);
                                  queryClient.invalidateQueries('trashStatus');
                                }}
                              >
                                {/* <div>
                                  <UserIconAnalyticsMenu />
                                </div> */}
                                <div className="liveAt_user_name">{data}</div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </Downshift>
                )}
              </div>
            </div>
            <div className="liveAt_inbox_search">
              <span className="search_click">
                <input
                  value={searchWord}
                  className="search_click"
                  type="search"
                  placeholder="Search user"
                  onChange={(e) => setSearchWord(e.target.value)}
                />
                {/* <span
                onClick={() => {
                    setSearchWord('')
                    setInputFocus(false)
                }}
            >
                <CloseIcon />
            </span> */}
                <SearchIcon />
              </span>
            </div>
            <div
              className="liveAt_inbox_mail_list_container"
              id="scrollableChatList"
              style={{ overflowY: 'scroll' }}
            >
              <InfiniteScroll
                dataLength={sessionData.length} //This is important field to render the next data
                next={fetchData}
                hasMore={totalCount > sessionData.length}
                loader={<></>}
                // endMessage={<p>No more data to load.</p>}
                scrollableTarget="scrollableChatList"
                // scrollThreshold={1}
                style={{ overflow: 'hidden' }}
                initialScrollY={0}
              >
                {sessionData.length > 0 &&
                  sessionData.map((data: Sessions, index: number) => (
                    <div
                      className={
                        convDatas.chat_id == data.chat_id
                          ? 'liveAt_inbox_mail_list flex active'
                          : 'liveAt_inbox_mail_list flex'
                      }
                    >
                      <div className="liveAt_inbox_mail_user">
                        <span
                          className={
                            pageState.action === ActionT.MOVETRASH &&
                            pageState.data.includes(data.id)
                              ? 'hide'
                              : 'liveAt_user_icon'
                          }
                        >
                          <UserIconAnalyticsMenu />
                        </span>
                        <span
                          className={
                            pageState.action === ActionT.MOVETRASH &&
                            pageState.data.includes(data.id)
                              ? 'radioShow liveAt_user_radio_btn'
                              : 'liveAt_user_radio_btn'
                          }
                        >
                          <label>
                            <input
                              type="radio"
                              className="option-input radio"
                              onClick={() => {
                                props.props.selectConvo(data.id);
                              }}
                              checked={
                                pageState.action === ActionT.MOVETRASH &&
                                pageState.data.includes(data.id)
                              }
                              // onChange={() => {}}
                              // onChange={() => setPageState(VIEW)}
                            />
                          </label>
                        </span>
                      </div>
                      <div
                        className="liveAt_inbox_mail_box flex"
                        onClick={() => {
                          props.props.linkSelect(
                            props.props.selectedBotID,
                            data.chat_id,
                            'mention-sessions'
                          );
                          setPageState({
                            action: ActionT.SELECT,
                            data: [data.id],
                          });
                        }}
                      >
                        <h5>{data.user.email}</h5>
                        <div className="flex">
                          <p>Connected with:</p>
                          <span>
                            <div>
                              <UserIconAnalyticsMenu />
                            </div>
                            <h6>
                              {data.assigned_agent
                                ? data.assigned_agent.first_name +
                                  data.assigned_agent.last_name
                                : 'Unassigned'}
                            </h6>
                          </span>
                        </div>
                        <p>
                          <AssistantMd
                            mdText={convertToMDText(data.custom_message)}
                          />
                        </p>
                        <div className="liveAt_inbox_mail_status flex">
                          <p>{botName(data.chat_source.id)}</p>
                          {/* <p>{data.session_status}</p> */}
                        </div>
                      </div>
                      <div className="liveAt_inbox_mail_icon flex">
                        <span>
                          {' '}
                          #
                          {data.chat_id < 10
                            ? '00' + data.chat_id
                            : data.chat_id < 1000
                            ? '0' + data.chat_id
                            : data.chat_id}
                        </span>
                        {platformIcon(data.platform)}
                        <p>{`${formatAMPM(data.start_time + 'Z')}`} </p>
                        <p> {`${formatDateInCard(data.start_time + 'Z')}`}</p>
                      </div>
                    </div>
                  ))}
                {/* {loading && <div>Loading...</div>} */}
              </InfiniteScroll>
            </div>
          </div>
          <ChatUserInfo
            data={convDatas.id}
            workspace={props.props.workspacename}
            botName={botName}
            closeConvo={props.props.closeConvo}
          />
        </section>
      ) : (
        <EmptyContainer searchWord={searchWord} setSearchWord={setSearchWord} />
      )}
    </div>
  );
}
