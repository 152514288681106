import React, { useEffect, useState } from 'react';
import {
  EmptyDiv,
  Header,
  HeaderLeft,
  InputContainer,
  Paragraph,
  PopupContainer,
  PopupScrollHeight,
  PopupWrapper,
  SmallButton,
  Wrapper,
} from '@/common/styled/Dialog.Canvas.Nodes.Dumb';
import { HeaderRight } from '@/common/styled/Subscription.Xtra.Dumb';
import { Input, InputContent } from '@/common/styled/Workflow.Analytics.Dumb';
import { Button, DropdownLiSpan } from '@/common/styled/Dialog.BotDetails.Dumb';
import {
  ButtonContainer,
  CancelButton,
  DropdownLi,
  DropdownUl,
  DropdownUlWrapper,
} from '@/common/styled/Workflow.Dumb';
import styled from 'styled-components/macro';
import {
  AddIcon,
  CheckColorIcon,
  CloseColorIcon,
  CloseIcon,
  CloseIconWithCircle,
  DropdownWithCircle,
  EditIcon,
  EyeCloseIcon,
  EyeOpenIcon,
  InfoIcon,
  MinusIcon,
  TickIcon,
} from '@/common/Icons/Icons';
import { LoadingSvgImage } from '@/common/Icons/LoadingSvgImage';
import {
  additionalPropertiesT,
  EmailValidT,
  initialState,
  IsValidURL,
  KnowledgeBase,
  Resp,
  sharepointCred,
  sharepointCredData,
  SharepointPopupT,
  StateT,
  UpdateResponse,
} from './MockFiles';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { getJson, knowledgeApiUrl } from '@/common/utils/api-utils';
import * as R from 'ramda';
import { Loader } from '@/common/components/Loader';
import {
  useAuthSaveCred,
  useAuthUpdateCred,
  useSaveConnection,
  useSaveCred,
  useUpdateConnection,
  useUpdateCred,
} from './KnowledgeApis';
import { DropDown } from '../Common/Effective';
import Downshift from 'downshift';
import { isValidEmail } from '@/ManageUsers/v2/ManageUsers';
import ImgLoader from '@/common/images/loading.svg';
import Tooltip from 'react-tooltip-lite';
import _ from 'lodash';
// import { useGetKb } from './KnowledgeApis';

const BgWrapper = styled.div`
  width: 100%;
  float: left;
  padding: 20px 20px 0px;
  background: #f4f4f4;
  margin-bottom: 10px;

  .sharepoint_dropdown{
    ${DropdownLi}{
      :last-child{
        padding-top: 2px; 
      }
    }
  }
`;
const OptionalInput = styled.div`
  width: 100%;
  float: left;
  position: relative;

  .target.customTip {
    width: 9px;
    height: 9px;
    pointer-events: all;
    cursor: pointer;
    left: 3px;
    svg {
      width: 9px;
      height: 9px;
      pointer-events: all;
      cursor: pointer;
    }
  }
`;

export default function SharepointPopup({
  pageState,
  PageStateUpdate,
  selectedID,
  workspace,
  Connection,
  exCatArray,
}: SharepointPopupT) {
  const [state, setState] = useState<StateT>(initialState);
  const [status, setStatus] = useState<string>('');
  const [popUpState, setPopupState] = useState<{
    selectedConnection: UpdateResponse[];
    clonedConnection: UpdateResponse[];
  }>({
    selectedConnection: [],
    clonedConnection: [],
  });
  const [authType, setAuthType] = useState<string>('Select');
  const [authChange, setAuthChange] = useState<boolean>(false);
  const [authDomain, setAuthDomain] = useState<boolean>(false);

  const [showAdditionalProp, setShowAdditionalProp] = useState<boolean>(false);
  const initialFolderValue = {
    name: '',
    value: '',
    description: '',
    valueDesc: '',
  };
  const [folderType, setFolderType] = useState<{
    name: string;
    value: string;
    description: string;
    valueDesc: string;
  }>(initialFolderValue);
  
  const initialAdditioanlProp = {
    pageTitleFilterRegEx: [],
    inclusionFilePath: [],
    inclusionFileTypePatterns: [],
    inclusionFileNamePatterns: [],
  };
  
  const [folderDropDown, setFolderDropDown] = useState<boolean>(false);
  const [number, setNumber] = useState<number>(-1);
  interface additionalObjT {
    name: string;
    bName: string;
    value: string;
    description: string;
  }
  const initialObj = [{ name: '', bName: '', value: '', description: '' }];
  const [additionalObj, setAdditionalObj] =
    useState<additionalObjT[]>(initialObj);
  const [clonedInfo, setClonedInfo] = useState<additionalObjT[]>(initialObj);

  console.log(additionalObj, 'setAdditionalObj');
  const FolderDropValue = [
    {
      name: 'File Path (Folder)',
      value: 'inclusionFilePath',
      description: 'Provide the folder name',
      valueDesc: 'You can give a folder inside the folder path separated by /',
    },
    {
      name: 'File Name',
      value: 'inclusionFileNamePatterns',
      description: 'Provide name of the file.',
      valueDesc: 'Provide name of the file.',
    },
    {
      name: 'File Type',
      value: 'inclusionFileTypePatterns',
      description: 'Provide file type.',
      valueDesc:
        'You can add one file type at a time. To add multiple file type. Add, another Filter.',
    },
    {
      name: 'Page Name',
      value: 'pageTitleFilterRegEx',
      description: 'Provide the page name',
      valueDesc: 'Page name must include the extension. Example: Home.aspx',
    },
  ];
  const setValue = (index: number, folderInfo: any, value: string) => {
    const list = [...additionalObj];
    list[index] = {
      name: folderInfo.name,
      bName: folderInfo.value,
      value: value,
      description: folderInfo.valueDesc,
    };
    setAdditionalObj(list);
  };

  const setName = (index: number, folderInfo: any) => {
    console.log('working', 'setAdditionalObj');
    const list = [...additionalObj];
    list[index] = {
      name: folderInfo.name,
      bName: folderInfo.value,
      description: folderInfo.valueDesc,
      value: '',
    };
    setAdditionalObj(list);
  };

  const removeSubset = (index: number) => {
    const rows = [...additionalObj];
    rows.splice(index, 1);
    rows.length === 0 ? setAdditionalObj(initialObj) : setAdditionalObj(rows);
  };

  const addMoreObj = () => {
    scroollUp();
    setAdditionalObj([...additionalObj, ...initialObj]);
  };
  const auth = ['Basic', 'With OAuth'];

  const checkingName: KnowledgeBase[] | [] =
    exCatArray && exCatArray.length > 0
      ? exCatArray.filter(
          (arr) => arr.name.toLowerCase() === state.kBName.toLowerCase()
        )
      : [];

  const getConnection = () => {
    getJson(knowledgeApiUrl(workspace, `knowledge-base/v2/${selectedID}`)).then(
      (data) => {
        if (data.type === 'success') {
          const output = [data.output];
          setPopupState({
            ...popUpState,
            selectedConnection: JSON.parse(JSON.stringify(output)),
            clonedConnection: JSON.parse(JSON.stringify(output)),
          });
        }
      }
    );
  };

  useEffect(() => {
    if (selectedID !== '' && pageState === 'edit') {
      getConnection();
    }
  }, [pageState]);

  useEffect(() => {
    if (popUpState.selectedConnection.length > 0) {
      const addit = popUpState.selectedConnection[0].additionalProperties;
      const fileName: any[] =
        addit.inclusionFileNamePatterns.length > 0
          ? addit.inclusionFileNamePatterns.map((value) => ({
              name: 'File Name',
              value: value,
              bName: 'inclusionFileNamePatterns',
              description: 'Provide name of the file.',
            }))
          : [];

      const filePath =
        addit.inclusionFilePath.length > 0
          ? addit.inclusionFilePath.map((value) => ({
              name: 'File Path',
              value: value,
              bName: 'inclusionFilePath',
              description:
                'You can give a folder inside the folder path separated by /',
            }))
          : [];

      const fileType =
        addit.inclusionFileTypePatterns.length > 0
          ? addit.inclusionFileTypePatterns.map((value) => ({
              name: 'File Type',
              value: value,
              bName: 'inclusionFileTypePatterns',
              description:
                'You can add one file type at a time. To add multiple file type. Add, another Filter.',
            }))
          : [];
      const path =
        addit.pageTitleFilterRegEx.length > 0
          ? addit.pageTitleFilterRegEx.map((value) => ({
              name: 'Path',
              value: value,
              bName: 'pageTitleFilterRegEx',
              description:
                'Page name must include the extension. Example: Home.aspx',
            }))
          : [];
      // newObj.push({name:'',value:''})
      const newObj = fileName.concat(filePath, fileType, path);
      setClonedInfo(newObj.length > 0 ? newObj : initialObj);
      setAdditionalObj(newObj.length > 0 ? newObj : initialObj);
      setShowAdditionalProp(newObj.length > 0 ? true : false);
      console.log(fileType, addit, 'newObj');
    }
  }, [popUpState]);

  const handleConnectionEdit = (
    value: any,
    id: string,
    property: string,
    obj?: string
  ) => {
    const index = popUpState.selectedConnection.findIndex((el) => el.id === id);
    const data = [...popUpState.selectedConnection];
    if (obj === 'credential') {
      data[index].credential[property] = value;
    } else if (obj === 'repository') {
      data[index].repository[property] = value;
    } else {
      data[index][property] = value;
    }
    setPopupState({ ...popUpState, selectedConnection: data });
  };
  const [updateCred, setUpdateCred] = useState<boolean>(false);

  const handleRepositoryChange = (
    index: number,
    event: { target: { value: string } },
    id: string,
    prop: string
  ) => {
    const { value } = event.target;
    const rep = popUpState.selectedConnection[0].repository;
    const list = [...rep.siteUrls];
    list[index] = value;
    handleConnectionEdit(list, id, prop, 'repository');
  };
  const removeSiteUrl = (index: number, id: string) => {
    const rows = [...popUpState.selectedConnection[0].repository.siteUrls];
    rows.splice(index, 1);
    rows.length === 0
      ? handleConnectionEdit([''], id, 'siteUrls', 'repository')
      : handleConnectionEdit(rows, id, 'siteUrls', 'repository');
  };
  const addSiteUrl = (id: string) => {
    const rep = popUpState.selectedConnection[0].repository;
    const list = [...rep.siteUrls, ''];
    handleConnectionEdit(list, id, 'siteUrls', 'repository');
  };
  const accountUpdate = () => {
    const selectedaccount = popUpState.selectedConnection[0].credential;
    const clonedaccount = popUpState.clonedConnection[0].credential;
    return (
      JSON.stringify(selectedaccount) === JSON.stringify(clonedaccount) &&
      state.updatePassWord === ''
    );
  };

  const addInputUrl = () => {
    setInputUrl([...inputUrl, '']);
  };

  const removeinputUrl = (index: number) => {
    const rows = [...inputUrl];
    rows.splice(index, 1);
    rows.length === 0 ? setInputUrl(['']) : setInputUrl(rows);
  };
  const changeUrl = (index: number, event: { target: { value: string } }) => {
    const { value } = event.target;
    const list = [...inputUrl];
    list[index] = value;
    setInputUrl(list);
  };

  const { saveCredMutate } = useSaveCred(
    (response: Resp) => {
      setStatus('');
      setState({
        ...state,
        connectionID: response.data.output.id,
        showConnectError: false,
        connectLoader: false,
      });
      setClonedCredData(JSON.parse(JSON.stringify(credData)));
    },
    () => {
      setStatus('apiConnectError');
      setState({
        ...state,
        connectLoader: false,
      });
    }
  );
  const { saveAuthCredMutate } = useAuthSaveCred(
    (response: Resp) => {
      const receiveMessage = (event: any) => {
        if (event.origin === 'http://localhost:3000') return;
        // window.removeEventListener('message', receiveMessage, false);
        if (
          event.data.from === 'knowledgebase' &&
          event.data.status === 'success'
        ) {
          setAuthDomain(true);
          setStatus('');
          window.removeEventListener('message', receiveMessage, false);
          childWindow.close();
        } else {
          setStatus('Authentication Error');
        }
        // window.removeEventListener('message', receiveMessage, false);
        // childWindow.close();
      };

      const childWindow: any = window.open(
        response.data.output.redirectURI,
        'authwind',
        'width=550,height=500,0,status=0,'
      );

      window.addEventListener('message', receiveMessage, false);
      setStatus('');
      setState({
        ...state,
        connectionID: response.data.output.id,
        redirectURI: response.data.output.redirectURI,
        showConnectError: false,
        connectLoader: false,
      });
      setClonedCredData(JSON.parse(JSON.stringify(credData)));
    },
    () => {
      setStatus('apiConnectError');
      setState({
        ...state,
        connectLoader: false,
      });
    }
  );

  const { updateAuthCredMutate } = useAuthUpdateCred(
    (response: Resp) => {
      const receiveMessage = (event: any) => {
        if (event.origin === 'http://localhost:3000') return;
        // window.removeEventListener('message', receiveMessage, false);
        if (
          event.data.from === 'knowledgebase' &&
          event.data.status === 'success'
        ) {
          setAuthDomain(true);
          // setStatus('');
          window.removeEventListener('message', receiveMessage, false);
          getConnection();
          childWindow.close();
        } else {
          // setStatus('Authentication Error');
          setTimeout(() => {
            window.removeEventListener('message', receiveMessage, false);
            getConnection();
          }, 5000);
        }
        // window.removeEventListener('message', receiveMessage, false);
        // childWindow.close();
      };

      const childWindow: any = window.open(
        response.data.output.redirectURI,
        'authwind',
        'width=550,height=500,0,status=0,'
      );

      window.addEventListener('message', receiveMessage, false);
      setUpdateCred(false);
      setState({
        ...state,
        updatePassWord: '',
        connectLoader: false,
      });
      setStatus('');
    },
    () => {
      setStatus('apiConnectError');
      setState({
        ...state,
        connectLoader: false,
      });
    }
  );
  const { updateCredMutate } = useUpdateCred(
    () => {
      setUpdateCred(false);
      setState({
        ...state,
        updatePassWord: '',
        connectLoader: false,
      });
      setStatus('');
    },
    () => {
      setStatus('apiConnectError');
      setState({
        ...state,
        connectLoader: false,
      });
    }
  );
  //saving credential data to get connectionID //create credential
  const onCredSave = () => {
    const credBody = {
      connector: 'SHAREPOINTV2',
      authType: 'OAuth2_RefreshToken',
      input: {
        clientId: credData.clientId,
        tenantId: credData.tenantId,
        clientSecret: credData.clientSecret,
        refreshToken: '',
        authType: 'OAuth2_RefreshToken',
        result: false,
      },
    };
    saveAuthCredMutate({ workspace, credBody });

    // if (!emailValidation.valid) {
    //   setStatus('');
    //   setState((prevState) => {
    //     return {
    //       ...state,
    //       showConnectError: true,
    //     };
    //   });
    // } else {
    //   setState({
    //     ...state,
    //     connectLoader: true,
    //   });
    //   saveCredMutate({ workspace, credBody});
    // }
  };
  const onCredBasicSave = () => {
    const credBody = {
      connector: 'SHAREPOINTV2',
      authType: 'basic',
      input: {
        userName: credData.userName,
        password: credData.password,
        authType: 'basic',
      },
    };
    if (!emailValidation.valid) {
      setStatus('');
      setState((prevState) => {
        return {
          ...state,
          showConnectError: true,
        };
      });
    } else {
      setState({
        ...state,
        connectLoader: true,
      });
      saveCredMutate({ workspace, credBody });
    }
  };
  const checkingValidUrl =
    popUpState.selectedConnection[0]?.repository.siteUrls.map((url) =>
      IsValidURL(url)
    );

  // updating credential data
  const onCredUpdateSave = (id: string) => {
    // const newData = {
    //   userName: popUpState.selectedConnection[0].credential.userName,
    //   password: state.updatePassWord,
    // };
    const newData = popUpState.selectedConnection[0].credential;
    const updateCredBody = {
      connector: 'SHAREPOINTV2',
      authType: 'OAuth2_RefreshToken',
      input: {
        clientId: newData.clientId,
        tenantId: newData.tenantId,
        clientSecret: newData.clientSecret,
        refreshToken: '',
        authType: 'OAuth2_RefreshToken',
        result: false,
      },
      // connector: 'SHAREPOINTV2',
      // authType: 'basic',
      // input: {
      //   userName: newData.userName,
      //   password: newData.password,
      //   authType: 'basic',
      // },
    };
    const updatedCloned = popUpState.clonedConnection.map((yes) =>
      Object.assign(yes, {
        credential: newData,
      })
    );
    setState({
      ...state,
      connectLoader: true,
    });
    updateAuthCredMutate({ workspace, id, updateCredBody });
    setPopupState({
      ...popUpState,
      clonedConnection: JSON.parse(JSON.stringify(updatedCloned)),
    });
  };

  const onCredBasicUpdateSave = (id: string) => {
    const newData = {
      userName: popUpState.selectedConnection[0].credential.userName,
      password: state.updatePassWord,
    };
    const updateCredBody = {
      connector: 'SHAREPOINTV2',
      authType: 'basic',
      input: {
        userName: newData.userName,
        password: newData.password,
        authType: 'basic',
      },
    };
    const updatedCloned = popUpState.clonedConnection.map((yes) =>
      Object.assign(yes, {
        credential: newData,
      })
    );
    setState({
      ...state,
      connectLoader: true,
    });
    updateCredMutate({ workspace, id, updateCredBody });
    setPopupState({
      ...popUpState,
      clonedConnection: JSON.parse(JSON.stringify(updatedCloned)),
    });
  };
  //siteURL management

  const [inputUrl, setInputUrl] = useState<string[]>(['']);

  const inputURLValid = inputUrl.map((url) => IsValidURL(url));
  const onCancel = () => {
    PageStateUpdate();
    setInputUrl(['']);
    setAdditionalObj(initialObj)
    // setPageInput('');
    // setAddLoader(false);
    setShowAdditionalProp(false);
    setUpdateCred(false);
    setState(initialState);
    setStatus('');
    setPopupState({
      selectedConnection: [],
      clonedConnection: [],
    });
    setEmailValidation({
      valid: true,
      message: null,
    });
    setAuthType('Select');
    setAuthChange(false);
    setAuthDomain(false);
  };
  //cred
  const [credData, setCredData] = useState<sharepointCred>(sharepointCredData);
  const [clonedCredData, setClonedCredData] =
    useState<sharepointCred>(sharepointCredData);

  const [emailValidation, setEmailValidation] = useState<EmailValidT>({
    valid: true,
    message: null,
  });

  const needUpdate = () => {
    const omittedAccount = R.omit(
      ['credential'],
      popUpState.selectedConnection[0]
    );
    const omittedClonedAccount = R.omit(
      ['credential'],
      popUpState.clonedConnection[0]
    );

    const eRadditionalObj = additionalObj.filter((add) => add.value !== '');
    const eRclonedInfo = clonedInfo.filter((add) => add.value !== '');

    console.log(
      JSON.stringify(omittedAccount) === JSON.stringify(omittedClonedAccount),
      _.isEqual(eRclonedInfo, eRadditionalObj),
      'chcksfsd'
    );

    return (
      JSON.stringify(omittedAccount) === JSON.stringify(omittedClonedAccount) &&
      _.isEqual(eRclonedInfo, eRadditionalObj)
    );
  };

  const checkingDuplicate = (arr: string[]) => {
    return arr.length === new Set(arr).size;
  };
  const { saveConmutate } = useSaveConnection(
    () => onCancel(),
    () => {
      setStatus('apiError');
    }
  );
  const { updateConmutate } = useUpdateConnection(
    () => onCancel(),
    () => {
      setStatus('apiError');
    }
  );

  const saveBasicConnection = () => {

    const addedProperties = uniqueadditionalObj(additionalObj)

    const connectionData = {
      name: state.kBName,
      category: 'SHAREPOINT_ONLINE',
      template: {
        type: 'SHAREPOINTV2',
        syncMode: 'FORCED_FULL_CRAWL',
        authType: 'basic',
        credentialDomainRefId: state.connectionID,
        repository: {
          domain: state.domainName,
          siteUrls: inputUrl,
        },
        additionalProperties: addedProperties,
      },
    };
    if (
      inputURLValid.includes(false) ||
      checkingName.length > 0 ||
      !checkingDuplicate(inputUrl)
    ) {
      setStatus('error');
      // setState({
      //   ...state,
      //   // error:true,
      //   loader: false,
      //   showError: true,
      // });
    } else {
      setStatus('loader');
      setState({
        ...state,
        disableSave: true,
      });

      saveConmutate({ workspace, connectionData });
    }
  };
  const saveConnection = () => {
    const addedProperties = uniqueadditionalObj(additionalObj)

    const connectionData = {
      name: state.kBName,
      category: 'SHAREPOINT_ONLINE',
      template: {
        type: 'SHAREPOINTV2',
        syncMode: 'FORCED_FULL_CRAWL',
        authType: 'oauth',
        credentialDomainRefId: state.connectionID,
        repository: {
          tenantId: credData.tenantId,
          domain: state.domainName,
          siteUrls: inputUrl,
        },
        additionalProperties: addedProperties,
      },
    };
    if (
      inputURLValid.includes(false) ||
      checkingName.length > 0 ||
      !checkingDuplicate(inputUrl)
    ) {
      setStatus('error');
      // setState({
      //   ...state,
      //   // error:true,
      //   loader: false,
      //   showError: true,
      // });
    } else {
      setStatus('loader');
      setState({
        ...state,
        disableSave: true,
      });

      saveConmutate({ workspace, connectionData });
    }
  };

  const checkingUpdateName = (id: string) => {
    const array =
      exCatArray.length > 0 ? exCatArray.filter((a) => a.id !== id) : [];
    return array.length > 0
      ? array.filter(
          (arr) =>
            arr.name.toLowerCase() ===
            popUpState.selectedConnection[0]?.name.toLowerCase()
        )
      : [];
  };

  const uniqueadditionalObj = (additionalObj:additionalObjT[]) => {
    const uniqueObject = {};

    const keys = Object.keys(initialAdditioanlProp)

    additionalObj.forEach((obj) => {
      if (!uniqueObject[obj.bName]) {
        uniqueObject[obj.bName] = [];
      }
      uniqueObject[obj.bName].push(obj.value);
    });

    keys.forEach(key => {
      if (!uniqueObject.hasOwnProperty(key)) {
        uniqueObject[key] = [];
      }
    });
    console.log(uniqueObject,'uniqueObject')
    return uniqueObject
  };
  console.log(uniqueadditionalObj(additionalObj),"additionalObj")
  const updateConnection = (id: string) => {
    const data = popUpState.selectedConnection[0];
    const filteredSiteUrls = data.repository.siteUrls.filter((f) => f !== '');

    const addedProperties = uniqueadditionalObj(additionalObj)

    if (Object.keys(data.credential).includes('userName')) {
      const connectionData = {
        name: data.name,
        category: 'SHAREPOINT_ONLINE',
        template: {
          type: 'SHAREPOINTV2',
          authType: 'basic',
          syncMode: 'FORCED_FULL_CRAWL',
          credentialDomainRefId: data.credentialId,
          repository: {
            domain: data.repository.domain,
            siteUrls: filteredSiteUrls,
          },
          additionalProperties:addedProperties,
        },
      };
      if (
        checkingValidUrl.includes(false) ||
        !checkingDuplicate(filteredSiteUrls) ||
        checkingUpdateName(id).length > 0
      ) {
        setStatus('error');
        // setState({
        //   ...state,
        //   // error:true,
        //   loader: false,
        //   showError: true,
        // });
      } else {
        setStatus('loader');
        setState({
          ...state,
          disableSave: true,
        });
        updateConmutate({ workspace, connectionData, id });
      }
    } else {
      const connectionData = {
        name: data.name,
        category: 'SHAREPOINT_ONLINE',
        template: {
          type: 'SHAREPOINTV2',
          authType: 'oauth',
          syncMode: 'FORCED_FULL_CRAWL',
          credentialDomainRefId: data.credentialId,
          repository: {
            tenantId: data.credential.tenantId,
            domain: data.repository.domain,
            siteUrls: filteredSiteUrls,
          },
          additionalProperties: addedProperties,
        },
      };
      if (
        checkingValidUrl.includes(false) ||
        !checkingDuplicate(filteredSiteUrls) ||
        checkingUpdateName(id).length > 0
      ) {
        setStatus('error');
        // setState({
        //   ...state,
        //   // error:true,
        //   loader: false,
        //   showError: true,
        // });
      } else {
        setStatus('loader');
        setState({
          ...state,
          disableSave: true,
        });
        updateConmutate({ workspace, connectionData, id });
      }
    }
  };

  useEffect(() => {
    scroollUp();
  }, [folderType, showAdditionalProp]);

  const scroollUp = () => {
    const element = document.getElementById('scrollIn');
    element?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      {pageState === 'add' && (
        <Wrapper>
          <PopupWrapper>
            <PopupContainer>
              <Header>
                <HeaderLeft minWidth={300}>
                  MS SharePoint Configuration
                </HeaderLeft>
                <HeaderRight></HeaderRight>
                <span
                  onClick={() => {
                    onCancel();
                  }}
                  className="slack_close_btn"
                >
                  <CloseIcon />
                </span>
              </Header>
              <Paragraph>Create a new connection.</Paragraph>
              <PopupScrollHeight>
                <EmptyDiv>
                  <BgWrapper>
                    <InputContainer>
                      <Input
                        type="text"
                        onChange={(e: { target: { value: string } }) =>
                          setState({
                            ...state,
                            kBName: e.target.value,
                          })
                        }
                      />
                      <InputContent>Connection name</InputContent>
                    </InputContainer>
                    {checkingName.length > 0 && status === 'error' && (
                      <p className="valid_url_alert">
                        Connection Name has to be unique
                      </p>
                      // </div>
                    )}
                    <InputContainer className="sharepoint_dropdown">
                      <Input
                        type="text"
                        value={authType}
                        // onChange={(e: any) => {}}
                        onClick={() => setAuthChange(true)}
                        style={{ paddingLeft: 10 }}
                      />
                      <InputContent>Select authentication type</InputContent>
                      <CancelButton
                        onClick={() => setAuthChange(true)}
                        style={{ display: 'block', top: '14px' }}
                      >
                        <DropdownWithCircle />
                      </CancelButton>
                      {authChange && (
                        <Downshift
                          isOpen={authChange}
                          onOuterClick={() => setAuthChange(false)}
                        >
                          {() => (
                            <div>
                              <DropdownUl style={{ marginTop: '48px' }}>
                                {auth.map((a) => (
                                  <DropdownLi
                                    onClick={() => {
                                      setAuthChange(false);
                                      setAuthType(a);
                                      // props.changeDateFilter('today' as DateFilterT)
                                    }}
                                  >
                                    <DropdownLiSpan>{a}</DropdownLiSpan>{' '}
                                  </DropdownLi>
                                ))}
                              </DropdownUl>
                            </div>
                          )}
                        </Downshift>
                      )}
                    </InputContainer>
                  </BgWrapper>
                  {authType === 'With OAuth' && (
                    <BgWrapper>
                      <div className="opations_label">
                        <Header style={{ fontSize: 14, marginBottom: 10 }}>
                          Connect Account{' '}
                        </Header>
                      </div>
                      <OptionalInput>
                        <InputContainer>
                          <Input
                            type="text"
                            onChange={(e: { target: { value: string } }) => {
                              setCredData({
                                ...credData,
                                clientId: e.target.value,
                              });
                            }}
                          />
                          <InputContent>Client ID</InputContent>
                        </InputContainer>
                      </OptionalInput>
                      <OptionalInput>
                        <InputContainer>
                          <Input
                            type="text"
                            onChange={(e: { target: { value: string } }) => {
                              setCredData({
                                ...credData,
                                clientSecret: e.target.value,
                              });
                            }}
                          />
                          <InputContent>Client Secret</InputContent>
                        </InputContainer>
                      </OptionalInput>
                      <OptionalInput>
                        <InputContainer>
                          <Input
                            type="text"
                            onChange={(e: { target: { value: string } }) => {
                              setCredData({
                                ...credData,
                                tenantId: e.target.value,
                              });
                            }}
                          />
                          <InputContent>Tenant ID</InputContent>
                        </InputContainer>
                      </OptionalInput>
                      <ButtonContainer
                        className="knowledge_ms_btn"
                        style={{ padding: '10px 0' }}
                      >
                        <Button
                          type="button"
                          style={{ marginRight: 20 }}
                          className={
                            state.connectionID === ''
                              ? credData.clientId !== '' &&
                                credData.clientSecret !== '' &&
                                credData.tenantId !== ''
                                ? ''
                                : 'editor_btn_disabled'
                              : JSON.stringify(clonedCredData) !==
                                  JSON.stringify(credData) &&
                                credData.clientId !== '' &&
                                credData.clientSecret !== '' &&
                                credData.tenantId !== ''
                              ? ''
                              : 'editor_btn_disabled'
                          }
                          onClick={() => onCredSave()}
                        >
                          <label>Save</label>
                        </Button>
                        {state.connectLoader && (
                          <span className="connect_account_loading automate_connect_account_loading">
                            <img src={LoadingSvgImage} alt="" />
                          </span>
                        )}
                        {status === 'apiConnectError' && (
                          <span className="connect_account_failed">
                            <div className="error_input_show error_input_show_add teams_page_alert">
                              <WarningAlertIcon />
                              <span className="error_menu_hvr">
                                Something went wrong
                              </span>
                            </div>
                          </span>
                        )}
                        {status === 'Authentication Error' && (
                          <span className="connect_account_failed">
                            <div className="error_input_show error_input_show_add teams_page_alert">
                              <WarningAlertIcon />
                              <span className="error_menu_hvr">
                                Authentication Error
                              </span>
                            </div>
                          </span>
                        )}
                        <Button
                          primary
                          type="button"
                          onClick={() => {
                            onCancel();
                          }}
                        >
                          <label> Cancel</label>
                        </Button>
                      </ButtonContainer>
                    </BgWrapper>
                  )}
                  {authType === 'Basic' && (
                    <BgWrapper>
                      <div className="opations_label">
                        <Header style={{ fontSize: 14, marginBottom: 10 }}>
                          Connect Account{' '}
                        </Header>
                      </div>
                      <OptionalInput>
                        <InputContainer>
                          <Input
                            type="email"
                            onChange={(e: { target: { value: string } }) => {
                              const validEmail = isValidEmail(e.target.value);
                              setEmailValidation({
                                valid: validEmail,
                                message: validEmail ? '' : 'Email is invalid',
                              });
                              setCredData({
                                ...credData,
                                userName: e.target.value,
                              });
                            }}
                          />
                          <InputContent>Provide login email</InputContent>
                          {!emailValidation.valid && state.showConnectError && (
                            <p className="error_msg_invalid">
                              {emailValidation.message}
                            </p>
                          )}
                        </InputContainer>
                      </OptionalInput>
                      <OptionalInput>
                        <InputContainer style={{ marginBottom: 0 }}>
                          <Input
                            type={!state.eyeIcon ? 'password' : 'text'}
                            onChange={(e: { target: { value: any } }) =>
                              setCredData({
                                ...credData,
                                password: e.target.value,
                              })
                            }
                          />
                          <InputContent>Provide password</InputContent>
                        </InputContainer>
                        <CancelButton
                          className="d-flex"
                          onClick={() =>
                            setState({
                              ...state,
                              eyeIcon: !state.eyeIcon,
                            })
                          }
                          style={{ top: '12px' }}
                        >
                          {state.eyeIcon ? <EyeOpenIcon /> : <EyeCloseIcon />}
                        </CancelButton>
                      </OptionalInput>
                      <ButtonContainer
                        className="knowledge_ms_btn"
                        style={{ padding: '10px 0' }}
                      >
                        <Button
                          type="button"
                          style={{ marginRight: 20 }}
                          className={
                            state.connectionID === ''
                              ? credData.userName !== '' &&
                                credData.password !== ''
                                ? ''
                                : 'editor_btn_disabled'
                              : JSON.stringify(clonedCredData) !==
                                  JSON.stringify(credData) &&
                                credData.userName !== '' &&
                                credData.password !== ''
                              ? ''
                              : 'editor_btn_disabled'
                          }
                          onClick={() => onCredBasicSave()}
                        >
                          <label>Save</label>
                        </Button>
                        {state.connectLoader && (
                          <span className="connect_account_loading automate_connect_account_loading">
                            <img src={LoadingSvgImage} />
                          </span>
                        )}
                        {status === 'apiConnectError' && (
                          <span className="connect_account_failed">
                            <div className="error_input_show error_input_show_add teams_page_alert">
                              <WarningAlertIcon />
                              <span className="error_menu_hvr">
                                Something went wrong
                              </span>
                            </div>
                          </span>
                        )}
                        <Button
                          primary
                          type="button"
                          onClick={() => {
                            onCancel();
                          }}
                        >
                          <label> Cancel</label>
                        </Button>
                      </ButtonContainer>
                    </BgWrapper>
                  )}
                  {authType === 'Basic' && state.connectionID !== '' && (
                    <>
                      <BgWrapper>
                        <div className="opations_label">
                          <Header style={{ fontSize: 14, marginBottom: 10 }}>
                            Domain{' '}
                          </Header>
                        </div>
                        <OptionalInput>
                          <InputContainer>
                            <Input
                              type="text"
                              onChange={(e: { target: { value: string } }) =>
                                setState({
                                  ...state,
                                  domainName: e.target.value,
                                })
                              }
                            />
                            <InputContent>Domain name</InputContent>
                          </InputContainer>
                        </OptionalInput>
                        <OptionalInput>
                          {inputUrl &&
                            inputUrl.map((data, index) => (
                              <>
                                <InputContainer
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Input
                                    type="url"
                                    onChange={(evnt: {
                                      target: { value: string };
                                    }) => changeUrl(index, evnt)}
                                    value={data}
                                    name="url"
                                  />
                                  <InputContent>
                                    Site URL {index + 1}
                                  </InputContent>
                                  <span
                                    className="kbclose_Icon"
                                    onClick={() => removeinputUrl(index)}
                                  >
                                    <CloseIcon />
                                  </span>
                                  {/* </div> */}
                                </InputContainer>
                                {!IsValidURL(data) && status === 'error' && (
                                  <p className="valid_url_alert">
                                    Provide a Valid URL
                                  </p>
                                )}
                              </>
                            ))}
                        </OptionalInput>
                        <SmallButton
                          primary
                          style={{ marginBottom: 20 }}
                          onClick={addInputUrl}
                        >
                          <span>
                            <AddIcon />
                          </span>
                          <label>Add More</label>
                        </SmallButton>
                      </BgWrapper>
                      <ButtonContainer className="footer_button_fixed knowledge_ms_btn">
                        <div className="popup_footer_button_container">
                          <Button
                            type="button"
                            className={
                              state.kBName !== '' &&
                              state.connectionID !== '' &&
                              state.domainName !== '' &&
                              inputUrl.length > 0 &&
                              inputUrl[0].length > 0 &&
                              (status !== 'error' ||
                                !inputURLValid.includes(false)) &&
                              !state.disableSave
                                ? ''
                                : 'editor_btn_disabled'
                            }
                            onClick={() => {
                              saveBasicConnection();
                            }}
                          >
                            <label> Save </label>
                          </Button>
                          {status === 'loader' && (
                            <span className="connect_account_loading automate_connect_account_loading">
                              <img src={LoadingSvgImage} alt="" />
                            </span>
                          )}
                          {status === 'error' && (
                            <span
                              className={
                                checkingDuplicate(inputUrl) &&
                                !inputURLValid.includes(false) &&
                                checkingName.length === 0
                                  ? 'connect_account_failed hide'
                                  : 'connect_account_failed'
                              }
                            >
                              <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                  Something went wrong
                                </span>
                              </div>
                            </span>
                          )}
                          {status === 'apiError' && (
                            <span className="connect_account_failed">
                              <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                  Something went wrong
                                </span>
                              </div>
                            </span>
                          )}
                          {!checkingDuplicate(inputUrl) && status === 'error' && (
                            <span className="connect_account_failed">
                              <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                  All Urls must be unique
                                </span>
                              </div>
                            </span>
                          )}
                          <Button
                            primary
                            type="button"
                            onClick={() => {
                              onCancel();
                            }}
                          >
                            <label> Cancel</label>
                          </Button>
                        </div>
                      </ButtonContainer>
                    </>
                  )}
                </EmptyDiv>
                {authType === 'With OAuth' && authDomain && (
                  <>
                    <BgWrapper>
                      <div className="opations_label">
                        <Header style={{ fontSize: 14, marginBottom: 10 }}>
                          Domain{' '}
                        </Header>
                      </div>
                      <OptionalInput>
                        <InputContainer>
                          <Input
                            type="text"
                            onChange={(e: { target: { value: string } }) =>
                              setState({ ...state, domainName: e.target.value })
                            }
                          />
                          <InputContent>Domain name</InputContent>
                        </InputContainer>
                      </OptionalInput>
                      <OptionalInput>
                        {inputUrl &&
                          inputUrl.map((data, index) => (
                            <>
                              <InputContainer
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Input
                                  type="url"
                                  onChange={(evnt: {
                                    target: { value: string };
                                  }) => changeUrl(index, evnt)}
                                  value={data}
                                  name="url"
                                />
                                <InputContent>
                                  Site URL {index + 1}
                                </InputContent>
                                <span
                                  className="kbclose_Icon"
                                  onClick={() => removeinputUrl(index)}
                                >
                                  <CloseIcon />
                                </span>
                                {/* </div> */}
                              </InputContainer>
                              {!IsValidURL(data) && status === 'error' && (
                                <p className="valid_url_alert">
                                  Provide a Valid URL
                                </p>
                              )}
                            </>
                          ))}
                      </OptionalInput>
                      <SmallButton
                        primary
                        style={{ marginBottom: 20 }}
                        onClick={addInputUrl}
                      >
                        <span>
                          <AddIcon />
                        </span>
                        <label>Add More</label>
                      </SmallButton>
                    </BgWrapper>
                    <ButtonContainer className="footer_button_fixed knowledge_ms_btn">
                      <div className="popup_footer_button_container">
                        <Button
                          type="button"
                          className={
                            state.kBName !== '' &&
                            state.connectionID !== '' &&
                            state.domainName !== '' &&
                            inputUrl.length > 0 &&
                            inputUrl[0].length > 0 &&
                            (status !== 'error' ||
                              !inputURLValid.includes(false)) &&
                            !state.disableSave
                              ? ''
                              : 'editor_btn_disabled'
                          }
                          onClick={() => {
                            saveConnection();
                          }}
                        >
                          <label> Save</label>
                        </Button>
                        {status === 'loader' && (
                          <span className="connect_account_loading automate_connect_account_loading">
                            <img src={LoadingSvgImage} alt="" />
                          </span>
                        )}
                        {status === 'error' && (
                          <span
                            className={
                              checkingDuplicate(inputUrl) &&
                              !inputURLValid.includes(false) &&
                              checkingName.length === 0
                                ? 'connect_account_failed hide'
                                : 'connect_account_failed'
                            }
                          >
                            <div className="error_input_show error_input_show_add teams_page_alert">
                              <WarningAlertIcon />
                              <span className="error_menu_hvr">
                                Something went wrong
                              </span>
                            </div>
                          </span>
                        )}
                        {status === 'apiError' && (
                          <span className="connect_account_failed">
                            <div className="error_input_show error_input_show_add teams_page_alert">
                              <WarningAlertIcon />
                              <span className="error_menu_hvr">
                                Something went wrong
                              </span>
                            </div>
                          </span>
                        )}
                        {!checkingDuplicate(inputUrl) && status === 'error' && (
                          <span className="connect_account_failed">
                            <div className="error_input_show error_input_show_add teams_page_alert">
                              <WarningAlertIcon />
                              <span className="error_menu_hvr">
                                All Urls must be unique
                              </span>
                            </div>
                          </span>
                        )}
                        <Button
                          primary
                          type="button"
                          onClick={() => {
                            onCancel();
                          }}
                        >
                          <label> Cancel</label>
                        </Button>
                      </div>
                    </ButtonContainer>
                  </>
                )}
                {state.connectionID !== '' && (
                  <SmallButton
                    primary
                    onClick={() => {
                      scroollUp();
                      setShowAdditionalProp(!showAdditionalProp);
                    }}
                    style={{ marginBottom: '10px', width: '153px' }}
                  >
                    {showAdditionalProp ? (
                      <span>
                        <MinusIcon />
                      </span>
                    ) : (
                      <span>
                        <AddIcon />
                      </span>
                    )}
                    <label>Select subset of the site</label>
                  </SmallButton>
                )}
                {state.connectionID !== '' && showAdditionalProp && (
                  <>
                    {/* <div className="right_panel_radio">
                      <div className="col-md-12 entity_radio">
                        <div className="radio">
                          <label>
                            <input
                              type="checkbox"
                              className="radio-warning"
                              onClick={() => {
                                setFolder(!folder);
                                scroollUp();
                              }}
                              checked={folder}
                              // onChange={() => setType(false)}
                            />
                          </label>
                          <span>Folders</span>
                        </div>
                        <div className="radio">
                          <label>
                            <input
                              type="checkbox"
                              checked={page}
                              onClick={() => {
                                setPage(!page);
                                scroollUp();
                              }}
                            />
                          </label>
                          <span>Pages</span>
                        </div>
                      </div>
                    </div> */}
                    {additionalObj &&
                      additionalObj.map((add: any, index: number) => (
                        <BgWrapper key={index}>
                          <InputContainer style={{ marginBottom: '10px' }}>
                            <Input
                              type="text"
                              value={add.name}
                              // onChange={(e: any) => {}}
                              onClick={() => {
                                setFolderDropDown(true);
                                setNumber(index);
                              }}
                              style={{ paddingLeft: 10 }}
                            />
                            <InputContent>Additional Information</InputContent>
                            <CancelButton
                              onClick={() => setFolderDropDown(true)}
                              style={{ display: 'block', top: '14px' }}
                            >
                              <DropdownWithCircle />
                            </CancelButton>
                            {folderDropDown && number === index && (
                              <Downshift
                                isOpen={folderDropDown}
                                onOuterClick={() => setFolderDropDown(false)}
                              >
                                {() => (
                                  <div>
                                    <DropdownUl style={{ marginTop: '48px' }}>
                                      {FolderDropValue.map((a) => (
                                        <DropdownLi
                                          onClick={() => {
                                            setFolderDropDown(false);
                                            setFolderType(a);
                                            setName(index, a);
                                          }}
                                        >
                                          <DropdownLiSpan>
                                            {a.name}
                                          </DropdownLiSpan>{' '}
                                          <Tooltip
                                            className="kai_sharepoint target customTip"
                                            zIndex={10000}
                                            arrowSize={8}
                                            tagName="span"
                                            content={a.description}
                                            distance={5}
                                          >
                                            <InfoIcon />
                                          </Tooltip>
                                        </DropdownLi>
                                      ))}
                                    </DropdownUl>
                                  </div>
                                )}
                              </Downshift>
                            )}
                          </InputContainer>
                          {add.name !== '' && (
                            <>
                              <div className="knowledge_ai_folder_box flex">
                                <InputContainer
                                  style={{ marginBottom: '10px' }}
                                >
                                  <Input
                                    type="text"
                                    value={add.value}
                                    onChange={(e: {
                                      target: { value: string };
                                    }) =>
                                      setValue(
                                        index,
                                        folderType,
                                        e.target.value
                                      )
                                    }
                                  />
                                  <InputContent>Value</InputContent>
                                  <span
                                    onClick={() => {
                                      removeSubset(index);
                                    }}
                                  >
                                    <CloseIconWithCircle/>
                                  </span>
                                </InputContainer>
                              </div>
                              <Paragraph>{add.description}</Paragraph>
                            </>
                          )}
                          
                        </BgWrapper>
                      ))}
                    {additionalObj[additionalObj.length - 1].value !== '' && (
                      <SmallButton
                        primary
                        style={{ marginBottom: 30 }}
                        onClick={() => addMoreObj()}
                      >
                        <span>
                          <AddIcon />
                        </span>
                        <label>Add More</label>
                      </SmallButton>
                    )}
                    <div id="scrollIn" />
                  </>
                )}
              </PopupScrollHeight>
            </PopupContainer>
          </PopupWrapper>
        </Wrapper>
      )}

      {pageState === 'edit' && (
        <Wrapper>
          <PopupWrapper className="edit_btn">
            <PopupContainer>
              <Header>
                <HeaderLeft minWidth={300}>
                  MS SharePoint Configuration
                </HeaderLeft>
                <HeaderRight>
                  {/* <VideoLink id={'ADD_AN_ADAPTIVE_CARD'} text={'How to'} /> */}
                </HeaderRight>
                <span
                  onClick={() => {
                    onCancel();
                  }}
                  className="slack_close_btn"
                >
                  <CloseIcon />
                </span>
              </Header>
              <Paragraph>Update the connection.</Paragraph>
              {popUpState.selectedConnection.length === 0 ? (
                <PopupScrollHeight>
                  <div className="popup_loader">
                    <Loader.PopupLoader show={true} />
                  </div>
                </PopupScrollHeight>
              ) : (
                popUpState.selectedConnection.map((data,index) => (
                  <PopupScrollHeight key={index}>
                    <EmptyDiv>
                      <BgWrapper>
                        <InputContainer>
                          <Input
                            type="text"
                            name="connection_name"
                            value={data.name}
                            onChange={(e: { target: { value: string } }) => {
                              handleConnectionEdit(
                                e.target.value,
                                data.id,
                                'name'
                              );
                            }}
                          />
                          <InputContent>Connection name</InputContent>

                          {status === 'error' &&
                            checkingUpdateName(data.id).length > 0 && (
                              <p className="valid_url_alert">
                                Connection Name has to be unique
                              </p>
                              // </div>
                            )}
                        </InputContainer>
                      </BgWrapper>

                      {Object.keys(data.credential).includes('userName') ? (
                        <BgWrapper>
                          <div className="opations_label sharepoint_edit">
                            <Header style={{ fontSize: 14, marginBottom: 10 }}>
                              Connect Account{' '}
                            </Header>
                            {updateCred ? (
                              <div
                                className="sharepoint_icon"
                                onClick={() => {
                                  setUpdateCred(false);
                                  setState({
                                    ...state,
                                    updatePassWord: '',
                                  });
                                }}
                              >
                                <CloseColorIcon />
                              </div>
                            ) : (
                              <div
                                className="sharepoint_icon"
                                onClick={() => setUpdateCred(true)}
                              >
                                <EditIcon />
                              </div>
                            )}
                          </div>
                          <OptionalInput>
                            <InputContainer>
                              <Input
                                type="email"
                                value={data.credential?.userName}
                                disabled={!updateCred}
                                className={
                                  updateCred ? '' : 'editor_btn_disabled'
                                }
                                onChange={(e: {
                                  target: { value: string };
                                }) => {
                                  const validEmail = isValidEmail(
                                    e.target.value
                                  );
                                  setEmailValidation({
                                    valid: validEmail,
                                    message: validEmail
                                      ? ''
                                      : 'Email is invalid',
                                  });
                                  handleConnectionEdit(
                                    e.target.value,
                                    data.id,
                                    'userName',
                                    'credential'
                                  );
                                }}
                              />
                              <InputContent>Connected email</InputContent>
                              {!emailValidation.valid && (
                                <p className="error_msg">
                                  {emailValidation.message}
                                </p>
                              )}
                            </InputContainer>
                          </OptionalInput>
                          <OptionalInput>
                            <InputContainer>
                              <Input
                                type={!state.eyeIcon ? 'password' : 'text'}
                                disabled={!updateCred}
                                className={
                                  updateCred ? '' : 'editor_btn_disabled'
                                }
                                value={state.updatePassWord}
                                onChange={(e: { target: { value: any } }) =>
                                  setState({
                                    ...state,
                                    updatePassWord: e.target.value,
                                  })
                                }
                              />
                              <InputContent>Password</InputContent>
                            </InputContainer>
                            <CancelButton
                              className={
                                updateCred ? 'd-flex' : 'editor_btn_disabled'
                              }
                              onClick={() =>
                                setState({
                                  ...state,
                                  eyeIcon: !state.eyeIcon,
                                })
                              }
                            >
                              {state.eyeIcon ? (
                                <EyeOpenIcon />
                              ) : (
                                <EyeCloseIcon />
                              )}
                            </CancelButton>
                          </OptionalInput>
                          <ButtonContainer
                            style={{
                              gap: 20,
                              padding: '0 0 10px 0',
                              marginTop: '-10px',
                            }}
                            className="slack_buton_center"
                          >
                            <Button
                              type="button"
                              className={
                                accountUpdate() || !updateCred
                                  ? 'hidden'
                                  : emailValidation.valid &&
                                    data.credential.userName !== '' &&
                                    state.updatePassWord !== ''
                                  ? ''
                                  : 'editor_btn_disabled'
                              }
                              onClick={() => {
                                setUpdateCred(false);
                                setState({
                                  ...state,
                                  updatePassWord: '',
                                });
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              style={{ marginRight: 20 }}
                              className={
                                accountUpdate() || !updateCred
                                  ? 'hidden'
                                  : emailValidation.valid &&
                                    data.credential.userName !== '' &&
                                    state.updatePassWord !== ''
                                  ? ''
                                  : 'editor_btn_disabled'
                              }
                              onClick={() =>
                                onCredBasicUpdateSave(data.credentialId)
                              }
                            >
                              <label> Update </label>
                            </Button>
                            {state.connectLoader && (
                              <span className="connect_account_loading">
                                <img src={LoadingSvgImage} />
                              </span>
                            )}
                            {status === 'apiConnectError' && (
                              <span className="connect_account_failed">
                                <div className="error_input_show error_input_show_add teams_page_alert">
                                  <WarningAlertIcon />
                                  <span className="error_menu_hvr">
                                    Something went wrong
                                  </span>
                                </div>
                              </span>
                            )}
                          </ButtonContainer>
                        </BgWrapper>
                      ) : (
                        <BgWrapper>
                          <div className="opations_label sharepoint_edit">
                            <Header style={{ fontSize: 14, marginBottom: 10 }}>
                              Connect Account{' '}
                            </Header>
                          </div>
                          <OptionalInput>
                            <InputContainer>
                              <Input
                                type="text"
                                value={data.credential?.clientId}
                                onChange={(e: {
                                  target: { value: string };
                                }) => {
                                  handleConnectionEdit(
                                    e.target.value,
                                    data.id,
                                    'clientId',
                                    'credential'
                                  );
                                }}
                              />
                              <InputContent>Client ID</InputContent>
                            </InputContainer>
                          </OptionalInput>
                          <OptionalInput>
                            <InputContainer>
                              <Input
                                type="text"
                                value={data.credential?.clientSecret}
                                onChange={(e: {
                                  target: { value: string };
                                }) => {
                                  handleConnectionEdit(
                                    e.target.value,
                                    data.id,
                                    'clientSecret',
                                    'credential'
                                  );
                                }}
                              />
                              <InputContent>Client Secret</InputContent>
                            </InputContainer>
                          </OptionalInput>
                          <OptionalInput>
                            <InputContainer>
                              <Input
                                type="text"
                                value={data.credential?.tenantId}
                                onChange={(e: {
                                  target: { value: string };
                                }) => {
                                  handleConnectionEdit(
                                    e.target.value,
                                    data.id,
                                    'tenantId',
                                    'credential'
                                  );
                                }}
                              />
                              <InputContent>Tenant ID</InputContent>
                            </InputContainer>
                          </OptionalInput>

                          <ButtonContainer
                            style={{
                              gap: 20,
                              padding: '0 0 10px 0',
                              marginTop: '-10px',
                            }}
                            className="slack_buton_center"
                          >
                            <Button
                              type="button"
                              className={
                                accountUpdate()
                                  ? 'hidden'
                                  : data.credential.clientId !== '' &&
                                    data.credential.clientSecret !== '' &&
                                    data.credential.tenantId !== ''
                                  ? ''
                                  : 'editor_btn_disabled'
                              }
                              onClick={() => {
                                // setUpdateCred(false);
                                setState({
                                  ...state,
                                  updatePassWord: '',
                                });
                                onCancel();
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              style={{ marginRight: 20 }}
                              className={
                                accountUpdate()
                                  ? 'hidden'
                                  : data.credential.clientId !== '' &&
                                    data.credential.clientSecret !== '' &&
                                    data.credential.tenantId !== ''
                                  ? ''
                                  : 'editor_btn_disabled'
                              }
                              onClick={() =>
                                onCredUpdateSave(data.credentialId)
                              }
                            >
                              <label> Update </label>
                            </Button>
                            {state.connectLoader && (
                              <span className="connect_account_loading">
                                <img src={LoadingSvgImage} alt="" />
                              </span>
                            )}
                            {status === 'apiConnectError' && (
                              <span className="connect_account_failed">
                                <div className="error_input_show error_input_show_add teams_page_alert">
                                  <WarningAlertIcon />
                                  <span className="error_menu_hvr">
                                    Something went wrong
                                  </span>
                                </div>
                              </span>
                            )}
                            {!data.credential.result && (
                              <span className="connect_account_failed">
                                <div className="error_input_show error_input_show_add teams_page_alert">
                                  <WarningAlertIcon />
                                  <span className="error_menu_hvr">
                                    Authentication Error
                                  </span>
                                </div>
                              </span>
                            )}
                          </ButtonContainer>
                        </BgWrapper>
                      )}
                      <BgWrapper>
                        <div className="opations_label">
                          <Header style={{ fontSize: 14, marginBottom: 10 }}>
                            Domain{' '}
                          </Header>
                        </div>
                        <OptionalInput>
                          <InputContainer>
                            <Input
                              type="text"
                              value={data.repository?.domain}
                              onChange={(e: { target: { value: string } }) =>
                                handleConnectionEdit(
                                  e.target.value,
                                  data.id,
                                  'domain',
                                  'repository'
                                )
                              }
                            />
                            <InputContent>Domain name</InputContent>
                          </InputContainer>
                        </OptionalInput>
                        <OptionalInput>
                          {data.repository?.siteUrls &&
                            data.repository?.siteUrls.map((rep, index) => (
                              <div className="knowledge_domain_url_box">
                                <InputContainer
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Input
                                    type="url"
                                    onChange={(evnt: {
                                      target: { value: string };
                                    }) =>
                                      handleRepositoryChange(
                                        index,
                                        evnt,
                                        data.id,
                                        'siteUrls'
                                      )
                                    }
                                    value={rep}
                                    name="url"
                                  />

                                  <InputContent>
                                    Site URL {index + 1}
                                  </InputContent>
                                  <span
                                    className="kbclose_Icon"
                                    onClick={() =>
                                      removeSiteUrl(index, data.id)
                                    }
                                  >
                                    <CloseIcon />
                                  </span>
                                </InputContainer>
                                {!IsValidURL(rep) && status === 'error' && (
                                  <p className="valid_url_alert">
                                    Provide a Valid URL
                                  </p>
                                )}
                              </div>
                            ))}
                        </OptionalInput>

                        <SmallButton
                          primary
                          style={{ marginBottom: 30 }}
                          onClick={() => addSiteUrl(data.id)}
                        >
                          <span>
                            <AddIcon />
                          </span>
                          <label>Add More</label>
                        </SmallButton>
                      </BgWrapper>
                      <SmallButton
                        primary
                        onClick={() => {
                          scroollUp();
                          setShowAdditionalProp(!showAdditionalProp);
                        }}
                        style={{ marginBottom: '10px' }}
                      >
                        {showAdditionalProp ? (
                          <span>
                            <MinusIcon />
                          </span>
                        ) : (
                          <span>
                            <AddIcon />
                          </span>
                        )}
                        <label>Select subset of the site</label>
                      </SmallButton>
                      {showAdditionalProp && (
                        <>
                          {additionalObj &&
                            additionalObj.map((add: any, index: number) => (
                              <BgWrapper>
                                <InputContainer
                                  style={{ marginBottom: '10px' }}
                                >
                                  <Input
                                    type="text"
                                    value={add.name}
                                    // onChange={(e: any) => {}}
                                    onClick={() => {
                                      setFolderDropDown(true);
                                      setNumber(index);
                                    }}
                                    style={{ paddingLeft: 10 }}
                                  />
                                  <InputContent>
                                    Additional Information
                                  </InputContent>
                                  <CancelButton
                                    onClick={() => setFolderDropDown(true)}
                                    style={{ display: 'block', top: '14px' }}
                                  >
                                    <DropdownWithCircle />
                                  </CancelButton>
                                  {folderDropDown && number === index && (
                                    <Downshift
                                      isOpen={folderDropDown}
                                      onOuterClick={() =>
                                        setFolderDropDown(false)
                                      }
                                    >
                                      {() => (
                                        <div>
                                          <DropdownUl
                                            style={{ marginTop: '48px' }}
                                          >
                                            {FolderDropValue.map((a) => (
                                              <DropdownLi
                                                onClick={() => {
                                                  setFolderDropDown(false);
                                                  setFolderType(a);
                                                  setName(index, a);
                                                }}
                                              >
                                                <DropdownLiSpan>
                                                  {a.name}
                                                </DropdownLiSpan>{' '}
                                                <Tooltip
                                                  className="kai_sharepoint target customTip"
                                                  zIndex={10000}
                                                  arrowSize={8}
                                                  tagName="span"
                                                  content={a.description}
                                                  distance={5}
                                                >
                                                  <InfoIcon />
                                                </Tooltip>
                                              </DropdownLi>
                                            ))}
                                          </DropdownUl>
                                        </div>
                                      )}
                                    </Downshift>
                                  )}
                                </InputContainer>

                                {add.name !== '' && (
                                  <>
                                    <div className="knowledge_ai_folder_box flex">
                                      <InputContainer
                                        style={{ marginBottom: '10px' }}
                                      >
                                        <Input
                                          type="text"
                                          value={add.value}
                                          onChange={(e: {
                                            target: { value: string };
                                          }) =>
                                            setValue(
                                              index,
                                              folderType,
                                              e.target.value
                                            )
                                          }
                                          style={{ transition: '.2s' }}
                                        />
                                        <InputContent>Value</InputContent>
                                        <span
                                          onClick={() => {
                                            removeSubset(index);
                                          }}
                                        >
                                          <CloseIconWithCircle />
                                        </span>
                                      </InputContainer>
                                    </div>
                                    <Paragraph>{add.description}</Paragraph>
                                  </>
                                )}
                              </BgWrapper>
                            ))}
                          {additionalObj[additionalObj.length - 1].value !==
                            '' && (
                            <SmallButton
                              primary
                              style={{ marginBottom: 30 }}
                              onClick={() => addMoreObj()}
                            >
                              <span>
                                <AddIcon />
                              </span>
                              <label>Add More</label>
                            </SmallButton>
                          )}
                          <div id="scrollIn" />
                        </>
                      )}
                    </EmptyDiv>

                    <ButtonContainer
                      style={{ padding: 0 }}
                      className="footer_button_fixed"
                    >
                      <div className="popup_footer_button_container slack_buton_center">
                        <Button
                          type="button"
                          className={
                            needUpdate()
                              ? 'hidden'
                              : data.name !== '' &&
                                data.repository.domain !== '' &&
                                data.repository.siteUrls.length > 0 &&
                                (status !== 'error' ||
                                  !checkingValidUrl.includes(false))
                              ? ''
                              : 'editor_btn_disabled'
                          }
                          onClick={() => {
                            updateConnection(data.id);
                          }}
                        >
                          <label> Save</label>
                        </Button>
                        {status === 'loader' && (
                          <span className="connect_account_loading">
                            <img src={LoadingSvgImage} alt="" />
                          </span>
                        )}

                        {status === 'error' && (
                          <span
                            className={
                              checkingDuplicate(data.repository.siteUrls) &&
                              !checkingValidUrl.includes(false) &&
                              checkingUpdateName(data.id).length === 0
                                ? 'connect_account_failed hide'
                                : 'connect_account_failed'
                            }
                          >
                            <div className="error_input_show error_input_show_add teams_page_alert">
                              <WarningAlertIcon />
                              <span className="error_menu_hvr">
                                Something went wrong
                              </span>
                            </div>
                          </span>
                        )}
                        {status === 'apiError' && (
                          <span className="connect_account_failed">
                            <div className="error_input_show error_input_show_add teams_page_alert">
                              <WarningAlertIcon />
                              <span className="error_menu_hvr">
                                Something went wrong
                              </span>
                            </div>
                          </span>
                        )}
                        {!checkingDuplicate(data.repository.siteUrls) &&
                          status === 'error' && (
                            <span className="connect_account_failed">
                              <div className="error_input_show error_input_show_add teams_page_alert">
                                <WarningAlertIcon />
                                <span className="error_menu_hvr">
                                  All Urls must be unique
                                </span>
                              </div>
                            </span>
                          )}
                      </div>
                    </ButtonContainer>
                  </PopupScrollHeight>
                ))
              )}
            </PopupContainer>
          </PopupWrapper>
        </Wrapper>
      )}
    </>
  );
}
